import {gql} from "apollo-boost/lib/index"
import client from "../utils/ApolloClient"

export const GET_MUNICIPIOS = gql`
    query municipiosQuery($searchTerm: String){
        municipiosQuery(searchTerm: $searchTerm) {
            codIBGE
            municipio
            uf
            latitude
            longitude
        }
  }
`;

export const getMunicipios = async (searchTerm) => {
    const municipiosQuery = await client.query({
        query: GET_MUNICIPIOS,
        variables: {searchTerm: searchTerm},
        fetchPolicy: "cache-first"
    });

    return municipiosQuery.data.municipiosQuery;
};