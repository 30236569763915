import client from "../utils/ApolloClient";
import {gql} from "apollo-boost";

export const GET_CONFIRMATION_DETAILS = gql`
  query getConfirmationDetails($slug: String!, $UnidadeID: Int, $tipoProcedimentoID: Int!, $pacienteID: Int!, $data: String!, $hora: String!, $gradeID: Int, $localID: Int, $profissionalID: Int!, $procedimentos: [procedureInput], $convenioId: Int, $tabelaId: Int) {
      confirmAppointmentQuery(slug: $slug, UnidadeID: $UnidadeID, tipoProcedimentoID: $tipoProcedimentoID, pacienteID: $pacienteID, data: $data, hora: $hora, gradeID: $gradeID, localID: $localID, profissionalID: $profissionalID, procedimentos: $procedimentos, convenioID: $convenioId, tabelaID: $tabelaId){
        Unidade {
          id
          nomeClinica,
          nomeUnidade,
          endereco,
          foto
        },
        Profissional {
          id,
          nome,
          foto,
          sexo,
        },
        Procedimentos {
          id,
          procedimento,
          tipoProcedimento,
          especialidade,
          preparo {
            tipoPreparo
          },
          valor
        },
        Paciente {
            id,
            nome
        },
        Convenio {
            id,
            nome
        }
        GradeID,
        LocalID
      }
}
`;

export const CONFIRM_APPOINT = gql`
  mutation confirmAppoint($slug: String!, $UnidadeID: Int, $pacienteID: Int!, $data: String!, $hora: String!, $localID: Int!, $tipoProcedimentoID: Int!, $profissionalID: Int!, $procedimentos: [procedureInput]!, $tabelaParticularId: Int, $convenioId: Int, $planoId: Int, $campanha: String, $retornoId: Int) {
      confirmAppointmentMutation(PacienteID: $pacienteID, ClinicaUnidadeID: $UnidadeID, ProfissionalID: $profissionalID, LocalID: $localID, data: $data, hora: $hora, slug: $slug, procedimentos: $procedimentos, tipoProcedimentoID: $tipoProcedimentoID, tabelaParticularId: $tabelaParticularId, convenioId: $convenioId, planoId: $planoId, campanha: $campanha, retornoId: $retornoId){
        success
        content
        needsReload
        ID
        LicencaID
        PacienteID
      }
  }
`;

export const ACEITE_QUERY = gql`
    query aceiteQuery($slug: String!){
      TermoDeCompromissoQuery(slug: $slug){
        url
      }
    }
`;

export const getConfirmationDetails = async (slug, unityId, appointTypeId, patientId, date, hour, gradeId, localId, professionalId, selectedProcedures, convenioId, planoId, tabelaId) => {
    const confirmationDetailsQuery = await client.query({
        query: GET_CONFIRMATION_DETAILS,
        variables: {
            slug: slug,
            UnidadeID: unityId,
            tipoProcedimentoID: appointTypeId,
            pacienteID: patientId,
            data: date,
            hora: hour,
            gradeID: gradeId || null,
            localID: localId || null,
            profissionalID: professionalId,
            procedimentos: selectedProcedures,
            convenioId: convenioId,
            planoId: planoId,
            tabelaId: tabelaId
        },
        fetchPolicy: "no-cache"
    });

    if (!confirmationDetailsQuery.data.confirmAppointmentQuery) throw new Error("Ocorreu um erro interno na validação dos dados de confirmação");

    return confirmationDetailsQuery.data.confirmAppointmentQuery;
};

export const getTermoUrl = async (slug) => {
    const aceiteQuery = await client.query({
        query: ACEITE_QUERY,
        variables: {
            slug: slug,
        },
        fetchPolicy: "no-cache"
    });


    return aceiteQuery.data.TermoDeCompromissoQuery.url;
};

export const confirmAppoint = async (slug, unityId, appointTypeId, patientId, date, hour, localId, professionalId, selectedProcedures, convenioId, planoId, tabelaId, campaign, returnId) => {
    const confirmAppointMutation = await client.mutate({
        mutation: CONFIRM_APPOINT,
        variables: {
            slug: slug,
            UnidadeID: unityId,
            tipoProcedimentoID: appointTypeId,
            pacienteID: patientId,
            data: date,
            hora: hour,
            localID: localId || 0,
            profissionalID: professionalId,
            procedimentos: selectedProcedures,
            convenioId: convenioId,
            planoId: planoId,
            tabelaParticularId: tabelaId,
            campanha: campaign,
            retornoId: returnId
        },
    });

    if (!confirmAppointMutation.data.confirmAppointmentMutation.success) throw new Error(confirmAppointMutation.data.confirmAppointmentMutation.content);

    return confirmAppointMutation.data.confirmAppointmentMutation;
};

export const redirectToPayment = async (licenseId, patientId, appointId) => {
    const licenseHashResponse = await fetch(`https://api.feegow.com.br/create-hash/${licenseId}`);

    const licenseHash = await licenseHashResponse.text();
    const patientHash = btoa(String(patientId));

    window.location.href = `https://api.feegow.com.br/patient-interface/${licenseHash}/redirect-auth?pHash=${patientHash}&lHash=${licenseHash}&routeName=appointList&pendingAppoint=${appointId}`;
};
