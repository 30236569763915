import client from "../utils/ApolloClient";
import {gql} from "apollo-boost";

export const GET_AVAILABLE_SCHEDULE = gql`
  query getAvailableSchedule($slug: String!, $dateStart: String!, $dateEnd: String!, $appointTypeId: Int!, $procedureId: Int!, $specialtyId: Int!, $unityId: Int, $update: Boolean, $convenioId: Int, $planoId: Int, $latitude: String, $longitude: String, $range: String) {
      availableDatesQuery(slug: $slug, dataInicio: $dateStart, dataFim: $dateEnd, tipoProcedimentoId: $appointTypeId, procedimentoId: $procedureId, especialidadeId: $specialtyId, unidadeID: $unityId, update: $update, convenioId: $convenioId, planoId: $planoId, latitude: $latitude, longitude: $longitude, range: $range ){
        data,
        totalVagas,
        unidades {
          id,
          nomeUnidade,
          endereco {
            rua,
            bairro,
            cidade,
            estado,
            regiao,
            numero,
            complemento
          },
          vagas {
            quantidadeVagas,
            profissionais {
              id,
              nome,
              crm,
              foto,
              sexo,
              disponiveis {
                gradeId,
                horario,
                localId
              }
            }
          }
        }
      }
}
`;

export const GET_CONVENIOS = gql`
    query convenioQuery($slug: String!){
        convenioQuery(slug: $slug) {
            id
            nome
            registroAns
            Planos {
                id,
                plano
            }
        }
  }
`;

export const GET_SCHEDULE_QUEUE = gql`
    query scheduleQueueQuery($ProcedimentoID: Int!, $EspecialidadeID: Int!, $slug: String!){
      scheduleQueueQuery(ProcedimentoID: $ProcedimentoID, EspecialidadeID: $EspecialidadeID, slug: $slug){
        isOnQueue
      }
    }
`;

export const UPDATE_SCHEDULE_QUEUE = gql`
mutation scheduleQueueMutation($slug: String!, $type: String!, $ProcedimentoID: Int!, $EspecialidadeID: Int!){
  scheduleQueueMutation(slug: $slug, type: $type, ProcedimentoID: $ProcedimentoID, EspecialidadeID: $EspecialidadeID){
    isOnQueue
  }
}
`;


export const getAvailableSchedule = async (slug, dateStart, dateEnd, appointTypeId, specialtyId, procedureId, convenioId, planoId, unityId = false, needsReload = false, latitude = false, longitude = false, range = false) => {

    // se for exame seta especialidade como 0
    if (appointTypeId == 3)
        specialtyId = 0;

    const availableScheduleQuery = await client.query({
        fetchPolicy: needsReload ? "no-cache" : "cache-first",
        query: GET_AVAILABLE_SCHEDULE,
        variables: {
            slug: slug,
            dateStart: dateStart,
            dateEnd: dateEnd,
            appointTypeId: appointTypeId,
            specialtyId: specialtyId,
            procedureId: procedureId,
            unityId: unityId,
            update: needsReload,
            planoId: planoId,
            convenioId: convenioId,
            latitude: latitude,
            longitude: longitude,
            range: range
        }
    });

    if (!availableScheduleQuery.data.availableDatesQuery.length === 0) throw new Error("Nenhum horário encontrado");

    return availableScheduleQuery.data.availableDatesQuery;
};

export const getConvenios = async (slug) => {
    const convenioQuery = await client.query({
        fetchPolicy: "cache-first",
        query: GET_CONVENIOS,
        variables: {
            slug: slug,
        }
    });

    return convenioQuery.data.convenioQuery;
};

export const getScheduleQueueStatus = async (slug, specialtyId, procedureId) => {
    const scheduleQueueQuery = await client.query({
        fetchPolicy: "no-cache",
        query: GET_SCHEDULE_QUEUE,
        variables: {
            slug: slug,
            ProcedimentoID: procedureId,
            EspecialidadeID: specialtyId
        }
    });

    return scheduleQueueQuery.data.scheduleQueueQuery;
}

export const updateQueueStatus = async (slug, type, specialtyId, procedureId) => {
    const scheduleQueue = await client.mutate({
        mutation: UPDATE_SCHEDULE_QUEUE,
        variables: {
            slug: slug,
            type: type,
            EspecialidadeID: specialtyId,
            ProcedimentoID: procedureId
        }
    });

    return scheduleQueue.data.scheduleQueueMutation;
}
