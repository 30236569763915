import React, {useState} from 'react';
import {Alert, Col, Fade, Form, FormGroup, FormInput, Row} from "shards-react";
import {sendPasswordResetEmail} from "../../services/firebaseService";
import SendEmailButton from "./SendEmailButton";


const ForgotPassword = (props) => {
    const [email, setEmail] = useState("");
    const [emailSent, setEmailSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const sendConfirmEmail = async () => {
        setLoading(true);

        try {
            await sendPasswordResetEmail(email, window.location.href);
            setLoading(false);
            setEmailSent(true);
        } catch (e) {
            setLoading(false);
            setError(e.message);
        }
    };

    const onKeyDown = async (e) => {
        if (e.key === "Enter") {
            await sendConfirmEmail();
        }
    };

    return (
        <Fade>
            <Row>
                <Col style={{textAlign: "center"}}>
                    <h5 style={{fontFamily: 'Montserrat'}}>Esqueceu sua senha?</h5>
                    <p style={{opacity: 0.80, fontSize: 12}}>Informe o email associado a sua conta para receber
                        um email com
                        instruções para resetar sua
                        senha.</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <FormGroup>
                            <FormInput onKeyPress={onKeyDown} onChange={(ev) => setEmail(ev.target.value)} type="email"
                                       id="#email2"
                                       placeholder="Informe seu email"/>
                        </FormGroup>
                        <p style={{color: "red"}}>{error}</p>
                    </Form>
                </Col>
            </Row>
            <Row style={{marginTop: 25}}>
                <Col style={{textAlign: "center"}}>
                    <SendEmailButton sendConfirmEmail={sendConfirmEmail} loading={loading} emailSent={emailSent}/>
                </Col>
            </Row>
            <Row style={{marginTop: 5}}>
                <Col style={{textAlign: "center"}}>
                    <a style={{fontSize: 12}} onClick={() => props.setCurrentPage('login', email)}
                       className="alert-link" href="#">
                        Já resetei minha senha. Realizar login.
                    </a>
                </Col>
            </Row>
        </Fade>
    )
};

export default ForgotPassword;