import {action, thunk} from "easy-peasy";
import {confirmAppoint, getConfirmationDetails} from "../../services/confirmationService";

const confirmationModel = {
    loading: false,
    error: false,
    confirmationDetails: false,

    setLoading: action((state, isLoading) => {
        state.loading = isLoading
    }),

    setError: action((state, error) => {
        state.error = error
    }),

    setValue: action((state, payload) => {
        let payloadKeys = Object.keys(payload);

        payloadKeys.forEach((key) => {
            if (state.hasOwnProperty(key)) {
                state[key] = payload[key];
            }
        })
    }),

    getConfirmationDetails: thunk(async (actions, payload, {getStoreState, getStoreActions}) => {
        if(!payload.ignoreLoading) {
            actions.setLoading(true);
        }

        const stepActions = getStoreActions().steps;

        try {
            const slug = getStoreState().layout.slug;
            const params = getStoreState().steps.getParams;

            const selectedProcedures = params.selectedProcedures.map((selectedProcedure) => {
                return {especialidadeID: selectedProcedure.specialtyId, procedimentoID: params.isTelemedicine ? selectedProcedure.telemedicineId : selectedProcedure.procedureId}
            });

            const confirmationDetails = await getConfirmationDetails(slug, params.unityId, params.appointTypeId, params.patientId, params.dateStart, params.hour, params.gradeId, params.localId, params.professionalId, selectedProcedures, params.convenioId, params.planoId, params.tabelaId);

            actions.setValue({confirmationDetails: confirmationDetails});

            stepActions.setStepData({
                "horario-profissional": {
                    localId: confirmationDetails.LocalID,
                    gradeId: confirmationDetails.GradeID
                }
            });

        } catch (e) {
            actions.setError(e.message);
        }

        actions.setLoading(false);
    }),

    confirmAppoint: thunk(async (actions, payload, {getStoreState, getStoreActions}) => {
        const scheduleActions = getStoreActions().schedule;

        try {
            const slug = getStoreState().layout.slug;
            const params = getStoreState().steps.getParams;

            const selectedProcedures = params.selectedProcedures.map((selectedProcedure) => {
                return {especialidadeID: selectedProcedure.specialtyId, procedimentoID: params.isTelemedicine ? selectedProcedure.telemedicineId : selectedProcedure.procedureId}
            });

            //caso localid seja null trocar para 0
            const localId = params.localId == null ? 0 : params.localId;

            const result = await confirmAppoint(slug, params.unityId, params.appointTypeId, params.patientId, params.dateStart, params.hour, localId, params.professionalId, selectedProcedures, params.convenioId, params.planoId, params.tabelaId, params.campaign, params.returnId);

            if (result.needsReload) {
                scheduleActions.setValue({needsReload: true});
                return "redirect";
            }

            return result;
        } catch (e) {
            throw e;
        }
    }),

    getInitialValues: thunk(async (actions, payload, {getStoreState, getStoreActions}) => {

        const stepsActions = getStoreActions().steps;

        stepsActions.setStepData({
            "confirmar": {
                patientFeegowId: payload.patientFeegowId,
                licenseId: payload.licenseId,
                appointId: payload.appointId,
                tabelaId: payload.tabelaId,
            }
        });
    }),
};

export default confirmationModel;
