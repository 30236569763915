import React from 'react';
import {useStoreState} from "easy-peasy";

const OptionalFieldLabel = (props) => {
    const patientStore = useStoreState(s => s.patient);

    if(Object.keys(patientStore.requiredFields).includes(props.field)) return null;

    return (
        <p style={{fontSize: 12, marginBottom: 0, marginTop: 5, marginLeft: 2, fontWeight: "400", opacity: 0.6}}>Campo Opcional</p>
    )
};

export default OptionalFieldLabel;
