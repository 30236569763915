import {gql} from "apollo-boost";
import client from "../utils/ApolloClient";


export const AUTHENTICATE = gql`
  mutation authenticateUser($slug: String!, $userId: String, $email: String, $photo: String, $displayName: String, $token: String) {
      authenticate(slug: $slug, uid: $userId, photoUrl: $photo, displayName: $displayName, email: $email, token: $token){
        success
        content
        token
        email
        photoUrl
        isAdmin
      }
  }
`;

export const authUser = async (slug, userId, email, photo, displayName, token) => {
    const userInfoQuery = await client.mutate({
        mutation: AUTHENTICATE,
        variables: {slug: slug, userId: userId, email: email, photo: photo, displayName: displayName, token: token},
    });

    if (!userInfoQuery.data.authenticate.success) throw new Error(userInfoQuery.data.authenticate.content);

    return userInfoQuery.data.authenticate;
};