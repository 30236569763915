import client from "../utils/ApolloClient";
import {gql} from "apollo-boost";

export const GET_TABELAS_PARTICULARES = gql`
    query ParticularTablesQuery($slug: String!) {
        ParticularTablesQuery (slug: $slug) {
            id,
            tabela,
            tipoValidacao
        }
    }
`;

export const GET_TABELA_ELIGIBILITY = gql`
    query convenioEligiblity($patientId: Int, $tabelaId: Int, $type: String, $numero: String, $ans: String){
      EligibilityQuery(PacienteID: $patientId, typeTabela: $tabelaId, type: $type, numero: $numero, ans: $ans){
        success
        elegivel
      }
  }
`;

export const getTabelaEligibility = async (patientId, tabelaId) => {
    const tabelaEligibilityQuery = await client.query({
        fetchPolicy: "no-cache",
        query: GET_TABELA_ELIGIBILITY,
        variables: {
            patientId: patientId,
            tabelaId: tabelaId,
            type: false,
            numero: false,
            ans: false
        }
    });

    return tabelaEligibilityQuery.data;
};

export const getTabelasParticulares = async (slug) => {
    const tabelasParticularesQuery = await client.query({
        query: GET_TABELAS_PARTICULARES,
        variables: {slug: slug},
        fetchPolicy: "no-cache"
    });

    if (typeof tabelasParticularesQuery.data.ParticularTablesQuery[0] === "undefined") {
        throw Error("Tabelas não definidas");
    }

    return tabelasParticularesQuery.data.ParticularTablesQuery;
};
