import React from 'react';
import UnitDate from "./UnitDate";
import {isEmpty} from "../../utils/utils";

const UnitDates = (props) => {
    if (!isEmpty(props.availableUnits)) {
        return props.availableUnits.map((el, index) => (
            <UnitDate region={props.region} key={index} dateInfo={el}/>
        ))
    }
};

export default UnitDates;