import {action, computed, thunk} from 'easy-peasy';
import React from "react";
import {getAppointTypeOptions, getAppointTypes} from "../../services/procedureService";

const procedureModel = {
    loading: false,
    error: false,
    appointTypes: [],
    appointTypesOptions: {},

    setLoading: action((state, payload) => {
        state.loading = payload;
    }),

    setError: action((state, payload) => {
        state.error = payload;
    }),

    setValue: action((state, payload) => {
        let payloadKeys = Object.keys(payload);

        payloadKeys.forEach((key) => {
            if (state.hasOwnProperty(key)) {
                state[key] = payload[key];
            }
        })
    }),

    setAppointTypeOptions: action((state, payload) => {
        state.appointTypesOptions[payload.appointTypeId] = payload.appointTypeOptions;
    }),

    getAppointTypes: thunk(async (actions, payload, {getStoreState}) => {
        try {
            const appointTypes = await getAppointTypes(getStoreState().layout.slug);
            actions.setValue({appointTypes: appointTypes});
        } catch (e) {
            throw e;
        }
    }),

    getAppointTypeOptions: thunk(async (actions, payload, {getState, getStoreState}) => {
        let appointTypes = payload.appointTypes ? payload.appointTypes : getState().appointTypes;
        let isReturn = !!payload.appointTypes;

        await Promise.all(appointTypes.map(async (appointType) => {
            if ((appointType.enabled && !appointType.isReturn) || isReturn) {
                const appointTypeId = appointType.id;
                try {
                    const appointTypeOptions = await getAppointTypeOptions(getStoreState().layout.slug, appointTypeId);
                    actions.setAppointTypeOptions({
                        appointTypeId: appointTypeId,
                        appointTypeOptions: appointTypeOptions
                    });
                } catch (e) {
                    throw e;
                }
            }
        }));
    }),

    getInitialValues: thunk(async (actions, payload, {getState, getStoreState, getStoreActions}) => {
        const procedureSelectedValue = getStoreState().steps.availableSteps["especialidade"].selectedValue;
        const stepsActions = getStoreActions().steps;

        if (!procedureSelectedValue) {
            const appointTypeId = payload.appointTypeId || 2;
            const selectedProcedures = payload.selectedProcedures;
            const isTelemedicine = payload.isTelemedicine;
            const proceduresName = payload.selectedValues && payload.selectedValues["especialidade"];
            const returnId = payload.returnId;
            const limitIds = payload.limitIds;
            const limitDate = payload.limitDate;
            const convenioId = payload.convenioId;
            const cidade = payload.cidade;
            const uf = payload.uf;
            const planoId = payload.planoId;
            const blockDefaultParams = payload.blockDefaultParams && payload.blockDefaultParams.split(",");

            stepsActions.setSelectedValue({
                "especialidade": proceduresName,
            });

            stepsActions.setStepData({
                "especialidade": {
                    appointTypeId: appointTypeId,
                    selectedProcedures: selectedProcedures,
                    isTelemedicine: isTelemedicine,
                    returnId: returnId,
                    limitIds: limitIds,
                    blockDefaultParams: blockDefaultParams
                },
                "data": {
                    limitDate: limitDate
                },
                "centro-medico": {
                    "uf": uf,
                    "cidade": cidade,
                    "convenioId": convenioId,
                    "planoId": planoId
                }
            });
        }

        return true;
    }),

    getCurrentAppointType: computed(
        [
            (state) => state.appointTypes,
            (state, storeState) => storeState.steps.availableSteps["especialidade"].data
        ],
        (appointTypes, specialtyData) => {
            const filtered = appointTypes.filter((appointType) => appointType.id == specialtyData.appointTypeId);
            if (filtered.length > 0 && filtered[0].enabled) return filtered[0];

            let result = false;
            appointTypes.forEach((appointType, i) => {
                if (result.index && result.index > i) return;
                if (appointType.enabled) result = {object: appointType, index: i};
            });


            return result.object;

        }
    )
};

export default procedureModel;
