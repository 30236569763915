import React from 'react';
import {Redirect, useParams} from "react-router-dom";

const InitialComponent = (props) => {
    let {slug} = useParams();

    return (
        <Redirect to={`/agendamento/${slug}/especialidade`}/>
    )
};

export default InitialComponent;