import React from 'react';
import {useStoreState} from "easy-peasy";

const ProposalButton = ({procedure, onAddToProposal}) => {
    const layoutStore = useStoreState((s) => s.layout);


    if (!procedure.allowAppointment) return null;
    return (
        <p onClick={() => onAddToProposal(procedure)} style={{
            color: layoutStore.primaryColor,
            marginBottom: 0,
            cursor: "pointer"
        }}>Adicionar a proposta</p>
    )
};

export default ProposalButton;