import React, {useState, useEffect} from 'react';
import {Button, Col, Form, FormGroup, FormInput, Row} from "shards-react";
import {createUserWithEmail} from "../../services/firebaseService";
import {useStoreActions} from "easy-peasy";
import Loader from 'react-loader-spinner'

const SignUp = (props) => {
    const [email, setEmail] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const userActions = useStoreActions(a => a.user);
    const layoutActions = useStoreActions(a => a.layout);
    const stepActions = useStoreActions(a => a.steps);

    useEffect(() => {
        setEmail(props.email);
    }, []);

    const onKeyDown = async (e) => {
        if (e.key === "Enter") {
            await createAccount('email');
        }
    };

    const createAccount = async () => {
        if (password !== confirmPassword) {
            setError("As senhas informadas não conferem");
            return false;
        }

        try {
            setLoading(true);
            const user = await createUserWithEmail(email, password, displayName);

            if (!user.emailVerified) {
                props.setCurrentPage('verifyEmail', false, user);
                return false;
            }

            const userInfo = await userActions.signIn({
                userId: user.uid,
                email: user.email,
                displayName: user.displayName,
                photo: user.photoURL
            });


            userActions.setValue({userInfo: userInfo});
            layoutActions.setModalContent(false);

            setLoading(false);
            if (props.goToNextStep) stepActions.toNextStep();
        } catch (e) {
            setError(e.message);
            setLoading(false);
        }
    };

    return (
        <div>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <FormInput onKeyPress={onKeyDown} onChange={(ev) => setDisplayName(ev.target.value)} placeholder="Seu nome"/>
                        </FormGroup>
                        <FormGroup>
                            <FormInput onKeyPress={onKeyDown} value={email} onChange={(ev) => setEmail(ev.target.value)} type="email"
                                       placeholder="Email"/>
                        </FormGroup>
                        <FormGroup>
                            <FormInput onKeyPress={onKeyDown} onChange={(ev) => setPassword(ev.target.value)} type="password"
                                       placeholder="Senha"/>
                        </FormGroup>
                        <FormGroup>
                            <FormInput onKeyPress={onKeyDown} onChange={(ev) => setConfirmPassword(ev.target.value)} type="password"
                                       placeholder="Confirmar a senha"/>
                        </FormGroup>
                        <p style={{color: "red"}}>{error}</p>
                    </Form>
                </Col>
            </Row>
            <Row style={{marginTop: 20}}>
                <Col>
                    <Button onClick={() => props.setCurrentPage("login", email)} block outline>Voltar</Button>
                </Col>
                <Col>
                    <Button onClick={createAccount} block>
                        {loading ? <Loader height={15} width={20} color="white" type="ThreeDots"/> : "Registrar"}
                    </Button>
                </Col>
            </Row>
        </div>
    )
};

export default SignUp;