import React from 'react';
import {useStoreState} from "easy-peasy";
import {Route, Switch} from "react-router-dom";
import InitialComponent from "./InitialComponent";
import {useQueryParams} from "../../hooks/customHooks";

const ContentComponent = (props) => {
    const stepsStore = useStoreState(s => s.steps);

    let params = useQueryParams();

    const validateParams = (requiredParams) => {
        requiredParams.forEach((required) => {
            if (!params.hasOwnProperty(required.param) || !params[required.param]) throw new Error(`${required.name} não informado(a)`);
        })
    };

    const callInitialDataFunctions = async (initialDataFunctions, requiredParams) => {
        if (initialDataFunctions) {
            for (const fn of initialDataFunctions) {
                await fn(params);
            }
        }
    };

    const getStepInitialData = async (initialParams) => {
        initialParams.stepActions.setLoading(true);

        try {
            await validateParams(initialParams.requiredQueryParams);
            await callInitialDataFunctions(initialParams.initialDataFunctions, initialParams.requiredQueryParams);

            initialParams.stepActions.setLoading(false);
        } catch (e) {
            const err = e.message;
            const mensagem = <p>Ocorreu um erro de conexão <br/> Confira sua conexão com a internet ou tente novamente mais tarde.</p>;

            const erroCapturado = err.includes("Network") ? mensagem : err;

            initialParams.stepActions.setLoading(false);
            initialParams.stepActions.setError(erroCapturado);
        }
    };

    return (
        <div style={{padding: 20, height: "100%"}}>
            <Switch>
                {Object.keys(stepsStore.steps).map((step, index) => {
                    if (stepsStore.steps[step].path) {
                        return (
                            <Route children={stepsStore.steps[step].component(getStepInitialData)} key={index}
                                   path={stepsStore.steps[step].path}/>
                        )
                    }
                })};
                <Route path="/agendamento/:slug/" children={<InitialComponent/>}/>
                <Route path="*" children={(<p>Página não encontrada.</p>)}/>
            </Switch>
        </div>
    )
};

export default ContentComponent;