import {action, computed, thunk} from 'easy-peasy';
import {
    getAvailablePatients, getOrigens,
    getRelativeTypes,
    getRequiredFields,
    insertUpdatePatient
} from "../../services/patientService";
import {isEmpty} from "../../utils/utils";

const patientModel = {
    loading: false,
    error: false,
    selectedPatient: {},
    availablePatients: {},
    relativeTypes: [],
    requiredFields: {},
    origens: [],
    searchTerm: "",

    getParentId: computed(state => {
        let parentId = false;

        Object.keys(state.availablePatients).forEach((patientId) => {
            if (state.availablePatients[patientId].isUser) {
                parentId = patientId;
            }
        });

        return parentId;
    }),

    setLoading: action((state, isLoading) => {
        state.loading = isLoading;
    }),

    setError: action((state, error) => {
        state.error = error;
    }),

    setAvailablePatients: action((state, availablePatients) => {
        state.availablePatients = availablePatients;
    }),

    setRelativeTypes: action((state, relativeTypes) => {
        state.relativeTypes = relativeTypes;
    }),

    setValue: action((state, payload) => {
        let payloadKeys = Object.keys(payload);

        payloadKeys.forEach((key) => {
            if (state.hasOwnProperty(key)) {
                state[key] = payload[key];
            }
        })
    }),

    createPatient: action((state, patient) => {
        state.availablePatients[patient.id] = patient;
    }),

    deletePatient: action((state, patientId) => {
        delete state.availablePatients[patientId];
    }),

    updatePatient: action((state, patient) => {
        state.availablePatients[patient.id] = patient;
    }),

    addPatient: thunk(async (actions, patientInfo, {getStoreState, getState}) => {
        const availablePatients = getState().availablePatients;
        const slug = getStoreState().layout.slug;

        const verifyFields = await actions.verifyPatientFields(patientInfo);

        if (verifyFields.length > 0) throw new Error(`${verifyFields.missingNames.join(", ")} não informado(s).`);

        try {
            if (availablePatients.length === 0) {
                await insertUpdatePatient(slug, patientInfo);
            } else {
                const parentId = getState().getParentId;
                await insertUpdatePatient(slug, patientInfo, false, parentId, false);
            }

            actions.getAvailablePatients();
        } catch (e) {
            throw e;
        }
    }),

    removePatient: thunk(async (actions, patientInfo, {getStoreState, getState}) => {
        const slug = getStoreState().layout.slug;
        const parentId = getState().getParentId === patientInfo.id ? false : getState().getParentId;

        try {
            await insertUpdatePatient(slug, patientInfo, false, parentId, true);

            actions.deletePatient(patientInfo.id);
        } catch (e) {
            throw e;
        }
    }),

    editPatient: thunk(async (actions, patientInfo, {getStoreState, getState}) => {
        const slug = getStoreState().layout.slug;

        const verifyFields = await actions.verifyPatientFields(patientInfo);

        if (verifyFields.length > 0) throw new Error(`${verifyFields.missingNames.join(", ")} não informado(s).`);

        try {
            if (patientInfo.isUser) {
                await insertUpdatePatient(slug, patientInfo, false, false, false);
            } else {
                const parentId = getState().getParentId;
                await insertUpdatePatient(slug, patientInfo, false, parentId, false);
            }
        } catch (e) {
            throw e;
        }
    }),

    verifyPatientFields: thunk(async (actions, patient, {getStoreState, getState}) => {
        const layoutStore = getStoreState().layout;

        let requiredFields = getState().requiredFields;
        let missingFields = [];
        let missingNames = [];

        if (layoutStore.getConfig("convenioMatriculaObrigatoria") && patient.convenioId) {
            requiredFields = {...requiredFields, matricula: "Matricula do paciente"};
        }

        Object.keys(requiredFields).forEach((key) => {
            if (typeof patient[key] === "undefined" || !patient[key]) {
                missingFields.push(key);
                missingNames.push(requiredFields[key]);
            }
        });


        missingFields.missingNames = missingNames;
        return missingFields;
    }),

    getAvailablePatients: thunk(async (actions, payload, {getStoreState, getState}) => {
        actions.setLoading(true);

        try {
            const availablePatients = await getAvailablePatients(getStoreState().layout.slug, getStoreState().user.userInfo.isAdmin, getState().searchTerm);
            actions.setAvailablePatients(availablePatients);
        } catch (e) {
            console.log(e);
            throw e;
        }

        actions.setLoading(false);
    }),

    getRequiredFields: thunk(async (actions, payload, {getStoreState, getState}) => {
        actions.setLoading(true);

        try {
            const requiredFields = await getRequiredFields(getStoreState().layout.slug);
            actions.setValue({requiredFields: requiredFields});
        } catch (e) {
            throw e;
        }

        actions.setLoading(false);
    }),

    getOrigens: thunk(async (actions, payload, {getStoreState, getState}) => {
        actions.setLoading(true);

        try {
            const origens = await getOrigens(getStoreState().layout.slug);
            actions.setValue({origens: origens});
        } catch (e) {
            throw e;
        }

        actions.setLoading(false);
    }),

    getRelativeTypes: thunk(async (actions, payload, {getStoreState, getState}) => {
        actions.setLoading(true);

        try {
            const relativeTypes = await getRelativeTypes();
            actions.setRelativeTypes(relativeTypes);
        } catch (e) {
            throw e;
        }

        actions.setLoading(false);
    }),

    getInitialValues: thunk(async (actions, payload, {getStoreState, getStoreActions}) => {
        const patientSelectedValue = getStoreState().steps.availableSteps["paciente"].selectedValue;

        if (!patientSelectedValue) {
            const stepsActions = getStoreActions().steps;

            const patientId = payload.patientId;
            const patientName = payload.selectedValues["paciente"];

            stepsActions.setSelectedValue({
                "paciente": patientName
            });

            stepsActions.setStepData({
                "paciente": {
                    patientId: patientId
                }
            });
        }
    }),
};

export default patientModel;
