import React from 'react';
import {useStoreActions, useStoreState} from "easy-peasy";
import {Col} from "shards-react";
import {formatDate, getStepUrl} from "../../utils/utils";
import {withRouter} from "react-router-dom";

const HourBadge = (props) => {
    const layoutStore = useStoreState(s => s.layout);
    const stepStore = useStoreState(s => s.steps);
    const stepActions = useStoreActions(a => a.steps);
    const isSelected = stepStore.availableSteps["horario-profissional"].data.hour === props.hour && stepStore.availableSteps["horario-profissional"].data.professionalId === props.professional.professionalId && props.date === stepStore.availableSteps["data"].data.dateStart;

    const getProfessionalName = () => {
        const professionalName = props.professional.professionalInfo.nome;

        if (professionalName.length > 20)
            return professionalName.substr(0, 20) + "...";


        return professionalName;
    };

    const onHourSelect = () => {
        stepActions.setSelectedValue({
            "horario-profissional": getProfessionalName() + " às " + props.hour + "h",
            "data": formatDate(props.date, "d/m/y")
        });

        stepActions.setStepData({
            "data": {
                dateStart: props.date,
            },
            "horario-profissional": {
                "professionalId": props.professional.professionalId,
                "hour": props.hour,
                "gradeId": props.gradeId,
                "localId": props.localId,
            }
        });

        const nextStep = stepActions.toNextStep();
        props.history.push(getStepUrl(layoutStore.slug, nextStep, stepStore.getParams))
    };

    const badgeStyle = `
        .hour-badge {
            margin: 5px 5px;
            text-align: center;
            font-size: 12px;
            color: ${layoutStore.primaryColor};
            border: 1px solid ${layoutStore.primaryColor};
            font-family: Montserrat;
            font-weight: 600;
            padding: 8px 1em;
            cursor: pointer;
            border-radius: 5px;
        }
        
        .hour-badge:hover {
            color: white;
            background-color: ${layoutStore.primaryColor};
        }
        
        .hour-badge-active {
            color: white;
            background-color: ${layoutStore.primaryColor};
        }
    `;

    const badgeActive = () => {
        if (isSelected) {
            return "hour-badge-active"
        }
    };

    return (
        <Col onClick={() => onHourSelect()} xs={3} sm={3} md={1}
             className={`hour-badge btn-horario-hover ${badgeActive()}`}>
            <style>{badgeStyle}</style>
            {props.hour}
        </Col>
    )
};


export default withRouter(HourBadge);