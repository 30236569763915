import React, {useState, useEffect} from 'react';
import {Button, Fade, ModalBody, ModalFooter, ModalHeader} from "shards-react";
import {useStoreActions, useStoreState} from "easy-peasy";
import Loader from 'react-loader-spinner';
import {getScheduleQueueStatus, updateQueueStatus} from "../../services/scheduleService"

const ConfirmScheduleAlert = () => {
    const [loading, setLoading] = useState(true);
    const [queueStatus, setQueueStatus] = useState(true);
    const stepsState = useStoreState(s => s.steps);
    const userState = useStoreState(s => s.user);
    const layoutState = useStoreState(s => s.layout);
    const isTelemedicine = stepsState.getParams.isTelemedicine;
    const specialtyId = stepsState.getParams.selectedProcedures[0].specialtyId;
    const procedureId = isTelemedicine ? stepsState.getParams.selectedProcedures[0].telemedicineId : stepsState.getParams.selectedProcedures[0].procedureId;
    const slug = layoutState.slug;

    const onUpdateQueueStatus = async (type) => {
        setLoading(true);
        const updateStatus = await updateQueueStatus(slug, type, specialtyId, procedureId);
        setQueueStatus(updateStatus.isOnQueue);
        setLoading(false);
    };

    const getQueueStatus = async () => {
        setLoading(true);
        const status = await getScheduleQueueStatus(slug, specialtyId, procedureId);
        setQueueStatus(status.isOnQueue);
        setLoading(false);
    };

    useEffect(() => {
        getQueueStatus();
    }, []);

    if (loading) {
        return (
            <div style={{display: 'flex', height: 300, justifyContent: 'center', alignItems: 'center'}}>
                <Loader
                    type="Oval"
                    color="rgba(0,0,0,0.2)"
                    height={100}
                    width={100}
                />
            </div>
        )
    }

    if (!loading && queueStatus) {
        return (
            <Fade>
                <ModalHeader style={{textAlign: "center"}} titleClass="modal-login-title">Fila de espera</ModalHeader>
                <ModalBody style={{textAlign: 'center'}}>
                    <p><b>Pronto, você está registrado na fila de espera!</b></p>
                    <p>Assim que novos horários para <b>{stepsState.getParams.selectedValues.especialidade}</b> estiverem disponíveis, você receberá
                        uma notificação em: <br/><b>{userState.userInfo.email}</b></p>
                </ModalBody>
                <ModalFooter style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                    <Button onClick={() => onUpdateQueueStatus("delete")} theme="danger">Remover vaga da fila de espera</Button>
                </ModalFooter>
            </Fade>
        )
    }

    return (
        <Fade>
            <ModalHeader style={{textAlign: "center"}} titleClass="modal-login-title">Confirmar a notificação</ModalHeader>
            <ModalBody>
                <p>Infelizmente não existem horários disponíveis no momento para a
                    especialidade <b>{stepsState.getParams.selectedValues.especialidade}</b>.</p>
                <p>Gostaria de receber uma notificação no email <b>{userState.userInfo.email}</b> quando novos horários estiverem disponíveis?</p>
            </ModalBody>
            <ModalFooter style={{textAlign: 'center', justifyContent: 'center', alignItems: 'center'}}>
                <Button onClick={() => onUpdateQueueStatus("create")} primary>Me envie uma notificação</Button>
            </ModalFooter>
        </Fade>
    )
};

export default ConfirmScheduleAlert;