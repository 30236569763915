import {action, computed, thunk} from 'easy-peasy';
import {getLayoutInfo} from "../../services/layoutService";

const layoutModel = {
    loading: false,
    error: false,
    slug: false,
    modalContent: false,
    primaryColor: "",
    secondaryColor: "",
    tertiaryColor: "",
    redirectError: false,
    clinicName: "",
    matrizId: "",
    logoURL: "https://whitesquareproperties.com.au/wp-content/uploads/2016/08/logo.png",
    layoutConfigs: [],

    showingModal: computed(state => state.modalContent ? true : false),

    setModalContent: action((state, payload) => {
        state.modalContent = payload;
    }),

    setLoading: action((state, payload) => {
        state.loading = payload;
    }),

    setError: action((state, payload) => {
        state.error = payload;
    }),

    setValue: action((state, payload) => {
        let payloadKeys = Object.keys(payload);

        payloadKeys.forEach((key) => {
            if (state.hasOwnProperty(key)) {
                state[key] = payload[key];
            }
        })
    }),

    getConfig: computed(state => configKey => {
        let result = false;

        state.layoutConfigs.forEach((config) => {
            if (config.key === configKey) {
                if (config.valor === 0 || config.valor == 0 || !config.valor || config.valor == "false") {
                    result = false;
                    return;
                }
                result = config.valor;
            }
        });

        return result;
    }),

    getLayoutInfo: thunk(async (actions, payload, {getStoreState, getState}) => {
        const params = getStoreState().steps.getParams;

        try {
            const layoutInfo = await getLayoutInfo(getState().slug, params.campaign);

            actions.setValue({
                primaryColor: layoutInfo.cor1,
                secondaryColor: layoutInfo.cor2,
                tertiaryColor: layoutInfo.cor3,
                logoURL: layoutInfo.logo,
                clinicName: layoutInfo.clinica.nomeClinica,
                matrizId: layoutInfo.clinica.unidadeMatrizId,
                layoutConfigs: layoutInfo.outrasConfig,
            });

            return true;
        } catch (e) {
            throw e;
        }
    }),
};
export default layoutModel;
