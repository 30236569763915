import {gql} from 'apollo-boost';
import client from "../utils/ApolloClient";
import moment from "moment";

export const GET_LAYOUT_INFO = gql`
  query getLayoutInfo($slug: String!, $campaign: String) {
        configWebSite(slug: $slug, campaign: $campaign){
        id
        cor1
        cor2
        cor3
        logo,
        buscaId,
        outrasConfig {
            key,
            valor
        }
        clinica {
            nomeClinica
            unidadeMatrizId
        }
    }
  }
`;

export const GET_AVAILABLE_STEPS = gql`
  query getAvailableSteps($slug: String!) {
      flow(slug: $slug){
        id
        nomeFluxo,
        slugFluxo
        clinicaFluxo {
          autenticado,
          alterarData
        }
      }
  }
`;

export const getLayoutInfo = async (slug, campaign) => {
    const layoutInfoQuery = await client.query({
        query: GET_LAYOUT_INFO,
        variables: {
            slug: slug,
            campaign: campaign
        }
    });

    if (!layoutInfoQuery.data.configWebSite) throw new Error("Nenhuma configuração de layout encontrada");
    if (!layoutInfoQuery.data.configWebSite.id) throw new Error("Nenhuma clínica encontrada");

    localStorage.setItem("feegow-agendamento-busca-id", layoutInfoQuery.data.configWebSite.buscaId);
    return layoutInfoQuery.data.configWebSite;
};

export const getAvailableSteps = async (slug) => {
    try {
        const availableStepsInfo = await client.query({
            query: GET_AVAILABLE_STEPS,
            variables: {slug: slug}
        });

        if (availableStepsInfo.data.flow.length === 0) throw new Error("Fluxo de etapas não configurado");

        let availableSteps = {};
        availableStepsInfo.data.flow.forEach((step) => {
            availableSteps[step.slugFluxo] = {
                name: step.nomeFluxo,
                needsAuth: step.clinicaFluxo.autenticado === 1,
                selectedValue: false,
                canSelectDate: step.clinicaFluxo.alterarData === 1,
                isDate: step.slugFluxo === "data",
                data: {}
            };

            if (step.slugFluxo === "data") {
                availableSteps[step.slugFluxo].data.dateStart = moment();
                availableSteps[step.slugFluxo].selectedValue = moment().format("DD/MM/YYYY");
            }
        });

        availableSteps["paciente"] = {
            "name": "Paciente",
            "needsAuth": true,
            "selectedValue": false,
            "canSelectDate": false,
            "data": {}
        };

        availableSteps["confirmar"] = {
            "name": "Confirmar",
            "needsAuth": true,
            "selectedValue": false,
            "canSelectDate": false,
            "data": {}
        };

        return availableSteps;
    } catch (e) {
        throw e;
    }
};
