import React, {useState} from 'react';
import {
    Col,
    Row,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    Collapse, NavItem
} from "shards-react";
import {faBars, faCaretDown, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useStoreState} from "easy-peasy";

import './NavTop.css'

import CurrentUser from './components/CurrentUser';
import moment from "moment";
import {faCalendar} from "@fortawesome/free-regular-svg-icons/faCalendar";
import DatePicker from "../SideMenu/components/DatePicker";

const NavTop = (props) => {
    const [collapseOpen, setCollapseOpen] = useState(false);
    const [callendarOpen, setCallendarOpen] = useState(false);
    const layout = useStoreState(state => state.layout);
    const stepsStore = useStoreState(s => s.steps);

    const selectedDate = stepsStore.availableSteps["data"].data.dateStart;

    const displayDate = () => {
        if (stepsStore.availableSteps[stepsStore.currentStep].canSelectDate && !layout.loading) {
            return (
                <Col onClick={() => setCallendarOpen(!callendarOpen)} xs="5"
                     style={{textAlign: "center", cursor: "pointer"}}>
                    <p style={{marginTop: 10, color: layout.primaryColor, fontSize: 16}}><FontAwesomeIcon
                        icon={faCalendar}/> {moment(selectedDate).format("DD/MM/YYYY")}
                        <FontAwesomeIcon style={{marginLeft: 8}} icon={faCaretDown}/></p>
                </Col>
            )
        } else {
            return null;
        }
    };

    return (
        <div style={{height: "100%"}}>
            <Row>
                <Col className="nav-top-container">
                    <Navbar className="nav-top-content" type="light" expand="md">
                        <div></div>
                        {/*<NavbarToggler onClick={() => setCollapseOpen(!collapseOpen)}>*/}
                        {/*    <FontAwesomeIcon color={layout.primaryColor} className="nav-top-toggle-icon"*/}
                        {/*                     icon={collapseOpen ? faTimes : faBars}/>*/}
                        {/*</NavbarToggler>*/}
                        <NavbarBrand className="d-sm-none" href="#">
                            <img alt="feegow-mobile-logo" className="nav-top-logo" src={layout.logoURL}/>
                        </NavbarBrand>
                        <Collapse open={collapseOpen} navbar>
                            <Nav className="d-sm-none" navbar>
                                ver oq vai entrar
                            </Nav>
                        </Collapse>
                        <Nav>
                            <div style={{marginTop: 25}} className="d-none d-md-block">
                                <NavItem style={{cursor: "pointer"}} className="nav-top-user">
                                    <CurrentUser/>
                                </NavItem>
                            </div>
                        </Nav>
                    </Navbar>
                </Col>
            </Row>
            <Row style={{marginTop: 20}} className="d-sm-none">
                {displayDate()}
                <Col style={{cursor: "pointer", zIndex: 999}} xs="7">
                    <CurrentUser/>
                </Col>
            </Row>
            <Row style={{marginTop: 20}} className="d-sm-none">
                <Col style={{textAlign: "center"}}>
                    <Collapse open={callendarOpen}>
                        <DatePicker hideSelectedDate={true}/>
                    </Collapse>
                </Col>
            </Row>
            <Row>
                <Col>
                    {props.children}
                </Col>
            </Row>
        </div>
    );
};

export default NavTop;