import React, {useEffect} from 'react';
import {useLocation, withRouter} from "react-router-dom";
import {Button, Col, Row, Fade} from "shards-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhone, faPrint, faStethoscope, faUserMd} from "@fortawesome/free-solid-svg-icons";
import {useStoreActions, useStoreState} from "easy-peasy";
import {faIdCard, faUser, faCreditCard} from "@fortawesome/free-regular-svg-icons";
import {faCalendarAlt} from "@fortawesome/free-regular-svg-icons/faCalendarAlt";
import {formatDate} from "../../utils/utils";
import MostrarLocalizacao from "../Confirmar/MostrarLocalizacao";
import {redirectToPayment} from "../../services/confirmationService";
import {faHospital} from "@fortawesome/free-regular-svg-icons/index"


const Confirmacao = (props) => {
    const layoutState = useStoreState(s => s.layout);
    const confirmationStore = useStoreState(s => s.confirmation);
    const confirmationActions = useStoreActions(a => a.confirmation);
    const stepsState = useStoreState(s => s.steps);
    const patientActions = useStoreActions(a => a.patient);
    const scheduleActions = useStoreActions(a => a.schedule);
    const unitActions = useStoreActions(a => a.units);
    const procedureActions = useStoreActions(a => a.procedure);
    const stepsActions = useStoreActions(a => a.steps);

    const confirmDetails = confirmationStore.confirmationDetails;
    const nomeUnidade = confirmDetails ? confirmDetails.Unidade.nomeUnidade : null;
    const enderecoUnidade = confirmDetails ? confirmDetails.Unidade.endereco : null;
    const patientName = confirmDetails ? confirmDetails.Paciente.nome : null;
    const professionalName = confirmDetails ? confirmDetails.Profissional.nome : null;
    const telClinica = confirmDetails ? confirmDetails.Unidade.telClinica : null;

    const callNumber = () => {
        window.open("tel:+55" + telClinica);
    };

    const printAppoint = () => {
        window.print();
    };

    const getPatientFirstName = () => {
        if (patientName) {
            return patientName.split(" ")[0];
        }
    };

    const renderSpecialties = () => {
        let specialties = [];

        if (confirmDetails) {
            confirmDetails.Procedimentos.forEach((procedure) => {
                if (procedure.especialidade) {
                    specialties.push(procedure.especialidade);
                }
            });
        }

        if (specialties.length === 0) return "Não definida";

        return specialties.join(", ");
    };

    const getDateAndHour = () => {
        const date = stepsState.getParams.dateStart;
        const hour = stepsState.getParams.hour;

        return formatDate(date, "weekDay") + " às " + hour + "h";
    };

    const onlinePayment = async () => {
        await redirectToPayment(stepsState.getParams.licenseId, stepsState.getParams.patientFeegowId, stepsState.getParams.appointId)
    };

    const renderPaymentButton = () => {
        if (layoutState.getConfig('pagamentoOnline')) {
            return (
                <Fade><p style={{marginBottom: 15, fontSize: 16, opacity: 0.80, fontWeight: '400'}}>Você
                    pode
                    adiantar seu atendimento
                    realizando o pagamento antecipado.</p>
                    <Button style={{marginBottom: 10}} onClick={() => onlinePayment()}><FontAwesomeIcon
                        icon={faCreditCard}/> Realizar
                        Pagamento</Button></Fade>
            )
        } else {
            return null;
        }
    };

    const initialParams = {
        stepActions: confirmationActions,
        requiredQueryParams: [
            {param: "appointTypeId", name: "Tipo de agendamento"},
            {param: "selectedProcedures", name: "Procedimentos"},
            {param: "unityId", name: "Centro Médico"},
            {param: "professionalId", name: "Profissional"},
            {param: "hour", name: "Hora do agendamento"},
            {param: "dateStart", name: "Data do agendamento"},
        ],
        initialDataFunctions: [
            procedureActions.getInitialValues,
            unitActions.getInitialValues,
            scheduleActions.getInitialValues,
            patientActions.getInitialValues,
            confirmationActions.getInitialValues,
            confirmationActions.getConfirmationDetails,
        ]
    };

    useEffect(() => {
        stepsActions.setCurrentStep("confirmar");
        props.getStepInitialData(initialParams);
    }, []);

    return (
        <div>
            <style>{confirmationStyle}</style>
            <Row className="animated fadeIn">
                <Col style={{textAlign: "center"}}>
                    <p style={{
                        fontFamily: "Montserrat",
                        fontWeight: "800",
                        letterSpacing: -1.3,
                        fontSize: 24,
                        marginTop: 20,
                        color: layoutState.primaryColor
                    }}>Agendamento confirmado !</p>
                </Col>
            </Row>
            <Row id="print-appoint" className="animated fadeInUp faster">
                <Col style={{borderLeft: `6px solid ${layoutState.primaryColor}`}} md={{size: 8, offset: 2}}
                     className="confirm-card">
                    <Row>
                        <Col>
                            <p style={{marginBottom: 10}}>Olá, <span
                                style={{color: layoutState.primaryColor}}>{getPatientFirstName()}!</span>
                            </p>
                            <p style={{marginBottom: 20, fontSize: 16, opacity: 0.80}}>{layoutState.getConfig("textoConfirmacao")}</p>
                            {renderPaymentButton()}
                        </Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col>
                            {stepsState.getParams.isTelemedicine ?
                                null
                                :
                                <Row style={{paddingLeft: 10}}>
                                    <Col md="8">
                                        <p className="details-title">Centro médico:</p>
                                        <p className="details-unity-name"><FontAwesomeIcon color={layoutState.primaryColor}
                                                                                           icon={faHospital}/> {nomeUnidade}
                                        </p>
                                        <p className="details-unity-address">{enderecoUnidade}</p>
                                    </Col>
                                    <Col md="4">
                                        <MostrarLocalizacao address={enderecoUnidade}/>
                                    </Col>
                                </Row>
                            }
                            <Row style={{paddingLeft: 10, marginTop: 30}}>
                                <Col md="8">
                                    <p className="details-title">Paciente agendado:</p>
                                    <p className="details-patient-name"><FontAwesomeIcon
                                        color={layoutState.primaryColor}
                                        icon={faUser}/> {patientName}
                                    </p>
                                </Col>
                            </Row>
                            <Row style={{paddingLeft: 10, marginTop: 30}}>
                                <Col md="6">
                                    <p className="details-title">Profissional:</p>
                                    <p className="details-patient-name"><FontAwesomeIcon
                                        color={layoutState.primaryColor}
                                        icon={faUserMd}/> {professionalName}
                                    </p>
                                </Col>
                            </Row>
                            <Row style={{paddingLeft: 10, marginTop: 30}}>
                                <Col md="6">
                                    <p className="details-title">Especialidade:</p>
                                    <p className="details-patient-name"><FontAwesomeIcon
                                        color={layoutState.primaryColor}
                                        icon={faStethoscope}/> {renderSpecialties()}
                                    </p>
                                </Col>
                            </Row>
                            {typeof confirmDetails.Convenio !== "undefined" && confirmDetails.Convenio.id ?
                                <Row style={{paddingLeft: 10, marginTop: 30}}>
                                    <Col md="6">
                                        <p className="details-title">Convênio:</p>
                                        <p className="details-patient-name"><FontAwesomeIcon
                                            color={layoutState.primaryColor}
                                            icon={faIdCard}/> {confirmDetails.Convenio.nome}
                                        </p>
                                    </Col>
                                </Row> : null}
                            <Row style={{paddingLeft: 10, marginTop: 30}}>
                                <Col md="9">
                                    <p className="details-title">Data & Hora:</p>
                                    <p className="details-patient-name"><FontAwesomeIcon
                                        color={layoutState.primaryColor}
                                        icon={faCalendarAlt}/> {getDateAndHour()}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <hr/>
                    <Row style={{marginTop: 15}}>
                        <Col>
                            <Button onClick={() => printAppoint()}><FontAwesomeIcon icon={faPrint}/> Imprimir
                                comprovante</Button>
                            {telClinica ?
                                <Button onClick={() => callNumber()} style={{marginLeft: 10}} outline><FontAwesomeIcon
                                    icon={faPhone}/> Entrar em contato</Button> : null}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
};

const confirmationStyle = `
    .confirm-card {
        background-color: white;
        border-radius: 5px;
        padding: 30px;
        box-shadow: 00 0.125em 0.313em rgba(50,50,93,.09), 0 0.063em 0.125em rgba(0,0,0,.07);
    }
    .details-title {
        margin-bottom: 3px;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 14px;
        opacity: 0.6;
        letter-spacing: -0.50px
    }
    .details-unity-name {
        margin-bottom: 2px;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 14px;
        letter-spacing: -0.50px
    }
    .details-unity-address {
        margin-bottom: 0px;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 14px;
        letter-spacing: -0.50px
    }
    .details-patient-name {
        margin-bottom: 0px;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 14px;
        letter-spacing: -0.50px
    }
    
    .confirm-card-title {
        font-size: 16px;
        font-family: "Montserrat";
        font-weight: 600;
        letter-spacing: -0.50px;
        margin-bottom: 12px;
    }
    
    .prepare-step {
        font-size: 14px;
        margin-bottom: 10px;
    }
`;


export default withRouter(Confirmacao);
