import React from 'react';
import {Button, Col, Row} from "shards-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {formatDate} from "../../utils/utils";
import {useStoreActions, useStoreState} from "easy-peasy";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons/index.es"
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus"
import ConfirmScheduleAlert from "../../steps/HorariosProfissionais/ConfirmScheduleAlert"
import LoginModal from "../Modal/LoginModal"

const DataNotFound = () => {
    const userState = useStoreState(s => s.user);
    const layoutState = useStoreState(s => s.layout);
    const layoutActions = useStoreActions(a => a.layout);
    const stepsState = useStoreState(s => s.steps);
    const scheduleActions = useStoreActions(a => a.schedule);

    const handleNextMonth = () => {
        const nextMonthDay = moment(stepsState.getParams.dateStart).add(1, 'months').startOf('month').format("YYYY-MM-DD");
        scheduleActions.onMonthChange(nextMonthDay);
    };

    const confirmScheduleAlert = () => {
        if (!userState.userInfo) {
            layoutActions.setModalContent(<LoginModal/>)
            return false;
        }

        layoutActions.setModalContent(<ConfirmScheduleAlert/>)
    }

    return (
        <div>
            <Row>
                <Col style={{textAlign: "center", marginTop: 140, opacity: 0.20}}>
                    <FontAwesomeIcon style={{fontSize: 32}} icon={faSearch}/>
                    <p style={{fontSize: 24, marginTop: 20, fontWeight: "500"}}>Nenhum horário encontrado</p>
                </Col>
            </Row>
            <Row style={{textAlign: "center", justifyContent: 'center', alignItems: 'center'}}>
                <Button style={{margin: 10}} onClick={() => handleNextMonth()} outline><FontAwesomeIcon icon={faPlus}/> Buscar mais horários</Button>
                {layoutState.getConfig("filaEsperaHorarios") ?
                    <Button style={{margin: 10}} onClick={() => confirmScheduleAlert()}><FontAwesomeIcon icon={faEnvelope}/> Avise-me quando
                        disponível</Button> : null}
            </Row>
        </div>
    )
};

export default DataNotFound;