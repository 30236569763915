import React, {useEffect} from 'react';
import {faIdCard} from "@fortawesome/free-regular-svg-icons/index"
import {Col, Fade, FormSelect, Row} from "shards-react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es"
import {faDollarSign} from "@fortawesome/free-solid-svg-icons/index"
import {useStoreActions, useStoreState} from "easy-peasy";
import Hint from "./Hint"
import ConvenioOptions from "./ConvenioOptions"
import PlanoOptions from "./PlanoOptions"

const SelectConvenio = () => {
    const layoutStore = useStoreState(s => s.layout);
    const scheduleState = useStoreState(s => s.schedule);
    const stepsState = useStoreState(s => s.steps);
    const stepsActions = useStoreActions(a => a.steps);
    const scheduleActions = useStoreActions(a => a.schedule);

    if (!layoutStore.getConfig('agendaConvenio')) {
        return null
    }

    if (Object.keys(scheduleState.convenios).length === 0) {
        return null;
    }

    if (layoutStore.getConfig("bloquearTelemedicinaConvenio") && stepsState.getParams.isTelemedicine) {
        return null;
    }

    const changeConvenio = (convenioId) => {
        if (convenioId == 'false') convenioId = false;

        stepsActions.setStepData({
            "centro-medico": {
                convenioId: convenioId
            }
        });

        scheduleActions.getAvailableSchedule();
    };

    const changePlano = (planoId) => {
        if (planoId == 'false') {
            planoId = false;
        }

        stepsActions.setStepData({
            "centro-medico": {
                planoId: planoId
            }
        });

        scheduleActions.getAvailableSchedule();
    };

    const convenioIsDisabled = stepsState.getParams.blockDefaultParams && stepsState.getParams.blockDefaultParams.length > 0 && stepsState.getParams.blockDefaultParams.includes("convenio") && stepsState.getParams.convenioId;
    const showPlanos = stepsState.getParams.convenioId && scheduleState.convenios[stepsState.getParams.convenioId].planos.length > 0;

    return (
        <div id="select-convenio" style={{flex: 1, display: "flex", flexDirection: "row"}}>
            <Col md="4" style={{maxWidth: 200}}>
                <FontAwesomeIcon style={{position: "absolute", top: 13, left: 25}}
                                 color={layoutStore.primaryColor}
                                 icon={faIdCard}/>
                <FormSelect disabled={convenioIsDisabled} value={stepsState.getParams.convenioId}
                            onChange={(e) => changeConvenio(e.target.value)}
                            style={{paddingLeft: 33}} className="custom-select-feegow">
                    <option value={false}>Particular</option>
                    <ConvenioOptions/>
                </FormSelect>
            </Col>
            <Hint enabled={!stepsState.getParams.convenioId}
                  hint={layoutStore.getConfig("textoConvenioHint")}/>
            {showPlanos && <Col md="4" style={{maxWidth: 200}}>
                <FontAwesomeIcon style={{position: "absolute", top: 13, left: 25}}
                                 color={layoutStore.primaryColor}
                                 icon={faDollarSign}/>
                <FormSelect value={stepsState.getParams.planoId} onChange={(e) => changePlano(e.target.value)}
                            style={{paddingLeft: 28}} className="custom-select-feegow">
                    <option value={false}>Plano</option>
                    <PlanoOptions/>
                </FormSelect>
            </Col>}
        </div>
    )
};

export default SelectConvenio;
