import React, {useEffect} from 'react';
import {Col, Container, Modal, Row} from "shards-react";
import './Layout.css';
import {useStoreState, useStoreActions} from "easy-peasy";
import LoadingOverlay from 'react-loading-overlay';
import {useHistory, useRouteMatch} from "react-router-dom";


import SideMenu from '../SideMenu/SideMenu';
import NavTop from '../NavTop/NavTop';
import ContentComponent from "../Content/ContentComponent";
import {BrowserRouter as Router, useLocation, withRouter} from "react-router-dom";
import {redirectResult} from "../../services/firebaseService";
import LayoutError from "./LayoutError";
import LoginModal from "../Modal/LoginModal";
import ReactGA from 'react-ga';
import {getUrlParams} from "../../utils/utils"
import TagManager from 'react-gtm-module'

const Layout = (props) => {
    const showingModal = useStoreState(s => s.layout.showingModal);
    const modalContent = useStoreState(s => s.layout.modalContent);
    const setModalContent = useStoreActions(a => a.layout.setModalContent);
    const userActions = useStoreActions(a => a.user);
    const layoutStore = useStoreState(s => s.layout);
    const layoutActions = useStoreActions(a => a.layout);
    const stepsActions = useStoreActions(a => a.steps);
    let history = useHistory();
    const routeMatch = useRouteMatch("/agendamento/:slug");

    const feegowComponentStyles = `
body {
    overflow: hidden
}
.rc-steps-item-finish .rc-steps-item-tail:after {
    background-color: ${layoutStore.primaryColor};
}
.custom-radio .custom-control-input:checked~.custom-control-label::before {
    background-color: ${layoutStore.primaryColor};
}

.custom-control-input:checked ~ .custom-control-label::before {
    background-color: ${layoutStore.primaryColor};
}

.form-control:focus:hover {
    border-color: ${layoutStore.primaryColor};
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: ${layoutStore.primaryColor};
    box-shadow: 0 .313rem .719rem rgba(0, 123, 255, .1), 0 .156rem .125rem rgba(0, 0, 0, .06)
}

.btn-outline-primary {
    background-color: transparent;
    background-image: none;
    border-color: ${layoutStore.primaryColor};
    color: ${layoutStore.primaryColor};
}

.btn-outline-primary:hover {
    color: white;
    background-color: ${layoutStore.primaryColor};
    border-color: ${layoutStore.primaryColor};
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05), 0 4px 10px rgba(0, 123, 255, .25)
}

.btn-outline-secondary {
    background-color: transparent;
    background-image: none;
    border-color: ${layoutStore.secondaryColor};
    color: ${layoutStore.secondaryColor};
}

.btn-outline-secondary:hover {
    color: white;
    background-color: ${layoutStore.secondaryColor};
    border-color: ${layoutStore.secondaryColor};
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05), 0 4px 10px rgba(0, 123, 255, .25)
}

.btn-primary.disabled, .btn-primary:disabled {
    background-color: ${layoutStore.primaryColor};
    border-color: ${layoutStore.primaryColor};
    box-shadow: none;
    cursor: not-allowed;
}

.btn-primary {
    color: #fff;
    border-color: ${layoutStore.primaryColor};
    background-color: ${layoutStore.primaryColor};
    box-shadow: none
}

.btn-primary:hover {
    color: #fff;
    background-color: ${layoutStore.primaryColor};
    border-color: ${layoutStore.primaryColor};
    box-shadow: 0 5px 15px rgba(0, 0, 0, .05), 0 4px 10px rgba(0, 123, 255, .25)
}

.custom-select-feegow {
    border: solid 1px ${layoutStore.primaryColor};
    background: #f6f8fa url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
    background-size: 8px 10px;
    color: ${layoutStore.primaryColor}
}

.noUi-connect {
    background-color: ${layoutStore.primaryColor}
}

.noUi-target {
    margin-top: 15px;
}

.badge-primary {
    background-color: ${layoutStore.primaryColor}
}


`;

    const toggleModal = () => {
        setModalContent(false);
    };

    const getRedirectResult = async () => {
        try {
            const loginInfo = await redirectResult();

            if (loginInfo.user) {
                const userInfo = await userActions.signIn({
                    userId: loginInfo.user.uid,
                    email: loginInfo.user.email,
                    displayName: loginInfo.user.displayName,
                    photo: loginInfo.user.photoURL
                });


                userActions.setValue({userInfo: userInfo});
                layoutActions.setModalContent(false);
            }

        } catch (e) {
            layoutActions.setValue({
                "redirectError": e.message,
            })
        }
    };

    const applyGoogleAnalytics = () => {

        let analyticsTags = [{
            trackingId: 'UA-54670639-6',     // tag da feegow
            gaOptions: {
                name: 'trackerFeegow'
            }
        }];
        const analyticsTag = layoutStore.getConfig("analyticsTag");
        const gtmTag = layoutStore.getConfig("gtmTag");

        if(gtmTag){
            const tagManagerArgs = {
                gtmId: gtmTag
            }
             
            TagManager.initialize(tagManagerArgs)            
        }

        if (analyticsTag) {
            analyticsTags.push({
                trackingId: analyticsTag,
                gaOptions: {
                    name: 'trackerClinica'
                }
            })
        }

        ReactGA.initialize(analyticsTags);
        ReactGA.pageview(window.location.pathname + window.location.search, ['trackerFeegow', 'trackerClinica']);
    };

    const getStorageAuth = async (slug) => {
        let userInfo = false;

        const localStorageUserInfo = localStorage.getItem("userInfo-" + slug);

        if (localStorageUserInfo && typeof localStorageUserInfo !== "undefined" && localStorageUserInfo !== "undefined") {
            userInfo = JSON.parse(localStorageUserInfo);
            userActions.setValue({userInfo: userInfo})
        }
    };

    const getTokenAuth = async () => {
        const location = Object.assign({}, history.location);
        const urlParams = getUrlParams(false, location.search);
        if (!urlParams.tk) return false;

        const token = urlParams.tk;

        delete urlParams['tk'];
        history.push({
            pathname: location.pathname, state: urlParams
        });

        const userInfo = await userActions.signIn({
            token: token,
        });


        userActions.setValue({userInfo: userInfo});
        layoutActions.setModalContent(false);
    };

    const getCampaign = async () => {
        const location = Object.assign({}, history.location);
        const urlParams = getUrlParams(false, location.search);
        if (!urlParams.c) return false;

        stepsActions.setStepData({
            "confirmar": {
                campaign: urlParams.c
            }
        });

        return true;
    };

    const getSlug = async () => {
        if (!routeMatch || typeof routeMatch.params.slug === "undefined" || !routeMatch.params.slug) throw new Error("Clínica não especificada");
        layoutActions.setValue({slug: routeMatch.params.slug})
        localStorage.setItem("slug", routeMatch.params.slug);
        return routeMatch.params.slug;
    };

    const getLayoutInfo = async () => {
        layoutActions.setLoading(true);

        try {
            const slug = await getSlug();
            await getStorageAuth(slug);
            await getCampaign();
            await layoutActions.getLayoutInfo();
            await stepsActions.getAvailableSteps();
            await getRedirectResult();
            await getTokenAuth();
            applyGoogleAnalytics();
        } catch (e) {
            layoutActions.setError(e.message);
        }

        layoutActions.setLoading(false);
    };

    useEffect(() => {
        getLayoutInfo();
    }, []);

    useEffect(() => {
        document.title = 'Agendamento - ' + layoutStore.clinicName
    }, [layoutStore.clinicName]);

    return (
        <LoadingOverlay
            className="layout-loader"
            active={layoutStore.loading}
            spinner
            text='Carregando informações, aguarde um momento...'
        >
            <style>{feegowComponentStyles}</style>
            <Container className="h-100" fluid={true}>
                <Router>
                    <Row className="h-100">
                        <Modal fade={true} animation={true} open={showingModal} toggle={toggleModal}>
                            {modalContent}
                        </Modal>
                        <Col className="side-menu-container d-none d-md-block">
                            <SideMenu/>
                        </Col>
                        <Col className="main-content layout-container h-100">
                            <NavTop>
                                {!layoutStore.error && !layoutStore.loading && layoutStore.primaryColor ?
                                    <ContentComponent/> :
                                    <LayoutError error={layoutStore.error}/>}
                            </NavTop>
                        </Col>
                    </Row>
                </Router>
            </Container>
        </LoadingOverlay>
    )
};

export default withRouter(Layout);
