import React from 'react';
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import Loader from 'react-loader-spinner';
import {useStoreState} from "easy-peasy";
import {Col, Row} from "shards-react";


const ComponentOverlay = (props) => {
    const layoutStore = useStoreState(s => s.layout);

    if (props.error) {
        return (
            <Row>
                <Col style={{textAlign: "center", marginTop: 140, opacity: 0.20}}>
                    <FontAwesomeIcon style={{fontSize: 32}} icon={faTimes}/>
                    <p style={{fontSize: 24, marginTop: 20, fontWeight: "500"}}>{props.error}</p>
                </Col>
            </Row>
        )
    }

    if (props.loading && !layoutStore.loading) {
        return (
            <div style={{textAlign: "center", marginTop: 120}}>
                <Loader
                    type="Oval"
                    color="rgba(0,0,0,0.2)"
                    height={100}
                    width={100}
                />
            </div>
        )
    }

    return props.children;
};

export default ComponentOverlay;