import React from 'react';
import client from "../utils/ApolloClient";
import {gql} from "apollo-boost";

import {ReactComponent as ExamIcon} from '../img/exams.svg';
import {ReactComponent as SpecialtyIcon} from '../img/steto.svg';
import {ReactComponent as ReturnIcon} from '../img/return.svg';
import {ReactComponent as OdontoIcon} from '../img/odonto.svg';

export const GET_APPOINT_TYPES = gql`
  query getAppointTypes($slug: String!) {
    typeProcedure(slug: $slug){
        id,
        tipoProcedimento,
        permite,
        clinicatipoprocedimento {
            allowMultiple
        }
    }
  }
`;

export const GET_APPOINT_TYPE_OPTIONS = gql`
  query getAppointTypes($slug: String!, $appointTypeId: Int!) {
      priceTypeProcedure(slug: $slug, TipoProcedimentoID: $appointTypeId){
        especialidadeID
        procedimentoValorID
        procedimentoID
        consultaOnlineId
        consultaOnlineValor
        nomeProcedimento
        nomeEspecialidade
        nomeTipoProcedimento
        valor
        naoNecessitaAgendamento
        permitePagamentoOnline
      }
  }
`;

export const GET_AVAILABLE_RETURNS = gql`
    query getAvailableReturns($slug: String!, $dateStart: String!){
      AppointmentAttendedWithReturnQuery(slug: $slug, dateStart: $dateStart){
        ID
        DataLimite
        Data
        Horario
        RetornoID
        UnitAvailable {
          id,
          nomeUnidade
        }
        Procedimentos {
          id,
          especialidade
        }
        Profissional {
          id,
          nome
        }
        Paciente {
          id,
          nome
        }
      }
    }
`;

export const getAppointTypes = async (slug) => {
    const appointTypesInfo = {
        "2": {
            "id": 2,
            "name": "Consulta",
            "icon": (fillColor) => {
                return <SpecialtyIcon width={35} height={35} fill={fillColor}/>
            },
            "placeholder": "Digite o nome de uma especialidade",
            "multiselect": false,
            "enabled": true,
        },
        "3": {
            "id": 3,
            "name": "Exames",
            "icon": (fillColor) => {
                return <ExamIcon width={35} height={35} fill={fillColor}/>
            },
            "placeholder": "Digite o nome de um exame",
            "multiselect": true,
            "enabled": false
        },
        "9": {
            "id": 9,
            "name": "Retorno",
            "icon": (fillColor) => {
                return <ReturnIcon width={35} height={35} fill={fillColor}/>
            },
            "placeholder": "Digite o nome de uma especialidade",
            "multiselect": false,
            "enabled": true,
            "isReturn": true,
        }
    };

    try {
        const appointTypesQuery = await client.query({
            query: GET_APPOINT_TYPES,
            variables: {slug: slug}
        });

        if (appointTypesQuery.data.typeProcedure.length === 0) throw new Error("Nenhum tipo de agendamento encontrado");

        let result = [];
        appointTypesQuery.data.typeProcedure.forEach((appointType) => {
            if (typeof appointTypesInfo[appointType.id] !== "undefined") {
                appointTypesInfo[appointType.id].enabled = appointType.permite;
                result.push(appointTypesInfo[appointType.id]);
            }
        });

        return result;
    } catch (e) {
        throw e;
    }
};

export const getAppointTypeOptions = async (slug, appointTypeId) => {
    const formatValues = (values) => {
        return values.map((value) => {
            return {
                procedureId: value.procedimentoID,
                specialtyId: value.especialidadeID,
                telemedicineId: value.consultaOnlineId,
                telemedicineAmount: value.consultaOnlineValor,
                name: appointTypeId === 2 ? value.nomeEspecialidade : value.nomeProcedimento,
                appointTypeName: value.nomeTipoProcedimento,
                amount: value.valor,
                allowAppointment: value.naoNecessitaAgendamento === "S",
                allowPayment: value.PermitePagamentoOnline === "S",
            }
        })
    };

    const appointTypeOptionsQuery = await client.query({
        query: GET_APPOINT_TYPE_OPTIONS,
        variables: {slug: slug, appointTypeId: appointTypeId}
    });

    return formatValues(appointTypeOptionsQuery.data.priceTypeProcedure);
};

export const getAvailableReturns = async (slug, dateStart) => {
    const availableReturnsQuery = await client.query({
        query: GET_AVAILABLE_RETURNS,
        variables: {
            slug: slug,
            dateStart: dateStart
        }
    });

    const returns = availableReturnsQuery.data.AppointmentAttendedWithReturnQuery;

    if (returns) {
        returns.forEach((returnInfo, i) => {
            if (typeof returns[i].Procedimentos[0].especialidade === 'string') {
                returns[i].Procedimentos[0].especialidade = JSON.parse(returns[i].Procedimentos[0].especialidade);
            }
        });
    }

    return returns;
};
