import React, {useState} from 'react';
import {useStoreState} from "easy-peasy";
import {Col, Dropdown, DropdownItem, DropdownMenu, Row} from "shards-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import ProfessionalCard from "./ProfessionalCard";
import {formatDate} from "../../utils/utils";

const ScheduleDate = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [sortBy, setSortBy] = useState("slots");
    const layoutStore = useStoreState(s => s.layout);

    const sortFunctions = {
        "slots": {
            "name": "Disponibilidade",
            "function": (a, b) => {
                return b.availableHours.length - a.availableHours.length
            }
        },

        "name": {
            "name": "Nome",
            "function": (a, b) => {
                return b.professionalInfo.nome - a.professionalInfo.nome
            }
        }
    };

    const availableProfessionals = [].concat(props.schedule)
        .sort((a, b) => sortFunctions[sortBy].function(a, b))
        .filter((professional) => {
            if (props.gender === "" || props.gender === professional.professionalInfo.sexo) {
                return true;
            }
        });

    if (availableProfessionals.length > 0) {
        return (
            <div style={{marginTop: 50}}>
                <Row style={{borderBottom: "1px dashed #d9d9db"}}>
                    <Col md={8}>
                        <p style={{
                            float: "left",
                            marginBottom: 5,
                            fontFamily: "Montserrat",
                            fontSize: "24px",
                            color: "#2c404a",
                            fontWeight: "500",
                            letterSpacing: "-1.32px"
                        }}>{formatDate(props.date, "weekDay")}</p>
                    </Col>
                    <Col md={4}>
                        <Dropdown className="sort-dropdown" open={dropdownOpen}
                                  toggle={() => setDropdownOpen(!dropdownOpen)}>
                            <DropdownMenu small right>
                                {Object.keys(sortFunctions).map((el, i) => (
                                    <DropdownItem key={i}
                                                  onClick={() => setSortBy(el)}>{sortFunctions[el].name}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                        <p style={{
                            cursor: "pointer",
                            float: "right",
                            fontFamily: "Open Sans",
                            color: "#9eadb5",
                            fontWeight: "600",
                            fontSize: 14,
                            marginBottom: 0
                        }}>
                            Ordernar por:
                            <span onClick={() => setDropdownOpen(!dropdownOpen)}
                                  style={{color: layoutStore.primaryColor, marginLeft: 5}}>
                            {sortFunctions[sortBy].name}
                                <FontAwesomeIcon style={{marginLeft: 4}} color={layoutStore.primaryColor}
                                                 icon={faCaretDown}/>
                        </span>
                        </p>
                    </Col>
                </Row>
                <Row>
                    {
                        availableProfessionals.map((professional, index) => (
                            <ProfessionalCard key={index} date={props.date} professional={professional}/>
                        ))
                    }
                </Row>
            </div>
        )
    } else {
        return null;
    }
};

export default ScheduleDate;