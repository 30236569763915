import React from 'react';
import {
    faStethoscope,
    faMapMarkerAlt,
    faClock,
    faCalendarAlt,
    faUser,
    faCalendarCheck
} from "@fortawesome/free-solid-svg-icons";
import {action, computed, thunk} from 'easy-peasy';
import Especialidade from "../../steps/Especialidade/Especialidade";
import CentroMedico from "../../steps/CentroMedico/CentroMedico";
import HorariosProfissionais from "../../steps/HorariosProfissionais/HorariosProfissionais";
import Paciente from "../../steps/Paciente/Paciente";
import Confirmar from "../../steps/Confirmar/Confirmar";
import Confirmacao from "../../steps/Confirmacao/Confirmacao";
import moment from "moment";
import {getAvailableSteps} from "../../services/layoutService";
import AuthenticatedComponent from "../../layout/Content/AuthenticatedComponent";

const stepsModel = {
    currentStep: "confirmar",
    steps: {
        "especialidade": {
            "icon": faStethoscope,
            "path": "/agendamento/:slug/especialidade",
            "component": (getStepInitialData) => <Especialidade getStepInitialData={getStepInitialData}/>,
        },
        "centro-medico": {
            "icon": faMapMarkerAlt,
            "path": "/agendamento/:slug/centro-medico",
            "component": (getStepInitialData) => <CentroMedico getStepInitialData={getStepInitialData}/>
        },
        "horario-profissional": {
            "icon": faClock,
            "path": "/agendamento/:slug/horario-profissional",
            "component": (getStepInitialData) => <HorariosProfissionais getStepInitialData={getStepInitialData}/>
        },
        "data": {
            "icon": faCalendarAlt,
            "component": ""
        },
        "paciente": {
            "icon": faUser,
            "path": "/agendamento/:slug/paciente",
            "component": (getStepInitialData) => <AuthenticatedComponent
                component={<Paciente getStepInitialData={getStepInitialData}/>}/>
        },
        "confirmar": {
            "icon": faCalendarCheck,
            "path": "/agendamento/:slug/confirmar",
            "component": (getStepInitialData) => <AuthenticatedComponent
                component={<Confirmar getStepInitialData={getStepInitialData}/>}/>
        },
        "confirmacao": {
            "icon": false,
            "path": "/agendamento/:slug/confirmacao",
            "component": (getStepInitialData) => <AuthenticatedComponent
                component={<Confirmacao getStepInitialData={getStepInitialData}/>}/>
        }
    },
    availableSteps: {
        "especialidade": {
            "name": "Especialidade",
            "needsAuth": false,
            "selectedValue": false,
            "canSelectDate": false,
            "data": {},
        },
        "data": {
            "name": "Data",
            "needAuth": false,
            "selectedValue": false,
            "isDate": true,
            "data": {}
        },
        "centro-medico": {
            "name": "Centro Médico",
            "needsAuth": false,
            "selectedValue": false,
            "canSelectDate": true,
            "data": {}
        },
        "horario-profissional": {
            "name": "Horários / Profissionais",
            "needsAuth": false,
            "selectedValue": false,
            "canSelectDate": true,
            "data": {}
        },
        "paciente": {
            "name": "Paciente",
            "needsAuth": true,
            "selectedValue": false,
            "canSelectDate": false,
            "data": {}
        },
        "confirmar": {
            "name": "Confirmar",
            "needsAuth": true,
            "selectedValue": false,
            "canSelectDate": false,
            "data": {}
        },
    },

    setValue: action((state, payload) => {
        let payloadKeys = Object.keys(payload);

        payloadKeys.forEach((key) => {
            if (state.hasOwnProperty(key)) {
                state[key] = payload[key];
            }
        })
    }),

    getStepData: computed(state => (step, key) => {
        if (!state.availableSteps.hasOwnProperty(step)) return false;
        if (!state.availableSteps[step].data.hasOwnProperty(key)) return false;
        return state.availableSteps[step].data[key];
    }),

    getStepsSelectedValues: computed(state => {
        let availableSteps = state.availableSteps;
        let result = {};

        if (availableSteps) {
            Object.keys(availableSteps).forEach((step) => {
                result[step] = state.availableSteps[step].selectedValue;
            });
        }

        return result;
    }),

    getParams: computed(state => {
        return {
            appointTypeId: state.getStepData("especialidade", "appointTypeId"),
            isTelemedicine: state.getStepData("especialidade", "isTelemedicine"),
            returnId: state.getStepData("especialidade", "returnId"),
            selectedProcedures: state.getStepData("especialidade", "selectedProcedures"),
            limitIds: state.getStepData("especialidade", "limitIds"),
            blockDefaultParams: state.getStepData("especialidade", "blockDefaultParams"),
            dateStart: moment(state.getStepData("data", "dateStart")).format("YYYY-MM-DD"),
            dateEnd: moment(state.getStepData("data", "dateStart")).add(1, 'M').format("YYYY-MM-DD"),
            limitDate: state.getStepData("data", "limitDate"),
            unityId: state.getStepData("centro-medico", "unitId"),
            convenioId: state.getStepData("centro-medico", "convenioId"),
            planoId: state.getStepData("centro-medico", "planoId"),
            uf: state.getStepData("centro-medico", "uf"),
            cidade: state.getStepData("centro-medico", "cidade"),
            latitude: state.getStepData("centro-medico", "latitude"),
            longitude: state.getStepData("centro-medico", "longitude"),
            range: state.getStepData("centro-medico", "range"),
            professionalId: state.getStepData("horario-profissional", "professionalId"),
            hour: state.getStepData("horario-profissional", "hour"),
            gradeId: state.getStepData("horario-profissional", "gradeId"),
            localId: state.getStepData("horario-profissional", "localId"),
            patientId: state.getStepData("paciente", "patientId"),
            patientFeegowId: state.getStepData("confirmar", "patientFeegowId"),
            licenseId: state.getStepData("confirmar", "licenseId"),
            appointId: state.getStepData("confirmar", "appointId"),
            tabelaId: state.getStepData("confirmar", "tabelaId"),
            campaign: state.getStepData("confirmar", "campaign"),
            selectedValues: state.getStepsSelectedValues,
        }
    }),

    setStepData: action((state, payload) => {
        Object.keys(payload).forEach((step) => {
            Object.keys(payload[step]).forEach((property) => {
                state.availableSteps[step].data[property] = payload[step][property];
            });
        })
    }),

    setSelectedValue: action((state, payload) => {
        Object.keys(payload).forEach((step) => {
            state.availableSteps[step].selectedValue = payload[step];
        });
    }),

    setStep: action((state, step) => {
        state.currentStep = step;
    }),

    setCurrentStep: thunk((actions, step, {getState, getStoreState}) => {
        actions.setStep(step);
    }),

    toNextStep: thunk((actions, payload, {getState, getStoreActions, getStoreState}) => {
        const state = getState();

        let availableSteps = state.availableSteps;
        let nextStep = Object.keys(availableSteps)[state.currentStepIndex + 1];

        //checa se próximo step é data, se for, pula
        if (nextStep === "data") nextStep = Object.keys(availableSteps)[state.currentStepIndex + 2];

        //checa se próximo step existe
        if (typeof availableSteps[nextStep] === "undefined") return false;

        actions.setCurrentStep(nextStep);
        return nextStep;
    }),

    currentStepIndex: computed(state => Object.keys(state.availableSteps).indexOf(state.currentStep)),

    canSelectStep: computed(state => step => {
        if (step.key === "data") return false;
        if (step.key === "centro-medico" && state.getParams.isTelemedicine) return false;
        if (step.key === "especialidade" && state.getParams.blockDefaultParams && state.getParams.blockDefaultParams.length > 0 && state.getParams.blockDefaultParams.includes("especialidade")) return false;

        if (step.index < state.currentStepIndex || state.availableSteps[step.key].selectedValue) return true;
    }),

    getAvailableSteps: thunk(async (actions, payload, {getStoreState, getState}) => {
        try {
            const availableSteps = await getAvailableSteps(getStoreState().layout.slug);
            actions.setValue({availableSteps: availableSteps});
        } catch (e) {
            throw e;
        }
    }),
};

export default stepsModel;
