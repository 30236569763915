import React from 'react';
import {useStoreState} from "easy-peasy";

const AppointTypeOption = (props) => {
    const layoutStore = useStoreState(s => s.layout);

    if (props.enabled) {
        return (
            <div onClick={() => props.onSetAppointType(props.id)} style={{
                width: 150,
                height: 120,
                margin: 10,
                borderRadius: 11,
                boxShadow: "0 3px 7px 0 rgba(0, 0, 0, 0.08)",
                backgroundColor: props.isSelected ? layoutStore.primaryColor : "white",
                cursor: props.isSelected ? "default" : "pointer",
                flexShrink: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                WebkitTransition: "all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1)",
                transition: " all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1)"
            }}>
                {props.icon(props.isSelected ? "white" : layoutStore.primaryColor)}
                <p style={{
                    color: props.isSelected ? "white" : "#9baab2",
                    marginBottom: 0,
                    marginTop: 15,
                    fontSize: 16
                }}>{props.name}</p>
            </div>
        )
    } else {
        return (
            <div style={{
                width: 150,
                height: 120,
                margin: 10,
                borderRadius: 11,
                backgroundColor: "rgba(0,0,0,0.03)",
                flexShrink: 0,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                WebkitTransition: "all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1)",
                transition: " all 0.1s cubic-bezier(0.165, 0.84, 0.44, 1)"
            }}>
                {props.icon("rgba(0,0,0,0.1)")}
                <p style={{
                    color: "rgba(0,0,0,0.18)",
                    marginBottom: 0,
                    marginTop: 15,
                    fontSize: 16
                }}>{props.name}</p>
            </div>
        )
    }
};

export default AppointTypeOption;