import {action, thunk, computed} from 'easy-peasy';
import {authUser} from "../../services/userService";

const userModel = {
    currentUF: "RJ",
    userInfo: false,
    isLoggedIn: computed(state => state.userInfo ? true : false),

    setValue: action((state, payload) => {
        let payloadKeys = Object.keys(payload);

        payloadKeys.forEach((key) => {
            if (state.hasOwnProperty(key)) {
                state[key] = payload[key];
            }
        })
    }),

    deleteUserInfo: action((state, payload) => {
        state.userInfo = false;
    }),

    logout: thunk((actions, payload, {getStoreState}) => {
        const slug = getStoreState().layout.slug;

        localStorage.removeItem("userInfo-" + slug);
        localStorage.removeItem("token");
        actions.deleteUserInfo();
    }),

    signIn: thunk(async (actions, payload, {getStoreState, getState}) => {
        const slug = getStoreState().layout.slug;
        try {
            const signInInfo = await authUser(getStoreState().layout.slug, payload.userId, payload.email, payload.photo, payload.displayName, payload.token);

            localStorage.setItem("userInfo-" + slug, JSON.stringify(signInInfo));
            return signInInfo;
        } catch (e) {
            throw e;
        }
    }),
};


export default userModel;