import React, {useEffect, useState, useRef, useCallback} from 'react';
import {
    Col,
    Fade,
    FormInput,
    Slider,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Row,
    Badge,
    CardBody,
    Card,
    DropdownItem
} from "shards-react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es"
import {faDollarSign, faMapMarkerAlt, faSearch, faSpinner} from "@fortawesome/free-solid-svg-icons/index"
import {useStoreActions, useStoreState} from "easy-peasy";
import {getMunicipios} from "../../services/unityService";
import _ from 'lodash';
import {faMapMarker} from "@fortawesome/free-solid-svg-icons/faMapMarker"
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes"


const SelectMunicipio = () => {
    const stepsStatesLocation = useStoreState(s => s.steps);
    const inputRef = useRef();
    const stepsActions = useStoreActions(a => a.steps);
    const scheduleActions = useStoreActions(a => a.schedule);
    const [loading, setLoading] = useState(false);
    const [currentLocation, setCurrentLocation] = useState(false);
    const [municipios, setMunicipios] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [coords, setCoords] = useState(false);
    const [range, setRange] = useState(20);

    const cityUf = () => {
        let uf = stepsStatesLocation.getParams.uf;
        let cidade = stepsStatesLocation.getParams.cidade;

        if (!uf) return false;
        if (uf.length !== 2 || cidade.length < 2) return false;

        return cidade + '_' + uf;
    };


    const searchMunicipio = async () => {
        if (searchTerm.length < 2) {
            setMunicipios([]);
            return false;
        }

        setLoading(true);
        const municipios = await getMunicipios(searchTerm);
        setLoading(false);
        setMunicipios(municipios.slice(0, 10));
    };

    const getCurrentLocation = async () => {
        let cit = cityUf();

        if (cit === false) {
            if (!navigator.geolocation) return false;
            navigator.geolocation.getCurrentPosition((position) => {
                setCoords({latitude: position.coords.latitude, longitude: position.coords.longitude});
                setCurrentLocation("Utilizando sua localização atual");
            });
        }

        const municipios = await getMunicipios(cit);

        if (municipios.length < 2) {
            let muni = municipios[0];
            setCurrentPosition({latitude: muni.latitude, longitude: muni.longitude}, muni.municipio);
        }
    };

    const setCurrentPosition = (coords, label) => {
        setCoords({latitude: coords.latitude, longitude: coords.longitude});
        setSearchTerm(label);
        inputRef.current.blur();
    };

    const searchUnits = async () => {
        if (!coords || !range) return false;

        stepsActions.setStepData({
            "centro-medico": {
                latitude: coords.latitude,
                longitude: coords.longitude,
                range: range,
            }
        });

        scheduleActions.getAvailableSchedule();
    };

    const onMunicipioSelect = (municipio) => {
        setMunicipios([]);
        setCurrentPosition({latitude: municipio.latitude, longitude: municipio.longitude}, municipio.municipio);
        setCurrentLocation(municipio.municipio);
    };

    const onSlideChange = (e) => {
        setRange(parseFloat(e[0]));
    };

    useEffect(() => {
        if (stepsStatesLocation.getParams.cidade && stepsStatesLocation.getParams.uf) {
            getCurrentLocation()
        }
    }, [stepsStatesLocation.getParams.cidade, stepsStatesLocation.getParams.uf]);

    useEffect(() => {
        const timeout = setTimeout(async () => {
            await searchMunicipio();
        }, 1000);

        return () => clearTimeout(timeout);
    }, [searchTerm]);

    useEffect(() => {
        searchUnits();
    }, [coords, range])

    useEffect(() => {
        _.debounce(() => searchMunicipio(), 500)();
        inputRef.current.focus();
        getCurrentLocation();
    }, [])

    return (
        <div>
            <Row>
                <Col md={12}>
                    <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                        <div style={{display: "flex", width: 800}}>
                            <div style={{flex: 1.5}}>
                                {currentLocation ?
                                    <InputGroup onClick={() => {
                                        setCurrentLocation(false);
                                        setSearchTerm("")
                                    }} seamless>
                                        <InputGroupAddon type="prepend">
                                            <InputGroupText>
                                                <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <FormInput style={{cursor: 'pointer'}} readOnly={true} value={currentLocation}/>
                                        <InputGroupAddon type="append">
                                            <InputGroupText>
                                                <FontAwesomeIcon icon={faTimes}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    :
                                    <InputGroup seamless>
                                        <InputGroupAddon type="prepend">
                                            <InputGroupText>
                                                <FontAwesomeIcon icon={faSearch}/>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <FormInput value={searchTerm} innerRef={inputRef} autoComplete="off"
                                                   onChange={(e) => setSearchTerm(e.target.value)}
                                                   placeholder="Busque pelo município"
                                                   id="input-options"/>
                                    </InputGroup>
                                }
                            </div>
                        </div>
                    </div>
                </Col>
                {municipios.length > 0 && searchTerm.length > 2 && !currentLocation ?
                    <div style={{position: 'absolute', zIndex: 99, width: '95%', top: 45, left: 15}}>
                        <Card>
                            {municipios.map((municipio, i) => (
                                <DropdownItem key={i}
                                              onClick={() => onMunicipioSelect(municipio)}>{municipio.municipio} - {municipio.uf}</DropdownItem>
                            ))}
                        </Card>
                    </div> : null}
            </Row>
            <Row>
                <Col xs={10}>
                    <p style={{marginBottom: 0, fontWeight: '400', marginTop: 10}}>Distância:</p>
                    <Slider
                        step={5}
                        onChange={onSlideChange}
                        connect={[true, false]}
                        start={[range]}
                        range={{min: 5, max: 50}}/>
                </Col>
                <Col xs={2}>
                    <Badge style={{marginTop: 35}}>{range}Km</Badge>
                </Col>
            </Row>
        </div>
    )
};

export default SelectMunicipio;