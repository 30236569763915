import React, {useState, useEffect} from 'react';
import {faQuestion} from "@fortawesome/free-solid-svg-icons/faQuestion"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es"
import {Tooltip} from "shards-react"


const Hint = (props) => {
    const [open, setOpen] = useState(false);

    if (!props.enabled) {
        return null
    }

    const toggle = () => {
        setOpen(!open);
    }

    const pulseStyle = `
    .blob {
	background: lightgrey;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	margin: 10px;
	height: 20px;
	width: 20px;
	transform: scale(1);
	animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
`;

    return (
        <div id="hint" className="blob">
            <Tooltip
                open={open}
                toggle={toggle}
                target="#hint"
            >
                {props.hint}
            </Tooltip>
            <style>{pulseStyle}</style>
            <FontAwesomeIcon color="white" style={{position: "relative", left: 5, bottom: 5, width: 10, height: 10}} icon={faQuestion}/>
        </div>
    )
};

export default Hint;