import React, {useEffect, useState} from 'react';
import {Button, Col, Fade, FormInput, InputGroup, InputGroupAddon, InputGroupText, Row} from "shards-react";
import {useStoreActions, useStoreState} from "easy-peasy";
import PatientCard from "./PatientCard";
import AddPatient from "./AddPatient";
import PatientModal from "./PatientModal";
import ComponentOverlay from "../../layout/LoadingContent/ComponentOverlay";
import {withRouter} from "react-router-dom";
import {getStepUrl, isEmpty} from "../../utils/utils";

import './Paciente.css';
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes"
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons/index"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es"
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch"

const Paciente = (props) => {
    const patientStore = useStoreState(s => s.patient);
    const layoutStore = useStoreState(s => s.layout);
    const stepStore = useStoreState(s => s.steps);
    const userStore = useStoreState(s => s.user);
    const patientActions = useStoreActions(a => a.patient);
    const stepActions = useStoreActions(a => a.steps);
    const unitActions = useStoreActions(a => a.units);
    const procedureActions = useStoreActions(a => a.procedure);
    const scheduleActions = useStoreActions(a => a.schedule);
    const availablePatients = patientStore.availablePatients;

    const [patientModal, setPatientModal] = useState({
        type: "create", //create ou update
        showing: false,
        missingFields: false,
        error: false,
        cbFunction: false,
        patient: {},
    });


    const verifyConvenio = async (patient) => {
        if (stepStore.getParams.convenioId && stepStore.getParams.convenioId != patient.convenioId) {
            patient.convenioId = stepStore.getParams.convenioId;
            patient.planoId = false;
            patient.matricula = false;
        }
    };

    const onPatientSelect = async (patient) => {
        await verifyConvenio(patient);
        const missingFields = await patientActions.verifyPatientFields(patient);

        if (missingFields.length > 0) {
            setPatientModal({
                ...patientModal,
                type: "complete",
                showing: true,
                missingFields: missingFields,
                patient: patient,
                cbFunction: onPatientSelect,
            })
        } else {
            stepActions.setSelectedValue({
                "paciente": patient.name
            });

            stepActions.setStepData({
                "paciente": {
                    "patientId": patient.id,
                    "selectedPatient": patient,
                },
            });

            const nextStep = stepActions.toNextStep();
            props.history.push(getStepUrl(layoutStore.slug, nextStep, stepStore.getParams))
        }
    };

    const onLoadGetAvailablePatients = async () => {
        if (userStore.userInfo && userStore.userInfo.isAdmin) {
            return false;
        }

        return patientActions.getAvailablePatients();
    };


    const initialParams = {
        stepActions: patientActions,
        requiredQueryParams: [
            {param: "appointTypeId", name: "Tipo de agendamento"},
            {param: "selectedProcedures", name: "Procedimentos"},
            {param: "professionalId", name: "Profissional"},
            {param: "hour", name: "Hora do agendamento"},
            {param: "dateStart", name: "Data do agendamento"},
        ],
        initialDataFunctions: [
            procedureActions.getInitialValues,
            unitActions.getInitialValues,
            scheduleActions.getInitialValues,
            onLoadGetAvailablePatients,
            patientActions.getRequiredFields,
            patientActions.getRelativeTypes,
            patientActions.getOrigens,
            scheduleActions.getConvenios
        ]
    };

    useEffect(() => {
        stepActions.setCurrentStep("paciente");
        props.getStepInitialData(initialParams);
    }, []);


    const RenderPatients = () => {
        const limitIds = stepStore.getParams.limitIds && stepStore.getParams.limitIds["paciente"];
        const patients = Object.keys(availablePatients).filter((patientId) => !limitIds || (limitIds && limitIds.includes(parseFloat(patientId))));

        return (
            <Fade>
                <PatientModal setPatientModal={setPatientModal} patientModal={patientModal}/>
                <Row>
                    {
                        patients.map((patientId) => (
                            <PatientCard patientModal={patientModal} setPatientModal={setPatientModal}
                                         verifyConvenio={verifyConvenio}
                                         onPatientSelect={() => onPatientSelect(availablePatients[patientId])}
                                         key={patientId} patient={availablePatients[patientId]}/>
                        ))
                    }
                    {!limitIds ? <AddPatient patientModal={patientModal} setPatientModal={setPatientModal}/> : null}
                </Row>
            </Fade>
        )
    };

    return (
        <Fade>
            <Row>
                <p style={{
                    fontFamily: "Montserrat",
                    fontSize: "32px",
                    color: "#2c404a",
                    fontWeight: "500",
                    letterSpacing: "-2.75px"
                }}>Selecione o paciente da consulta</p>
            </Row>
            {userStore.userInfo && userStore.userInfo.isAdmin ?
                <Row style={{marginBottom: 20}}>
                    <Col md={6}>
                        <InputGroup seamless>
                            <InputGroupAddon type="prepend">
                                <InputGroupText>
                                    <FontAwesomeIcon icon={faSearch}/>
                                </InputGroupText>
                            </InputGroupAddon>
                            <FormInput value={patientStore.searchTerm}
                                       onChange={(e) => patientActions.setValue({"searchTerm": e.target.value})}
                                       placeholder="Busque pelo nome, email, CPF ou celular do paciente"/>
                            <InputGroupAddon type="append">
                                <Button onClick={patientActions.getAvailablePatients} theme="primary">Buscar
                                    paciente</Button>
                            </InputGroupAddon>
                        </InputGroup>
                    </Col>
                </Row>
                : null}
            <ComponentOverlay error={patientStore.error} loading={patientStore.loading}>
                {RenderPatients()}
            </ComponentOverlay>
        </Fade>
    )

};

export default withRouter(Paciente);
