import React from 'react';
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ProposalItem = (props) => {
    const formatName = (name) => {
        if (name.length > 10) return name.substr(0, 10) + "...";
        return name;
    };

    return (
        <div
            onClick={() => props.removeProcedure()}
            style={{
                display: "inline-block",
                flexDirection: "row",
                backgroundColor: "white",
                borderRadius: 8,
                cursor: "pointer",
                margin: 5,
                padding: 5
            }}>
            <p style={{fontSize: 12, marginBottom: 0}} title={props.name}>{formatName(props.name)} <FontAwesomeIcon
                icon={faTimes} style={{marginLeft: 5}}/></p>
        </div>
    )
};

export default ProposalItem;