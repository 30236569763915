import React, {useState} from 'react';
import {Button, Col, Form, FormGroup, FormInput, FormSelect, Modal, ModalBody, ModalHeader, Row} from "shards-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {useStoreActions, useStoreState} from "easy-peasy";
import MaskedInput from 'react-text-mask';
import Loader from 'react-loader-spinner';
import cep from 'cep-promise';
import OptionalFieldLabel from "./OptionalFieldLabel";
import PlanoOptions from "../CentroMedico/PlanoOptions"
import ConvenioOptions from "../CentroMedico/ConvenioOptions"


const PatientModal = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const patientStore = useStoreState(s => s.patient);
    const patientActions = useStoreActions(a => a.patient);
    const layoutStore = useStoreState(s => s.layout);
    const stepsState = useStoreState(s => s.steps);

    const saveInfo = async () => {
        setLoading(true);

        try {
            switch (props.patientModal.type) {
                case "create":
                    await patientActions.addPatient(props.patientModal.patient);
                    break;
                case "update":
                    await patientActions.editPatient(props.patientModal.patient);
                    break;
                case "complete":
                    await patientActions.editPatient(props.patientModal.patient);
            }

            await patientActions.getAvailablePatients();
            props.setPatientModal({...props.patientModal, showing: false});

            if (props.patientModal.cbFunction) props.patientModal.cbFunction(props.patientModal.patient);
        } catch (e) {
            setError(e.message);
        }

        setLoading(false);
    };

    const handleInputChange = (key, value) => {
        if (value === "false") value = false;
        let newPatient = props.patientModal.patient;
        newPatient[key] = value;

        props.setPatientModal({
            ...props.patientModal, patient: newPatient
        });
    };

    const onKeyDown = async (e) => {
        if (e.key === "Enter") {
            saveInfo();
        }
    };


    const handleCepChange = async (cepNumber) => {
        if (cepNumber.replace(/[^\d]/g, "").length !== 8) {
            handleInputChange('cep', cepNumber);
        }

        try {
            const info = await cep(cepNumber);

            handleInputChange('cep', info.cep);
            handleInputChange('estado', info.state);
            handleInputChange('cidade', info.city);
            handleInputChange('rua', info.street);
            handleInputChange('bairro', info.neighborhood);
        } catch (e) {
        }
    };

    const changeConvenio = (convenioId) => {
        if (convenioId === "false")
            convenioId = false;

        if (!convenioId) {
            handleInputChange("planoId", false);
            handleInputChange("matricula", false);
        }

        handleInputChange("convenioId", convenioId);
    };

    const formatBirthdate = (birthdate) => {
        if (!birthdate)
            return null;

        return birthdate.split("-").reverse().join("/");
    };

    const convenioIsDisabled = stepsState.getParams.blockDefaultParams && stepsState.getParams.blockDefaultParams.length > 0 && stepsState.getParams.blockDefaultParams.includes("convenio") && stepsState.getParams.convenioId;

    const fields = [
        {
            "fieldName": "name",
            "fieldLabel": "Nome completo",
            "inputType": "text",
            "value": props.patientModal.patient.name,
            "placeholder": "Insira o nome completo do paciente",
            "customInput": false,
        },
        {
            "fieldName": "birthdate",
            "fieldLabel": "Data de nascimento",
            "value": props.patientModal.patient.birthdate,
            "placeholder": null,
            "customInput": (<FormGroup>
                <label htmlFor="#birthdate">Data de nascimento</label>
                <MaskedInput
                    onKeyPress={onKeyDown}
                    className="form-control"
                    onChange={(e) => handleInputChange("birthdate", e.target.value)}
                    value={formatBirthdate(props.patientModal.patient.birthdate)} id="birthdate"
                    placeholder="Data de nascimento do paciente"
                    mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                />
                <OptionalFieldLabel field={"birthdate"}/>
            </FormGroup>),
        },
        {
            "fieldName": "email",
            "fieldLabel": "Email",
            "value": props.patientModal.patient.email,
            "placeholder": "Insira o email do paciente",
            "customInput": false,
        },
        {
            "fieldName": "cpf",
            "fieldLabel": "CPF",
            "value": props.patientModal.patient.cpf,
            "placeholder": "Insira o cpf do paciente",
            "customInput": (<FormGroup>
                <label htmlFor="#cpf">CPF</label>
                <MaskedInput
                    onKeyPress={onKeyDown}
                    className="form-control"
                    onChange={(e) => handleInputChange("cpf", e.target.value)}
                    value={props.patientModal.patient.cpf} id="cpf"
                    placeholder="Insira o CPF do paciente"
                    mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/,]}
                />
                <OptionalFieldLabel field={"cpf"}/>
            </FormGroup>),
        },
        {
            "fieldName": "cel",
            "fieldLabel": "Celular",
            "value": props.patientModal.patient.cel,
            "placeholder": null,
            "customInput": (<FormGroup>
                <label htmlFor="#cel">Celular</label>
                <MaskedInput
                    onKeyPress={onKeyDown}
                    className="form-control"
                    onChange={(e) => handleInputChange("cel", e.target.value)}
                    value={props.patientModal.patient.cel} id="cpf"
                    placeholder="Insira o celular do paciente"
                    mask={['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                />
                <OptionalFieldLabel field={"cel"}/>
            </FormGroup>),
        },
        {
            "fieldName": "sexo",
            "fieldLabel": "Sexo",
            "value": props.patientModal.patient.sexo,
            "placeholder": "Sexo do paciente",
            "customInput": (<FormGroup>
                <label htmlFor="#sexo">Sexo do paciente</label>
                <FormSelect
                    onKeyPress={onKeyDown}
                    onChange={(e) => handleInputChange("sexo", e.target.value)}
                    value={props.patientModal.patient.sexo} id="sexo">
                    <option value={false}>Selecione o sexo do paciente</option>
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                </FormSelect>
                <OptionalFieldLabel field={"sexo"}/>
            </FormGroup>),
        },
        {
            "fieldName": "relativeTypeId",
            "fieldLabel": "Tipo de parentesco",
            "value": props.patientModal.patient.relativeTypeId,
            "placeholder": "Selecione um tipo de parentesco",
            "customInput": (<FormGroup>
                <label htmlFor="#relativeType">Tipo de parentesco</label>
                <FormSelect
                    onKeyPress={onKeyDown}
                    disabled={props.patientModal.patient.isUser}
                    onChange={(e) => handleInputChange("relativeTypeId", e.target.value)}
                    value={props.patientModal.patient.relativeTypeId} id="relativeType">
                    <option value="">Selecione um tipo de parentesco</option>
                    {patientStore.relativeTypes.map((relativeType) => {
                        return (
                            <option key={relativeType.id}
                                    value={relativeType.id}>{relativeType.tipoParente}</option>
                        )
                    })}
                </FormSelect>
                <OptionalFieldLabel field={"relativeTypeId"}/>
            </FormGroup>),
        },
        {
            "fieldName": "origemId",
            "fieldLabel": "Origem",
            "value": props.patientModal.patient.origemId,
            "placeholder": "Selecione sua origem",
            "customInput": (<FormGroup>
                <label htmlFor="#origem">Origem do paciente</label>
                <FormSelect
                    onKeyPress={onKeyDown}
                    onChange={(e) => handleInputChange("origemId", e.target.value)}
                    value={props.patientModal.patient.origemId} id="origem">
                    <option value="">Selecione a origem do paciente</option>
                    {patientStore.origens.map((origem) => {
                        return (
                            <option key={origem.id}
                                    value={origem.id}>{origem.origem}</option>
                        )
                    })}
                </FormSelect>
                <OptionalFieldLabel field={"origemId"}/>
            </FormGroup>),
        },
        {
            "fieldName": "convenioId",
            "fieldLabel": "Convênio",
            "value": props.patientModal.patient.convenioId,
            "placeholder": "Selecione seu convênio",
            "customInput": (<FormGroup>
                <label htmlFor="#convenioId">Convênio</label>
                <FormSelect
                    disabled={convenioIsDisabled}
                    onKeyPress={onKeyDown}
                    onChange={(e) => changeConvenio(e.target.value)}
                    value={props.patientModal.patient.convenioId} id="convenioId">
                    <option value={false}>Selecione o convênio do paciente</option>
                    <ConvenioOptions/>
                </FormSelect>
                <OptionalFieldLabel field={"convenioId"}/>
            </FormGroup>),
        },
        {
            "fieldName": "planoId",
            "fieldLabel": "Plano",
            "value": props.patientModal.patient.planoId,
            "placeholder": "Selecione seu plano",
            "customInput": (<FormGroup>
                <label htmlFor="#planoId">Plano</label>
                <FormSelect
                    onKeyPress={onKeyDown}
                    onChange={(e) => handleInputChange("planoId", e.target.value)}
                    value={props.patientModal.patient.planoId} id="planoId">
                    <option value={false}>Selecione o plano do paciente</option>
                    <PlanoOptions convenioId={props.patientModal.patient.convenioId}/>
                </FormSelect>
                <OptionalFieldLabel field={"planoId"}/>
            </FormGroup>),
        },
        {
            "fieldName": "matricula",
            "fieldLabel": "Matrícula",
            "value": props.patientModal.patient.matricula || "",
            "placeholder": "Insira a matrícula no convênio",
            "customInput": false,
        },
        {
            "fieldName": "cep",
            "fieldLabel": "CEP",
            "inputType": "text",
            "value": props.patientModal.patient.cep,
            "placeholder": "Insira seu CEP",
            "customInput": (
                <FormGroup>
                    <label htmlFor="#cep">CEP</label>
                    <FormInput onKeyPress={onKeyDown} max="2100-01-01" min="1800-01-01"
                               onChange={(e) => handleCepChange(e.target.value)}
                               value={props.patientModal.patient.cep} type="text" id="cep"/>
                    <OptionalFieldLabel field={"cep"}/>
                </FormGroup>
            ),
        },
        {
            "fieldName": "estado",
            "fieldLabel": "Estado",
            "inputType": "text",
            "value": props.patientModal.patient.estado,
            "placeholder": "Insira seu estado (RJ, SP...)",
            "customInput": false,
        },
        {
            "fieldName": "cidade",
            "fieldLabel": "Cidade",
            "inputType": "text",
            "value": props.patientModal.patient.cidade,
            "placeholder": "Insira sua cidade",
            "customInput": false,
        },
        {
            "fieldName": "bairro",
            "fieldLabel": "Bairro",
            "inputType": "text",
            "value": props.patientModal.patient.bairro,
            "placeholder": "Insira seu Bairro",
            "customInput": false,
        },
        {
            "fieldName": "rua",
            "fieldLabel": "Logradouro",
            "inputType": "text",
            "value": props.patientModal.patient.rua,
            "placeholder": "Insira seu endereço",
            "customInput": false,
        },
        {
            "fieldName": "numero",
            "fieldLabel": "Número",
            "inputType": "text",
            "value": props.patientModal.patient.numero,
            "placeholder": "Insira o número do logradouro",
            "customInput": false,
        },
        {
            "fieldName": "complemento",
            "fieldLabel": "Complemento",
            "inputType": "text",
            "value": props.patientModal.patient.complemento,
            "placeholder": "Insira um complemento",
            "customInput": false,
        },
    ];

    const renderMissingFields = () => {
        return fields.map((field, i) => {
            if (props.patientModal.missingFields.includes(field.fieldName)) {

                if (field.customInput) {
                    return field.customInput;
                }

                return (
                    <FormGroup key={i}>
                        <label htmlFor={`#${field.fieldName}`}>{field.fieldLabel}</label>
                        <FormInput onKeyPress={onKeyDown}
                                   onChange={(e) => handleInputChange(field.fieldName, e.target.value)}
                                   value={field.value} id={field.fieldName}
                                   placeholder={field.placeholder}/>
                    </FormGroup>
                )
            }
        })
    };

    const renderInputs = () => {
        if (props.patientModal.type === "complete") {
            return renderMissingFields();
        }

        return fields.map((field, i) => {
            if (field.customInput) {
                return field.customInput;
            }

            return (
                <FormGroup key={i}>
                    <label htmlFor={`#${field.fieldName}`}>{field.fieldLabel}</label>
                    <FormInput onKeyPress={onKeyDown}
                               onChange={(e) => handleInputChange(field.fieldName, e.target.value)}
                               value={field.value} id={field.fieldName}
                               placeholder={field.placeholder}/>
                    <OptionalFieldLabel field={field.fieldName}/>
                </FormGroup>
            )
        })
    };

    const getModalHeader = () => {
        switch (props.patientModal.type) {
            case "create":
                return "Criar um novo paciente";
            case "update":
                return "Editar informações do paciente";
            case "complete":
                return "Informe os seguintes dados antes de continuar";
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <Modal modalContentClassName={"modal-body"}
               toggle={() => props.setPatientModal({...props.patientModal, showing: !props.patientModal.showing})}
               open={props.patientModal.showing}>
            <ModalHeader><h6 style={{marginBottom: 0}}>{getModalHeader()}</h6></ModalHeader>
            <ModalBody style={{overflowY: "auto", maxHeight: 600}}>
                <Form onSubmit={handleSubmit}>
                    {renderInputs()}
                </Form>
                {error ?
                    <Row>
                        <Col>
                            <p style={{color: "red"}}>{error}</p>
                        </Col>
                    </Row>
                    : null}
                <Row>
                    <Col>
                        {loading ? <Loader
                            style={{float: "right"}}
                            type="ThreeDots"
                            color={layoutStore.primaryColor}
                            height={30}
                            width={30}
                        /> : <Button onClick={() => saveInfo()} style={{float: "right"}}>
                            <FontAwesomeIcon icon={faSave}/> Salvar
                        </Button>}
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
};

export default PatientModal;
