import React, {useEffect, useState} from 'react';
import {useStoreState} from "easy-peasy";
import {FormCheckbox} from "shards-react";
import {getTermoUrl} from "../../services/confirmationService";

const AceiteAgendamento = (props) => {
    const [checked, setChecked] = useState(false);
    const [termoUrl, setTermoUrl] = useState(false);
    const layoutStore = useStoreState(s => s.layout);

    const handleChange = () => {
        if (checked) {
            setChecked(false);
            props.disableConfirmButton(true)
            return false;
        }

        setChecked(true);
        props.disableConfirmButton(false)
    }

    const getTermo = async () => {
        const url = await getTermoUrl(layoutStore.slug);

        if (url) {
            setTermoUrl(url);
        }
    }

    const openTermo = (e) => {
        e.stopPropagation(); //ignora o onclick do pai
        if(!termoUrl) return false;
        window.open(termoUrl,'_blank');
    }

    useEffect(() => {
        getTermo();
        if (!checked) {
            props.disableConfirmButton(true)
        }
    }, []);


    return (
        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
            <FormCheckbox
                style={{margin: 0}}
                checked={checked}
                onChange={handleChange}/>
            <p onClick={handleChange} style={{marginBottom: 0, marginTop: -5, fontSize: 12, cursor: 'pointer'}}>Estou de acordo com os <span onClick={openTermo} style={{fontWeight: "bold", cursor: "pointer"}}>termos de serviço</span></p>
        </div>
    )
};

export default AceiteAgendamento;
