import React from 'react';
import {Button} from "shards-react";

const MostrarLocalizacao = (props) => {

    const showLocation = () => {
        let encodedAddress = encodeURI(props.address);
        let url = "https://www.google.com.br/maps/search/" + encodedAddress;
        window.open(url, '_blank');
    };

    return (
        <Button onClick={() => showLocation()} style={{float: "right"}} size="sm" outline>Ver localização</Button>
    )
};

export default MostrarLocalizacao;