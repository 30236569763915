import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, FormSelect} from "shards-react";
import {useStoreActions, useStoreState} from "easy-peasy";
import {faUserMd} from "@fortawesome/free-solid-svg-icons";
import {fakeTimout, getStepUrl} from "../../utils/utils";
import {withRouter} from "react-router-dom";

const SelectTelemedicine = (props) => {
    const layoutStore = useStoreState(s => s.layout);
    const stepsState = useStoreState(s => s.steps);
    const stepsActions = useStoreActions(a => a.steps);
    const scheduleActions = useStoreActions(a => a.schedule);
    const unitsActions = useStoreActions(a => a.units);
    const [hasTelemedicine, setHasTelemedicine] = useState(false);

    const changeTelemedicine = async (value) => {
        stepsActions.setStepData({
            "especialidade": {
                isTelemedicine: value === 'true'
            }
        });

        if (value === 'true' && stepsState.currentStep === "centro-medico") {
            unitsActions.setUnitInfo({unityName: "Consultório Virtual", unityId: layoutStore.matrizId});
            props.history.push(getStepUrl(layoutStore.slug, "horario-profissional", stepsState.getParams))
            return false;
        }

        if (value !== 'true' && stepsState.currentStep === "horario-profissional") {
            unitsActions.setUnitInfo({unityName: false, unityId: false});
            props.history.push(getStepUrl(layoutStore.slug, "centro-medico", stepsState.getParams))
            return false;
        }

        await fakeTimout(300);
        scheduleActions.getAvailableSchedule();
    };

    useEffect(() => {
        if (typeof stepsState.getParams.selectedProcedures[0] !== "undefined" && stepsState.getParams.selectedProcedures[0].telemedicineId) {
            setHasTelemedicine(true);
        }
    }, [stepsState.getParams.selectedProcedures]);

    if (!layoutStore.getConfig('alternarPresencialOnline')) {
        return null;
    }

    if (!hasTelemedicine) {
        return null;
    }

    return (
        <Col md="4" style={{maxWidth: 230}}>
            <FontAwesomeIcon style={{position: "absolute", top: 13, left: 25}}
                             color={layoutStore.primaryColor}
                             icon={faUserMd}/>
            <FormSelect value={stepsState.getParams.isTelemedicine} onChange={(e) => changeTelemedicine(e.target.value)}
                        style={{paddingLeft: 33}} className="custom-select-feegow">
                <option value={true}>Consulta Online</option>
                <option value={false}>Consulta Presencial</option>
            </FormSelect>
        </Col>
    );
};

export default withRouter(SelectTelemedicine);
