import {faUser, faSignInAlt, faEllipsisH, faSignOutAlt} from "@fortawesome/free-solid-svg-icons/index";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from 'react';
import {
    Card,
    Fade, DropdownItem, DropdownMenu, Dropdown,
} from "shards-react";
import {useStoreState, useStoreActions} from "easy-peasy";

import '../NavTop.css'
import LoginModal from "../../Modal/LoginModal";

const UserAvatar = (props) => {
    if (props.user.userInfo.photoURL) {
        return (
            <img src={props.user.userInfo.photoURL} className="rounded-circle nav-top-current-user-avatar"
                 alt="user-avatar"/>
        );
    } else {
        return (
            <FontAwesomeIcon icon={faUser}/>
        )
    }
};

const IsLoggedIn = (props) => {
    const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
    const logout = useStoreActions(actions => actions.user.logout);

    const renderEmail = () => {
        const email = props.user.userInfo.email;

        if (email.length > 10) {
            return email.substr(0, 10) + "...";
        }

        return email;
    };

    return (
        <Fade>
            <Card onClick={() => setDropdownIsOpen(!dropdownIsOpen)} className="nav-top-user-card">
                <span>
                    <UserAvatar user={props.user}/>
                    <span className="nav-top-user-email">{renderEmail()}</span>
                    <FontAwesomeIcon className="nav-top-user-options-icon" icon={faEllipsisH}/>
                    <Dropdown style={{top: 50}} toggle={() => setDropdownIsOpen(!dropdownIsOpen)} open={dropdownIsOpen}>
                        <DropdownMenu>
                            <DropdownItem onClick={logout} style={{fontSize: 14}}>Sair <FontAwesomeIcon
                                style={{opacity: 0.50}} icon={faSignOutAlt}/></DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </span>
            </Card>
        </Fade>
    )
};

const NotLoggedIn = (props) => {
    const layoutActions = useStoreActions(a => a.layout);

    return (
        <Card onClick={() => layoutActions.setModalContent(<LoginModal/>)} className="nav-top-user-card">
                <span>
                    <span style={{color: props.layout.primaryColor}}>Entre</span> ou <span
                    style={{color: props.layout.primaryColor}}>Cadastre-se</span>
                <FontAwesomeIcon className="nav-top-sign-in" icon={faSignInAlt}/>
                </span>
        </Card>
    )
};

const CurrentUser = () => {
    const user = useStoreState(state => state.user);
    const layout = useStoreState(state => state.layout);

    if (layout.loading || layout.error) {
        return null;
    }

    return user.isLoggedIn && user.userInfo ? <IsLoggedIn user={user} layout={layout}/> : <NotLoggedIn user={user} layout={layout}/>;
};

export default CurrentUser;