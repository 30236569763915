import React from 'react';
import {Alert, Button} from "shards-react";
import Loader from "react-loader-spinner";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";


const SendEmailButton = (props) => {
    if (props.loading) {
        return (
            <Button>
                <Loader height={15} width={20} color="white" type="ThreeDots"/>
            </Button>
        )
    } else {
        if (props.emailSent) {
            return (
                <Alert theme="success">
                    Email enviado com sucesso!
                    <br/>
                    <a style={{fontSize: 12}} onClick={() => props.sendConfirmEmail()} className="alert-link" href="#">
                        Não recebeu? Clique aqui para enviar novamente.
                    </a>
                </Alert>
            )
        } else {
            return (
                <Button onClick={() => props.sendConfirmEmail()}>
                    Enviar código de verificação <FontAwesomeIcon icon={faPaperPlane}/>
                </Button>
            )
        }
    }
};

export default SendEmailButton;