import React from 'react';
import {useStoreState} from 'easy-peasy';


const ConvenioOptions = ({blockStateConvenio}) => {
    const scheduleState = useStoreState(s => s.schedule);

    if (Object.keys(scheduleState.convenios).length === 0) {
        return null;
    }

    return Object.keys(scheduleState.convenios)
        .map((convenioId) => scheduleState.convenios[convenioId])
        .sort((a, b) => {
            if (a.name < b.name) return -1;
            if (a.name > b.name) return 1;
            return 0;
        })
        .map((convenio) => {
            return <option key={convenio.id} value={convenio.id}>{convenio.name}</option>
        });
};

export default ConvenioOptions;