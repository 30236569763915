import React, {useState, useEffect} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, FormGroup, FormSelect, InputGroup, InputGroupAddon, InputGroupText} from "shards-react";
import {useStoreActions, useStoreState} from "easy-peasy";
import {faPercent, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {getTabelaEligibility, getTabelasParticulares} from "../../services/convenioService";
import {getConfirmationDetails} from "../../services/confirmationService";

const SelectTabelaParticular = (props) => {
    const [tabelas, setTabelas] = useState([]);
    const [loading, setLoading] = useState(false);
    const layoutStore = useStoreState(s => s.layout);
    const stepsStore = useStoreState(s => s.steps);
    const stepsActions = useStoreActions(a => a.steps);
    const confirmationActions = useStoreActions(a => a.confirmation);

    const getTabelas = async () => {
        try {
            const tabelas = await getTabelasParticulares(layoutStore.slug);
            setTabelas(tabelas);
            return tabelas;
        } catch (e) {
            console.log(e.message);
        }
    };

    const changeTabela = async (tabelaId) => {
        setLoading(true);

        props.hideConfirmButton(false);
        props.appointError(false);

        if (tabelaId === "false") tabelaId = false;
        stepsActions.setStepData({"confirmar": {tabelaId: tabelaId}});

        try {
            if (tabelaId) {
                const tableInfo = tabelas.find(tabela => tabela.id == tabelaId);
                if (tableInfo.tipoValidacao) {
                    props.hideConfirmButton(true);
                    const isEligible = await getTabelaEligibility(stepsStore.getParams.patientId, tableInfo.tipoValidacao);

                    if (!isEligible.EligibilityQuery.elegivel) {
                        throw new Error("Desconto não autorizado.");
                    }
                }
            }

            props.hideConfirmButton(false);
            await confirmationActions.getConfirmationDetails({ignoreLoading: true});
        } catch (e) {
            console.log(e);
            props.appointError(e.message);
        }


        setLoading(false);
    };

    useEffect(() => {
        getTabelas();
        stepsActions.setStepData({"confirmar": {tabelaId: false}});
    }, [])

    return (
        <Col>
            <p style={{fontWeight: "500", fontSize: 12, marginBottom: 10}}>{layoutStore.getConfig('labelTabelaParticular') || null}</p>
            <InputGroup seamless>
                <InputGroupAddon type="prepend">
                    <InputGroupText>
                        <FontAwesomeIcon spin={loading} size="xs"
                                         color={layoutStore.primaryColor}
                                         icon={loading ? faSpinner : faPercent}/>
                    </InputGroupText>
                </InputGroupAddon>
                <FormSelect id="#selectTabelaParticular" value={stepsStore.getParams.tabelaId} onChange={(e) => changeTabela(e.target.value)}
                            size="sm"
                            style={{paddingLeft: 33}}>
                    <option value={false}>Selecione uma tabela</option>
                    {tabelas.map((el, i) =>
                        <option key={i} value={el.id}>{el.tabela}</option>)
                    }
                </FormSelect>
            </InputGroup>
        </Col>
    )
};

export default SelectTabelaParticular;
