import moment from "moment";
import queryString from 'query-string';
import 'moment/locale/pt-br';

export const getUrlParams = (url, queryString = false) => {
    // get query string from url (optional) or window
    queryString = queryString ? queryString.replace("?", "") : url ? url.split('?')[1] : window.location.search.slice(1);

    // we'll store the parameters here
    let obj = {};

    // if query string exists
    if (queryString) {

        // stuff after # is not part of query string, so get rid of it
        queryString = queryString.split('#')[0];

        // split our query string into its component parts
        let arr = queryString.split('&');

        for (let i = 0; i < arr.length; i++) {
            // separate the keys and the values
            let a = arr[i].split('=');

            // set parameter name and value (use 'true' if empty)
            let paramName = a[0];
            let paramValue = typeof (a[1]) === 'undefined' ? true : a[1];


            // if the paramName ends with square brackets, e.g. colors[] or colors[2]
            if (paramName.match(/\[(\d+)?\]$/)) {

                // create key if it doesn't exist
                let key = paramName.replace(/\[(\d+)?\]/, '');
                if (!obj[key]) obj[key] = [];

                // if it's an indexed array e.g. colors[2]
                if (paramName.match(/\[\d+\]$/)) {
                    // get the index value and add the entry at the appropriate position
                    let index = /\[(\d+)\]/.exec(paramName)[1];
                    obj[key][index] = paramValue;
                } else {
                    // otherwise add the value to the end of the array
                    obj[key].push(paramValue);
                }
            } else {
                // we're dealing with a string
                if (!obj[paramName]) {
                    // if it doesn't exist, create property
                    obj[paramName] = paramValue;
                } else if (obj[paramName] && typeof obj[paramName] === 'string') {
                    // if property does exist and it's a string, convert it to an array
                    obj[paramName] = [obj[paramName]];
                    obj[paramName].push(paramValue);
                } else {
                    // otherwise add the property
                    obj[paramName].push(paramValue);
                }
            }
        }
    }

    return obj;
};

export const isValidEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const formatDate = (date, format) => {
    if (date) {
        moment.locale('pt-BR');

        switch (format) {
            case "weekDay":
                return moment(date).format("dddd") + ", " + moment(date).format("LL");
            case "y-m-d":
                return moment(date).format("YYYY-MM-DD");
            case "d/m/y":
                return moment(date).format("DD/MM/YYYY");
            case "dateObject":
                return moment(date).toDate();
        }
    }
};

export const getUrlSlugs = (path) => {
    if (!path) path = window.location.pathname;

    return path.split("/").filter(function (el) {
        return el.length != 0
    });
};

export const fakeTimout = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
};

export const objToQuery = (obj) => {
    if (obj) {
        return queryString.stringify(obj, {arrayFormat: "bracket"})
    }
};

export const objToBase64 = (obj) => {
    if (obj) {
        return btoa(JSON.stringify(obj));
    }
};

export const base64ToObj = (base64) => {
    if (base64) {
        let decoded = atob(base64);
        return JSON.parse(decoded);
    }
};

export const getStepUrl = (slug, step, data) => {
    const base64urlEncoded = encodeURIComponent(objToBase64(data));
    const queryString = `info=${base64urlEncoded}`;

    return `/agendamento/${slug}/${step}?${queryString}`;
};

export const isEmpty = (variable) => {
    if (typeof variable === "undefined") return true;
    if (typeof variable === "object" && Object.keys(variable).length === 0) return true;
    if (Array.isArray(variable) && variable.length === 0) return true;
    if (!variable) return true;

    return false;
};