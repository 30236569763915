import React from 'react';
import {useStoreState} from "easy-peasy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

const StepIcon = (props) => {
    const layout = useStoreState(state => state.layout);

    return (
        <div style={{backgroundColor: props.stepsStore.currentStep === props.stepKey ? layout.primaryColor : "#f0f6f7"}}
             className="circle">
            <FontAwesomeIcon className="icon-step"
                             color={props.stepsStore.currentStep === props.stepKey ? "white" : layout.primaryColor}
                             icon={props.stepsStore.availableSteps[props.stepKey].selectedValue ? faCheck : props.stepsStore.steps[props.stepKey].icon}/>
        </div>
    )
};

export default StepIcon;