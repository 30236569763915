import React from 'react';
import {useStoreState} from "easy-peasy";
import {Badge, Col, Row} from "shards-react";
import HourBadge from "./HourBadge";

import doutorPlaceholder from '../../img/doutor.png';
import doutoraPlaceholder from '../../img/doutora.png';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import {faVideo} from "@fortawesome/free-solid-svg-icons/faVideo"


const ProfessionalCard = (props) => {
    const layoutStore = useStoreState(s => s.layout);
    const stepsStore = useStoreState(s => s.steps);

    const getProfessionalAvatar = () => {
        if(layoutStore.getConfig("fotoPadraoProfissional")) {
            return layoutStore.getConfig("fotoPadraoProfissional")
        }

        if (props.professional.professionalInfo.sexo === "M") {
            return doutorPlaceholder;
        }

        if (props.professional.professionalInfo.sexo === "F") {
            return doutoraPlaceholder;
        }

        return doutorPlaceholder;
    };

    if (props.professional) {
        const profPhoto = props.professional.professionalInfo.foto || getProfessionalAvatar();

        return (
            <Col md={12} style={{marginTop: 20}}>
                <div
                    style={{
                        backgroundColor: "white",
                        borderRadius: 5,
                        padding: 20,
                        display: "flex",
                        flexDirection: "row"
                    }}>
                    <div>
                        <img style={{height: 75, width: 75, objectFit: 'cover'}}
                             src={profPhoto}
                             className="rounded-circle"
                             alt="user-avatar"/>
                    </div>
                    <div style={{marginLeft: 20, marginTop: 10, flex: 1, flexDirection: "column"}}>
                        <p style={{
                            fontFamily: "Montserrat",
                            fontWeight: "800",
                            fontSize: 18,
                            color: layoutStore.primaryColor,
                            marginBottom: 0
                        }}>{props.professional.professionalInfo.nome} {stepsStore.getParams.isTelemedicine ? <Badge style={{marginLeft: 5}} theme="success"><FontAwesomeIcon icon={faVideo}/> Consulta Online</Badge> : null}</p>
                        <p style={{
                            fontFamily: "Open Sans",
                            fontWeight: "500",
                            fontSize: 12,
                            color: "#9eadb5",
                            marginBottom: 0
                        }}>{props.professional.professionalInfo.crm}</p>
                        <Row style={{marginTop: 20}}>
                            {
                                props.professional.availableHours.map((availableHour, i) => {
                                        return <HourBadge key={i} hour={availableHour.horario}
                                                          localId={availableHour.localId} gradeId={availableHour.gradeId}
                                                          date={props.date}
                                                          professional={props.professional}/>
                                    }
                                )}
                        </Row>
                    </div>
                </div>
            </Col>
        )
    } else {
        return null;
    }
};

export default ProfessionalCard;
