import {action, thunk, computed} from 'easy-peasy';
import {formatDate, isEmpty} from "../../utils/utils";
import {getAvailableSchedule, getConvenioEligibility, getConvenios} from "../../services/scheduleService";
import moment from "moment";

const scheduleModel = {
    loading: false,
    error: false,
    needsReload: false,
    availableSchedule: [],
    convenios: {},
    month: moment().format("YYYY-MM-DD"),

    setAvailableSchedule: action((state, availableSchedule) => {
        state.availableSchedule = availableSchedule;
    }),

    setLoading: action((state, isLoading) => {
        state.loading = isLoading
    }),

    setError: action((state, error) => {
        state.error = error
    }),

    setValue: action((state, payload) => {
        let payloadKeys = Object.keys(payload);

        payloadKeys.forEach((key) => {
            if (state.hasOwnProperty(key)) {
                state[key] = payload[key];
            }
        })
    }),

    getAvailableSchedule: thunk(async (actions, payload, {getStoreState, getState}) => {
        const layoutState = getStoreState().layout;
        const stepsState = getStoreState().steps;
        const params = stepsState.getParams;

        if (layoutState.getConfig("filtroLatitudeLongitude") && (!params.latitude || !params.longitude || !params.range) && !params.isTelemedicine && !params.returnId) {
            return false;
        }

        actions.setLoading(true);
        try {
            const needsReload = true;
            const slug = layoutState.slug;
            const dateStart = payload ? moment(payload.startOfMonth).startOf('month').format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
            const dateEnd = params.limitDate || params.dateEnd;

            let procedureId = params.isTelemedicine ? params.selectedProcedures[0].telemedicineId : params.selectedProcedures[0].procedureId;
            procedureId = procedureId || 0;

            const availableSchedule = await getAvailableSchedule(slug, dateStart, dateEnd, params.appointTypeId, params.selectedProcedures[0].specialtyId, procedureId, params.convenioId, params.planoId, params.unityId, needsReload, params.latitude, params.longitude, params.range);

            actions.setAvailableSchedule(availableSchedule);
            if (needsReload) actions.setValue({needsReload: false});
        } catch (e) {
            throw e;
        }

        actions.setLoading(false);
    }),

    getConvenios: thunk(async (actions, payload, {getStoreState, getState}) => {
        try {
            //const params = getStoreState().steps.getParams;
            const slug = getStoreState().layout.slug;

            let result = {};
            const convenios = await getConvenios(slug);

            if (convenios.length > 0) {
                convenios.forEach((convenio) => {
                    result[convenio.id] = {
                        "id": convenio.id,
                        "name": convenio.nome,
                        "ans": convenio.registroAns,
                        "planos": convenio.Planos
                    }
                });
            }

            actions.setValue({"convenios": result});
        } catch (e) {
            throw e;
        }
    }),

    getInitialValues: thunk(async (actions, payload, {getStoreState, getStoreActions}) => {
        const selectedSchedule = getStoreState().steps.availableSteps["horario-profissional"].selectedValue;

        if (!selectedSchedule) {
            const stepsActions = getStoreActions().steps;

            const professionalId = payload.professionalId;
            const selectedValue = payload.selectedValues["horario-profissional"];
            const hour = payload.hour;
            const date = payload.dateStart;

            stepsActions.setSelectedValue({
                "horario-profissional": selectedValue,
                "data": formatDate(date, "d/m/y"),
            });

            stepsActions.setStepData({
                "data": {
                    dateStart: date,
                },
                "horario-profissional": {
                    "professionalId": professionalId,
                    "hour": hour
                },
            });
        }
    }),

    onMonthChange: thunk(async (actions, month, {getStoreState, getState, getStoreActions}) => {
        const stepsActions = getStoreActions().steps;
        actions.setValue({month: month});

        stepsActions.setStepData({"data": {"dateStart": month}});
        stepsActions.setSelectedValue({"data": formatDate(month, "d/m/y")});

        await actions.getAvailableSchedule({startOfMonth: month});

        const newDate = moment(month).min();
        stepsActions.setStepData({"data": {"dateStart": newDate}});
        stepsActions.setSelectedValue({"data": formatDate(newDate, "d/m/y")});
    }),
};

export default scheduleModel;
