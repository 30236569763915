import React, {useState, useEffect} from 'react';
import {useStoreActions, useStoreState} from "easy-peasy";
import 'moment/locale/pt-br';
import {Col, Fade, FormSelect, ModalBody, ModalHeader, Row} from "shards-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt, faVenusMars} from "@fortawesome/free-solid-svg-icons";
import ScheduleDate from "./ScheduleDate";
import ComponentOverlay from "../../layout/LoadingContent/ComponentOverlay";
import {isEmpty} from "../../utils/utils";
import moment from "moment";
import DataNotFound from "../../layout/Content/DataNotFound";
import SelectTelemedicine from "../CentroMedico/SelectTelemedicine";
import SelectConvenio from "../CentroMedico/SelectConvenio";

const HorariosProfissionais = (props) => {
    const [gender, setGender] = useState("");

    const stepsStore = useStoreState(s => s.steps);
    const scheduleStore = useStoreState(s => s.schedule);
    const layoutStore = useStoreState(s => s.layout);
    const layoutActions = useStoreActions(a => a.layout);
    const scheduleActions = useStoreActions(a => a.schedule);
    const stepsActions = useStoreActions(a => a.steps);
    const proceduresActions = useStoreActions(a => a.procedure);
    const unitsActions = useStoreActions(a => a.units);
    const dateStart = stepsStore.getStepData("data", "dateStart");

    const formatAvailableSchedule = () => {
        let result = {};
        if (isEmpty(scheduleStore.availableSchedule)) return result;
        const limitIds = stepsStore.getParams.limitIds && stepsStore.getParams.limitIds["horario-profissional"];

        scheduleStore.availableSchedule.map((schedule) => {
            if (moment(schedule.data).isSame(dateStart, 'day') || moment(schedule.data).isAfter(dateStart, 'day')) {
                if (!result.hasOwnProperty(schedule.data)) result[schedule.data] = [];
                schedule.unidades.map((unidade) => {
                    if ((unidade.id === stepsStore.getParams.unityId || stepsStore.getParams.isTelemedicine) && unidade.vagas.profissionais) {
                        unidade.vagas.profissionais.map((professional) => {
                            if (professional.disponiveis && (!limitIds || (limitIds && limitIds.includes(professional.id)))) {
                                result[schedule.data].push({
                                    "professionalInfo": {
                                        id: professional.id,
                                        nome: professional.nome,
                                        crm: professional.crm,
                                        foto: professional.foto,
                                        sexo: professional.sexo
                                    },
                                    "professionalId": professional.id,
                                    "availableHours": professional.disponiveis.map((grade) => {
                                        return {
                                            "horario": moment(grade.horario, "HH:mm:ss").format("HH:mm"),
                                            "gradeId": grade.gradeId,
                                            "localId": grade.localId,
                                        }
                                    }),
                                })
                            }
                        })
                    }
                });
            }
        });


        return result;
    };

    let availableSchedule = formatAvailableSchedule();

    const initialParams = {
        stepActions: scheduleActions,
        requiredQueryParams: [
            {param: "appointTypeId", name: "Tipo de agendamento"},
            {param: "selectedProcedures", name: "Procedimentos"},
        ],
        initialDataFunctions: [
            proceduresActions.getInitialValues,
            unitsActions.getInitialValues,
            scheduleActions.getAvailableSchedule,
            scheduleActions.getConvenios,
        ]
    };

    useEffect(() => {
        stepsActions.setCurrentStep("horario-profissional");
        props.getStepInitialData(initialParams);
        renderAlreadyScheduledAlert();
    }, []);

    const renderSchedules = () => {
        if (isEmpty(availableSchedule)) {
            return <DataNotFound/>;
        } else {
            return Object.keys(availableSchedule).map((date) => (
                <ScheduleDate gender={gender} key={date} date={date} schedule={availableSchedule[date]}/>
            ))
        }
    };

    const renderAlreadyScheduledAlert = () => {
        if (scheduleStore.needsReload) {
            layoutActions.setModalContent(
                <div>
                    <ModalHeader>O
                        horário
                        escolhido
                        já
                        foi
                        agendado.</ModalHeader>
                    <ModalBody>Aguarde
                        um
                        momento, estamos buscando novos horários disponíveis.</ModalBody>
                </div>
            )
        }
    };

    return (
        <Fade>
            <Row>
                <p style={{
                    fontFamily: "Montserrat",
                    fontSize: "32px",
                    color: "#2c404a",
                    fontWeight: "500",
                    letterSpacing: "-2.75px"
                }}>Selecione um profissional</p>
            </Row>
            <Row>
                {/*<Col md="4" style={{maxWidth: 300}}>*/}
                    {/*<FontAwesomeIcon style={{position: "absolute", top: 13, left: 25}} color={layoutStore.primaryColor}*/}
                                     {/*icon={faVenusMars}/>*/}
                    {/*<FormSelect value={gender} onChange={(e) => setGender(e.target.value)}*/}
                                {/*style={{paddingLeft: 32}} className="custom-select-feegow">*/}
                        {/*<option value="">Gênero dos médicos</option>*/}
                        {/*<option value="M">Masculino</option>*/}
                        {/*<option value="F">Feminino</option>*/}
                    {/*</FormSelect>*/}
                {/*</Col>*/}
                <SelectTelemedicine/>
                <SelectConvenio/>
            </Row>
            <ComponentOverlay error={scheduleStore.error} loading={scheduleStore.loading}>
                {renderSchedules()}
            </ComponentOverlay>
        </Fade>
    )
};

export default HorariosProfissionais;
