import * as firebase from "firebase/app";
import "firebase/auth";
import firebaseConfig from "../utils/FirebaseConfig";
import {isValidEmail} from "../utils/utils";

firebase.initializeApp(firebaseConfig);

export const getSignUpErrorMessage = (errorCode) => {
    switch (errorCode) {
        case "auth/email-already-in-use":
            return "O e-mail informado já está sendo utilizado";
        case "auth/invalid-email":
            return "O e-mail informado não é válido";
        case "auth/operation-not-allowed":
            return "Operação de cadastro inválida";
        case "auth/weak-password":
            return "A senha informada não é forte o suficiente";
    }
};

export const getSignInErrorMessage = (e) => {
    switch (e.code) {
        case "auth/user-disabled":
            return "O usuário foi inativado";
        case "auth/invalid-email":
            return "O e-mail informado não é válido";
        case "auth/user-not-found":
            return "Email não encontrado";
        case "auth/wrong-password":
            return "Senha inválida";
        case "auth/account-exists-with-different-credential":
            return "Você já possui uma conta cadastrada com esse e-mail, faça o login usando o provedor previamente cadastrado (Google, Facebook ou Email)";
        default:
            return e.message;
    }
};

export const getPasswordResetEmailError = (errorCode) => {
    switch (errorCode) {
        case "auth/invalid-email":
            return "O e-mail informado não é válido";
        case "auth/user-not-found":
            return "Usuário não encontrado";
    }
};

export const createUserWithEmail = async (email, password, displayName) => {
    if (!displayName) throw new Error("Nenhum nome informado");
    if (!isValidEmail(email)) throw new Error("O e-mail informado é inválido");

    try {
        const loginInfo = await firebase.auth().createUserWithEmailAndPassword(email, password);
        await loginInfo.user.updateProfile({displayName: displayName});

        return loginInfo.user;
    } catch (e) {
        throw new Error(getSignUpErrorMessage(e.code));
    }
};

export const signInWithEmail = async (email, password) => {
    if (!email) throw new Error("Nenhum email informado");
    if (!password) throw new Error("Nenhuma senha informada");

    try {
        const loginInfo = await firebase.auth().signInWithEmailAndPassword(email, password);

        return loginInfo.user;
    } catch (e) {
        throw new Error(getSignInErrorMessage(e));
    }
};

export const sendPasswordResetEmail = async (email, redirectUrl) => {
    if (!email) throw new Error("Nenhum email informado");
    if (!redirectUrl) throw new Error("Nenhuma URL de redirecionamento informada");

    try {
        return await firebase.auth().sendPasswordResetEmail(email, {url: redirectUrl});
    } catch (e) {
        throw new Error(getPasswordResetEmailError(e.code));
    }
};

export const signInWithGoogle = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().languageCode = 'pt';

    try {
        return await firebase.auth().signInWithRedirect(provider);
    } catch (e) {
        throw new Error(e.message)
    }
};

export const signInWithFacebook = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().languageCode = 'pt';

    try {
        return await firebase.auth().signInWithRedirect(provider);
    } catch (e) {
        throw new Error(e.message)
    }
};

export const redirectResult = async () => {
    try {
        return await firebase.auth().getRedirectResult();
    } catch (e) {
        throw new Error(getSignInErrorMessage(e))
    }
};
