import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faVideo} from "@fortawesome/free-solid-svg-icons";
import {Button} from "shards-react";
import {useStoreState} from "easy-peasy";

const BUTTON_TEXT = "Consulta online";

const TelemedicineButton = ({procedure, onSelectProcedure}) => {
    const layoutStore = useStoreState((s) => s.layout);

    const getProcedurePrice = () => {
        if (!layoutStore.getConfig('exibirPrecoEspecialidade')) return null;
        if (typeof procedure.telemedicineAmount === "undefined") return null;
        if (procedure.telemedicineAmount === "0.00") return null;
        if (!procedure.telemedicineAmount) return null;

        return ` - R$ ${procedure.telemedicineAmount}`;
    };

    if (!procedure.telemedicineId) return null;
    return (
        <Button className="btn-outline-secondary" outline style={{
            borderColor: layoutStore.secondaryColor,
            marginRight: 5
        }} onClick={() => onSelectProcedure(procedure, true)} pill><FontAwesomeIcon
            icon={faVideo}/> {BUTTON_TEXT} {getProcedurePrice()}</Button>
    )
};

export default TelemedicineButton;