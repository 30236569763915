import React from 'react';

import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import {ApolloProvider} from '@apollo/react-hooks';
import client from "./utils/ApolloClient";

import {StoreProvider} from 'easy-peasy';
import store from './store/store';
import Layout from './layout/Layout/Layout';
import {BrowserRouter} from "react-router-dom";

function App() {
    return (
        <ApolloProvider client={client}>
            <StoreProvider store={store}>
                <BrowserRouter>
                    <Layout/>
                </BrowserRouter>
            </StoreProvider>
        </ApolloProvider>
    );
}

export default App;
