import stepsModel from './stepsModel';
import userModel from "./userModel";
import layoutModel from "./layoutModel";
import unitsModel from "./unitsModel";
import scheduleModel from "./scheduleModel";
import patientModel from "./patientModel";
import procedureModel from "./procedureModel";
import confirmationModel from "./confirmationModel";

const storeModel = {
    steps: stepsModel,
    user: userModel,
    layout: layoutModel,
    units: unitsModel,
    schedule: scheduleModel,
    patient: patientModel,
    procedure: procedureModel,
    confirmation: confirmationModel,
};

export default storeModel;