import React, {useState, useEffect} from 'react';
import {Col, Fade, FormSelect, Row} from "shards-react";
import {useStoreActions, useStoreState} from "easy-peasy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import './UnitDate.css';
import ComponentOverlay from "../../layout/LoadingContent/ComponentOverlay";
import UnitDates from "./UnitDates";
import {isEmpty} from "../../utils/utils";
import DataNotFound from "../../layout/Content/DataNotFound";
import SelectTelemedicine from "./SelectTelemedicine";
import SelectConvenio from "./SelectConvenio";
import SelectMunicipio from "./SelectMunicipio";
import moment from 'moment';

const CentroMedico = (props) => {
    const [region, setRegion] = useState("");
    const unitStore = useStoreState(s => s.units);
    const unitsActions = useStoreActions(a => a.units);
    const layoutStore = useStoreState(s => s.layout);
    const unitActions = useStoreActions(a => a.units);
    const stepsStore = useStoreState(s => s.steps);
    const stepsActions = useStoreActions(a => a.steps);
    const proceduresActions = useStoreActions(a => a.procedure);
    const scheduleActions = useStoreActions(a => a.schedule);
    const scheduleState = useStoreState(s => s.schedule);
    const dateStart = stepsStore.getStepData("data", "dateStart");

    const formatAvailableUnits = () => {
        let availableUnits = [];
        const limitIds = stepsStore.getParams.limitIds && stepsStore.getParams.limitIds["centro-medico"];

        scheduleState.availableSchedule.forEach((schedule) => {
            if (dateStart) {
                if (moment(schedule.data).isSame(dateStart, 'day') || moment(schedule.data).isAfter(dateStart, 'day')) {
                    availableUnits.push({
                        "data": schedule.data,
                        "unidades": schedule.unidades.filter((unidade) => !limitIds || (limitIds && limitIds.includes(unidade.id))).map((unidade) => {
                            return {
                                "idUnidade": unidade.id,
                                "nomedaUnidade": unidade.nomeUnidade,
                                "cidade": unidade.endereco.cidade,
                                "bairro": unidade.endereco.bairro,
                                "endereco": unidade.endereco.rua,
                                "numero": unidade.endereco.numero,
                                "complemento": unidade.endereco.complemento,
                                "vagasDisponiveis": unidade.vagas.quantidadeVagas
                            }
                        })
                    });
                }
            }
        });

        return availableUnits;
    };

    const formatRegions = () => {
        let result = [];

        if (availableUnits.length > 0) {
            availableUnits.forEach((dates) => {
                dates.unidades.forEach((unit) => {
                    if (unit.bairro) {
                        if (!result.includes(unit.bairro)) {
                            result.push(unit.bairro);
                        }
                    }
                });
            });
        }

        return result;
    };

    const availableUnits = formatAvailableUnits();
    const regions = formatRegions();

    const initialParams = {
        stepActions: unitActions,
        requiredQueryParams: [
            {param: "appointTypeId", name: "Tipo de agendamento"},
            {param: "selectedProcedures", name: "Procedimentos"}
        ],
        initialDataFunctions: [
            proceduresActions.getInitialValues,
            scheduleActions.getAvailableSchedule,
            scheduleActions.getConvenios,
        ]
    };

    useEffect(() => {
        unitsActions.setUnitInfo({unityName: false, unityId: false});
        stepsActions.setCurrentStep("centro-medico");
        props.getStepInitialData(initialParams);
    }, []);

    return (
        <Fade>
            <Row>
                <p style={{
                    fontFamily: "Montserrat",
                    fontSize: "32px",
                    color: "#2c404a",
                    fontWeight: "500",
                    letterSpacing: "-2.75px"
                }}>Selecione um centro médico</p>
            </Row>
            <Row>
                {layoutStore.getConfig("filtroLatitudeLongitude") ?
                    <Col md={4}>
                        <SelectMunicipio/>
                    </Col>
                    :
                    <Col md="4" style={{maxWidth: 300}}>
                        <FontAwesomeIcon style={{position: "absolute", top: 13, left: 25}}
                                         color={layoutStore.primaryColor}
                                         icon={faMapMarkerAlt}/>
                        <FormSelect value={region} onChange={(e) => setRegion(e.target.value)}
                                    style={{paddingLeft: 28}} className="custom-select-feegow">
                            <option value="">Selecionar uma região</option>
                            {regions.map((el, index) => (
                                <option key={el} value={el}>{el}</option>
                            ))}
                        </FormSelect>
                    </Col>
                }
                <SelectTelemedicine/>
                <SelectConvenio/>
            </Row>
            <ComponentOverlay loading={scheduleState.loading} error={scheduleState.error}>
                {isEmpty(availableUnits) ?
                    <DataNotFound/> : <UnitDates availableUnits={availableUnits} region={region}/>}
            </ComponentOverlay>
        </Fade>
    )
};

export default CentroMedico;
