import React, {useState, useEffect} from 'react';
import {Alert, Col, Fade, Form, FormGroup, FormInput, Row} from "shards-react";
import SendEmailButton from "./SendEmailButton";

const VerifyEmail = (props) => {

    const sendConfirmEmail = async () => {
        await props.user.sendEmailVerification();
    };

    useEffect(() => {
        sendConfirmEmail();
    }, []);

    return (
        <Fade>
            <Row>
                <Col style={{textAlign: "center"}}>
                    <h5 style={{fontFamily: 'Montserrat'}}>Email de verificação enviado!</h5>
                    <p style={{opacity: 0.80, fontSize: 14}}>Esta ação requere a verificação da sua identidade. Por
                        favor,
                        verifique sua caixa de entrada e siga as instruções.</p>
                </Col>
            </Row>
            <Row style={{marginTop: 25}}>
                <Col style={{textAlign: "center"}}>
                    <Alert theme="success">
                        <a style={{fontSize: 12}} onClick={() => sendConfirmEmail()} className="alert-link" href="#">
                            Não recebeu? Clique aqui para enviar novamente.
                        </a>
                    </Alert>
                </Col>
            </Row>
            <Row style={{marginTop: -5}}>
                <Col style={{textAlign: "center"}}>
                    <a style={{fontSize: 12}} onClick={() => props.setCurrentPage('login', props.user.email)}
                       className="alert-link" href="#">
                        Já verifiquei meu e-mail. Fazer o login.
                    </a>
                </Col>
            </Row>
        </Fade>
    )
};

export default VerifyEmail;