import {useLocation} from "react-router-dom";
import {base64ToObj, getUrlParams} from "../utils/utils";

export function useQueryParams() {
    let queryString = useLocation().search;
    let urlParams = getUrlParams(false, queryString);
    if (!urlParams.info) return false;

    let base64 = decodeURIComponent(urlParams.info);
    return base64ToObj(base64);
}

export function useQuery() {
    return new URLSearchParams(useLocation().search);
}
