import React from 'react';
import {useStoreState} from "easy-peasy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStethoscope} from "@fortawesome/free-solid-svg-icons";
import {Button} from "shards-react";

const SPECIALTY_TEXT = " Consulta presencial";
const PROCEDURE_TEXT = " Agendar";

const AppointButton = ({procedure, onSelectProcedure, appointTypeId}) => {
    const layoutStore = useStoreState((s) => s.layout);

    const getProcedurePrice = () => {
        if (!layoutStore.getConfig('exibirPrecoEspecialidade')) return null;
        if (typeof procedure.amount === "undefined") return null;
        if (procedure.amount === "0.00") return null;
        if (!procedure.amount) return null;

        return ` - R$ ${procedure.amount}`;
    };

    if (procedure.allowAppointment) return null;
    if (!procedure.procedureId) return null;
    if (!layoutStore.getConfig("permitirConsultaPresencial")) return null;
    return (
        <Button onClick={() => onSelectProcedure(procedure)} pill>
            <FontAwesomeIcon icon={faStethoscope}/>
            {appointTypeId === 3 ? PROCEDURE_TEXT : SPECIALTY_TEXT}
            {getProcedurePrice()}
        </Button>
    )
};

export default AppointButton;