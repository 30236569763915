import React from 'react';
import {useStoreState} from "easy-peasy";
import LoginModal from "../Modal/LoginModal";

const AuthenticatedComponent = (props) => {
    const userState = useStoreState(s => s.user);

    if (userState.isLoggedIn) {
        return props.component;
    }

    return (
        <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style={{maxWidth: 700, width: 700}}>
                <LoginModal/>
            </div>
        </div>
    )
};

export default AuthenticatedComponent;