import React, {useState, useEffect} from 'react';
import {useStoreActions, useStoreState} from "easy-peasy";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome/index.es"
import Loader from 'react-loader-spinner';
import {getAvailableReturns} from "../../services/procedureService"
import * as moment from 'moment'
import {Button} from "shards-react"
import {faUserMd} from "@fortawesome/free-solid-svg-icons/index.es"
import {faCalendar} from "@fortawesome/free-regular-svg-icons/faCalendar"
import {faUser} from "@fortawesome/free-regular-svg-icons/faUser"
import {getStepUrl} from "../../utils/utils"
import {withRouter} from "react-router-dom"

const RenderReturns = (props) => {
    const layoutStore = useStoreState(s => s.layout);
    const stepsStore = useStoreState(s => s.steps);
    const stepsActions = useStoreActions(a => a.steps);

    const [loading, setLoading] = useState(true);
    const [returns, setReturns] = useState([]);

    const getPatientReturns = async () => {
        const availableReturns = await getAvailableReturns(layoutStore.slug, moment().subtract(5, 'months'));
        setLoading(false);
        setReturns(availableReturns);
    };

    const createReturn = async (appoint) => {
        stepsActions.setStepData({
            "especialidade": {
                appointTypeId: 2, //aqui o ideal seria retornar do back, fixo em consulta (especialidade)
                selectedProcedures: [{
                    specialtyId: parseInt(appoint.Procedimentos[0].especialidade[0].EspecialidadeID),
                    procedureId: parseInt(appoint.Procedimentos[0].id)
                }],
                returnId: appoint.ID,
                limitIds: {
                    "centro-medico": [appoint.UnitAvailable.id],
                    "horario-profissional": [appoint.Profissional.id],
                    "paciente": [appoint.Paciente.id]
                }
            },
            "data": {
                "limitDate": appoint.DataLimite
            },
            "centro-medico": {
                "unitId": appoint.UnitAvailable.id,
            },
        });

        stepsActions.setSelectedValue({
            "especialidade": appoint.Procedimentos[0].especialidade[0].tipoEspecialidade + " - RETORNO",
            "centro-medico": appoint.UnitAvailable.nomeUnidade
        });

        props.history.push(getStepUrl(layoutStore.slug, 'horario-profissional', stepsStore.getParams));
    };

    useEffect(() => {
        getPatientReturns();
    }, []);

    return (
        <div style={{
            display: "flex",
            marginTop: 40,
            justifyContent: "center",
        }}>
            <div style={{
                backgroundColor: "rgba(0,0,0,0.01)",
                borderRadius: 5,
                width: 900,
                padding: 25,
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
            }}>
                {loading ? <Loader type="Oval" color="rgba(0,0,0,0.2)" height={50} width={50}/> : null}
                {!loading && returns.length === 0 ? <p style={{marginBottom: 0}}>Nenhum retorno disponível no momento</p> : null}
                {returns.map((appoint, key) => (
                    <div key={key} style={{
                        borderRadius: 8,
                        backgroundColor: "white",
                        display: "flex",
                        width: '100%',
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: 25,
                        marginTop: 15
                    }}>
                        <div>
                            <p style={{
                                marginBottom: 5,
                                fontFamily: "Open Sans",
                                color: "#686868",
                                fontWeight: '500',
                                fontSize: 16
                            }}>{appoint.Procedimentos[0].especialidade[0].tipoEspecialidade}</p>
                            <p style={{fontFamily: "Open Sans", fontSize: 14, marginBottom: 20}}><FontAwesomeIcon color={layoutStore.primaryColor}
                                                                                                                  icon={faUserMd}/> Dr(a). {appoint.Profissional.nome}
                            </p>
                            <p style={{marginBottom: 5, fontFamily: "Open Sans", fontSize: 14}}><FontAwesomeIcon color={layoutStore.primaryColor}
                                                                                                                 icon={faUser}/> {appoint.Paciente.nome}
                            </p>
                            <p style={{marginBottom: 5, fontFamily: "Open Sans", fontSize: 14}}><FontAwesomeIcon color={layoutStore.primaryColor}
                                                                                                                 icon={faCalendar}/> {appoint.Data} / {appoint.Horario}
                            </p>
                        </div>
                        <div>
                            <Button onClick={() => createReturn(appoint)} style={{marginRight: 5}} pill>Agendar retorno</Button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
};

export default withRouter(RenderReturns);