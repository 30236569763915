const firebaseConfig = {
    apiKey: "AIzaSyDr2m46fIlkmnMfi2GDvr9HvNnVti6xMAs",
    authDomain: "feegow-paciente.firebaseapp.com",
    databaseURL: "https://feegow-paciente.firebaseio.com",
    projectId: "feegow-paciente",
    storageBucket: "",
    messagingSenderId: "1023243352955",
    appId: "1:1023243352955:web:8a7edfbd4a9ec841eb9bde"
};

export default firebaseConfig;