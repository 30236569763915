import ApolloClient from 'apollo-boost/lib/index';

window.apiUrl = "https://api-paciente.feegow.com.br/graphql";
// window.apiUrl = "http://localhost:8000/graphql";

const client = new ApolloClient({
    request: (operation) => {
        const slug = localStorage.getItem('slug');
        const userInfoString = localStorage.getItem('userInfo-' + slug);
        const userInfo = JSON.parse(userInfoString);
        const buscaId = localStorage.getItem('feegow-agendamento-busca-id');
        operation.setContext({
            uri: window.apiUrl,
            headers: {
                authorization: userInfo ? `Bearer ${userInfo.token}` : '',
                BuscaID: buscaId
            }
        })
    }
});

export default client;