import React from 'react';
import {useStoreActions, useStoreState} from "easy-peasy";
import {formatDate, getStepUrl} from "../../utils/utils";
import {Col} from "shards-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";
import {withRouter} from "react-router-dom";

import unityPlaceholder from '../../img/unidade.png';

const UnitCard = (props) => {
    const layoutStore = useStoreState(s => s.layout);
    const stepsStore = useStoreState(s => s.steps);
    const stepActions = useStoreActions(a => a.steps);
    const isSelected = props.unitInfo.idUnidade === stepsStore.availableSteps["centro-medico"].data.unitId && props.date === stepsStore.availableSteps["data"].data.dateStart;

    const unityPhoto = props.unitInfo.foto ? props.unitInfo.foto : unityPlaceholder;

    const selectUnity = () => {
        stepActions.setStepData({
            "centro-medico": {
                unitId: props.unitInfo.idUnidade
            },
            "data": {
                dateStart: props.date
            }
        });

        stepActions.setSelectedValue({
            "centro-medico": props.unitInfo.nomedaUnidade,
            "data": formatDate(props.date, "d/m/y")
        });

        const nextStep = stepActions.toNextStep();
        props.history.push(getStepUrl(layoutStore.slug, nextStep, stepsStore.getParams))
    };


    const renderSelectedIcon = () => {
        if (isSelected) {
            return (
                <div style={{position: "absolute", top: -10, right: 3, backgroundColor: "white"}}>
                    <FontAwesomeIcon style={{fontSize: 25}} color={layoutStore.primaryColor} icon={faCheckCircle}/>
                </div>
            )
        } else {
            return null
        }
    };

    return (
        <Col sm="6" md="4" style={{marginTop: 20}}>
            {renderSelectedIcon()}
            <div
                className="card-hover"
                onClick={() => {
                    selectUnity(props.date, props.unitInfo.idUnidade, props.unitInfo.nomedaUnidade);
                }}
                style={{
                    backgroundColor: "white",
                    borderRadius: 5,
                    padding: 20,
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    border: isSelected ? `1px solid ${layoutStore.primaryColor}` : null
                }}>
                <div>
                    <img style={{height: 75}}
                         src={unityPhoto}
                         className="rounded-circle"
                         alt="user-avatar"/>
                </div>
                <div style={{marginLeft: 20}}>
                    <p style={{
                        fontFamily: "Montserrat",
                        fontWeight: "600",
                        fontSize: 12,
                        color: layoutStore.secondaryColor,
                        marginBottom: 0
                    }}>{props.unitInfo.cidade} - {props.unitInfo.bairro}</p>
                    <p style={{
                        fontFamily: "Montserrat",
                        fontWeight: "800",
                        fontSize: 18,
                        color: layoutStore.primaryColor,
                        marginBottom: 0
                    }}>{props.unitInfo.nomedaUnidade}</p>
                    <p style={{
                        fontFamily: "Open Sans",
                        fontWeight: "500",
                        fontSize: 12,
                        color: "#9eadb5",
                        marginBottom: 0
                    }}>{props.unitInfo.endereco}, {props.unitInfo.numero} - {props.unitInfo.complemento}</p>
                    <p style={{
                        fontFamily: "Open Sans",
                        fontWeight: "800",
                        fontSize: 12,
                        color: "#9eadb5",
                        marginBottom: 0
                    }}>{props.unitInfo.vagasDisponiveis} vagas</p>
                </div>
            </div>
        </Col>
    )
};

export default withRouter(UnitCard);
