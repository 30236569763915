import React, {useEffect, useState} from 'react';
import DayPicker from 'react-day-picker';
import {useStoreState, useStoreActions} from "easy-peasy";

import './DatePicker.css';
import {Collapse} from "shards-react";
import {formatDate} from "../../../utils/utils";
import moment from "moment";
import LoadingOverlay from 'react-loading-overlay';
import Loader from 'react-loader-spinner'

import './LoadingOverlay.css';

const DatePicker = (props) => {
    const [loading, setLoading] = useState(false);
    const [disabledDays, setDisableDays] = useState([]);
    const layoutStore = useStoreState(s => s.layout);
    const stepsStore = useStoreState(s => s.steps);
    const stepsActions = useStoreActions(a => a.steps);
    const unitsActions = useStoreActions(a => a.units);
    const scheduleActions = useStoreActions(a => a.schedule);
    const scheduleState = useStoreState(s => s.schedule);
    const availableSchedule = scheduleState.availableSchedule;

    const selectedDate = [formatDate(stepsStore.availableSteps["data"].data.dateStart, "dateObject")];
    const displayDate = stepsStore.availableSteps["data"].selectedValue;

    const dateIsDisabled = (day) => {
        let isDisabled = false;

        disabledDays.forEach((date) => {
            if (formatDate(date, "y-m-d") === formatDate(day, "y-m-d"))
                isDisabled = true;
        });

        return isDisabled;
    };

    const handleDayClick = async (day) => {
        if (!dateIsDisabled(day)) {
            stepsActions.setSelectedValue({"data": formatDate(day, "d/m/y")});
            stepsActions.setStepData({"data": {"dateStart": day}});
        }
    };

    const getDaysInMonth = () => {
        const daysInMonth = moment(scheduleState.month).daysInMonth();
        const yearMonth = moment(scheduleState.month).format("YYYY-MM-");

        let daysInMonthArray = [];

        for (let i = 0; i < daysInMonth; i++) {
            let day = i + 1;
            if (day.toString().length === 1) day = "0" + day;

            let date = yearMonth + day;
            daysInMonthArray.push(date);
        }

        return daysInMonthArray;
    };

    const getAvailableDates = () => {
        let result = [];

        if (availableSchedule) {
            availableSchedule.forEach((schedule) => {
                result.push(schedule.data);
            });
        }

        return result;
    };

    const setDisabledDays = () => {
        const daysInMonth = getDaysInMonth();
        const availableDates = getAvailableDates();
        const disabledDays = daysInMonth.filter(date => !availableDates.includes(date) || (stepsStore.getParams.limitDate && moment(date).isAfter(stepsStore.getParams.limitDate)));
        let disableDaysArray = [];

        disabledDays.forEach((date) => {
            disableDaysArray.push(formatDate(date, "dateObject"));
        });

        setDisableDays(disableDaysArray);
    };

    const onMonthChange = async (month) => {
        scheduleActions.setValue({month: month});
        stepsActions.setStepData({"data": {"dateStart": month}});
        stepsActions.setSelectedValue({"data": formatDate(month, "d/m/y")});

        await scheduleActions.getAvailableSchedule({startOfMonth: month});
        const newDate = moment(month).min();
        stepsActions.setStepData({"data": {"dateStart": newDate}});
        stepsActions.setSelectedValue({"data": formatDate(newDate, "d/m/y")});
    };

    useEffect(() => {
        const now = moment().format("YYYY-MM-DD");

        if (!stepsStore.availableSteps.data.data.dateStart) {
            stepsActions.setStepData({"data": {"dateStart": now}});
            stepsActions.setSelectedValue({"data": formatDate(now, "d/m/y")})
        }
    }, []);

    useEffect(() => {
        setDisabledDays();
    }, [availableSchedule]);

    const MONTHS = [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
    ];
    const WEEKDAYS_LONG = [
        'Domingo',
        'Segunda-feira',
        'Terça-feira',
        'Quarta-feira',
        'Quinta-feira',
        'Sexta-feira',
        'Sabado',
    ];
    const WEEKDAYS_SHORT = ['DOM', 'SEG', 'TER', 'QUA', 'QUI', 'SEX', 'SAB'];

    const buttonStyle = `
        .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;

    background-color: ${layoutStore.primaryColor};
    color: #F0F8FF;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: ${layoutStore.primaryColor};
}

.DayPicker-Caption {
    color: ${layoutStore.primaryColor}
}`;

    return (
        <div>
            <style>{buttonStyle}</style>
            {props.hideSelectedDate ? null :
                <p onClick={() => setLoading(!loading)} className="selected-date">{displayDate}</p>}
            <Collapse open={stepsStore.availableSteps[stepsStore.currentStep].canSelectDate && !layoutStore.loading}>
                <LoadingOverlay
                    classNamePrefix="_loading_overlay_datepicker_"
                    spinner={<Loader type="ThreeDots" width={25} height={20} color={layoutStore.primaryColor}/>}
                    active={scheduleState.loading && !layoutStore.loading}>
                    <DayPicker
                        disabledDays={disabledDays}
                        fromMonth={new Date()}
                        onMonthChange={scheduleActions.onMonthChange}
                        onDayClick={handleDayClick}
                        month={formatDate(scheduleState.month, 'dateObject')}
                        selectedDays={selectedDate}
                        locale="pt-br"
                        months={MONTHS}
                        weekdaysLong={WEEKDAYS_LONG}
                        weekdaysShort={WEEKDAYS_SHORT}
                        firstDayOfWeek={1}/>
                </LoadingOverlay>
            </Collapse>
        </div>
    )
};

export default DatePicker;