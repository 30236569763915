import React, {useState, useEffect} from 'react';
import {Col, Row} from "shards-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useStoreState} from "easy-peasy";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons/index.es"

const LayoutError = (props) => {
    const [error, setError] = useState(false);
    const layoutStore = useStoreState(s => s.layout);

    useEffect(() => {
        setError(props.error);
    }, [props.error]);


    if (layoutStore.loading) {
        return null;

    }

    if (error && error.includes("Unexpected")) {
        return (
            <Row>
                <Col style={{textAlign: "center", marginTop: 140, opacity: 0.20}}>
                    <FontAwesomeIcon style={{fontSize: 32}} icon={faExclamationTriangle}/>
                    <p style={{fontSize: 24, marginTop: 20, fontWeight: "500"}}>Ocorreu um erro interno nos nossos servidores. <br/> Confira se sua URL está correta, caso esteja, tente novamente mais tarde.</p>
                </Col>
            </Row>
        )
    }

    if(error && error.includes("Network")) {
        return (
            <Row>
                <Col style={{textAlign: "center", marginTop: 140, opacity: 0.20}}>
                    <FontAwesomeIcon style={{fontSize: 32}} icon={faExclamationTriangle}/>
                    <p style={{fontSize: 24, marginTop: 20, fontWeight: "500"}}>Ocorreu um erro de conexão <br/> Confira sua conexão com a internet ou tente novamente mais tarde.</p>
                </Col>
            </Row>
        )
    }

    return (
        <Row>
            <Col style={{textAlign: "center", marginTop: 140, opacity: 0.20}}>
                <FontAwesomeIcon style={{fontSize: 32}} icon={faExclamationTriangle}/>
                <p style={{fontSize: 24, marginTop: 20, fontWeight: "500"}}>{error}</p>
            </Col>
        </Row>
    )
};

export default LayoutError;