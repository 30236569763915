import client from "../utils/ApolloClient";
import {gql} from "apollo-boost";

export const GET_REQUIRED_FIELDS = gql`
    query RequiredFieldsQuery($slug: String!) {
        RequiredFieldsQuery (slug: $slug) {
            id,
            campos {
                campo,
                obrigatorio
            }
        }
    }
`;


export const GET_AVAILABLE_PATIENTS = gql`
  query getAvailablePatients($slug: String!, $isAdmin: Boolean!, $searchTerm: String) {
      patient(slug: $slug, searchTerm: $searchTerm){
        id
        nome
        nascimento
        cpf,
        celular,
        email,
        sexo,
        Origem,
        CartaoConvenio {
            numero,
            convenio,
            plano
        }
        endereco {
            rua,
            bairro,
            cep,
            cidade,
            estado,
            complemento,
            numero
        }
        user {
            photoUrl,
        }
        parentes @skip(if: $isAdmin) {
          paciente {
            id,
            nome,
            nascimento,
            cpf,
            celular,
            email,
            sexo,
            Origem,
            CartaoConvenio {
                numero,
                convenio,
                plano
            }
            endereco {
                rua,
                bairro,
                cep,
                cidade,
                estado,
                complemento,
                numero
            }
            user {
                photoUrl
            }
          },
          tipoParente {
            id,
            tipoParente
          }
        }
      }
  }
`;

export const GET_RELATIVE_TYPES = gql`
  query getRelativeTypes {
    relative{
        id
        tipoParente
        status
    }
  }
`;

export const GET_ORIGENS = gql`
  query origemQuery($slug: String!) {
    OrigemQuery(slug: $slug){
        id
        origem
    }
  }
`;

export const INSERT_UPDATE_PATIENT = gql`
  mutation insertUpdatePatient($slug: String!, $patientId: Int, $nome: String, $cpf: String, $celular: String, $email: String, $senha: String, $tipoParentescoId: Int, $pacienteIdRelacao: Int, $nascimento: String!, $delete: Boolean, $rua: String, $bairro: String, $cep: String, $cidade: String, $estado: String, $complemento: String, $numero: String, $origemId: Int, $cartaoConvenio: [CartaoConvenioInputType], $sexo: String) {
      patientSite(PacienteID: $patientId, nome: $nome, cpf: $cpf, celular: $celular, email: $email, senha: $senha, tipoParentesco: $tipoParentescoId, PacienteIDRelacao: $pacienteIdRelacao, slug: $slug, nascimento: $nascimento, delete: $delete, rua: $rua, bairro: $bairro, cep: $cep, cidade: $cidade, estado: $estado, complemento: $complemento, numero: $numero, OrigemID: $origemId, CartaoConvenio: $cartaoConvenio, sexo: $sexo) {
        success
        content
      }
  }
`;

export const insertUpdatePatient = async (slug, patient, password = false, patientRelationId = false, deletePatient = false) => {
    let cartaoConvenio = [{
        numero: patient.matricula,
        convenio: patient.convenioId,
        plano: patient.planoId
    }];

    if (!patient.convenioId) {
        cartaoConvenio = null;
    }
    const patientInfo = await client.mutate({
        mutation: INSERT_UPDATE_PATIENT,
        variables: {
            slug: slug,
            senha: password,
            pacienteIdRelacao: patientRelationId,
            delete: deletePatient,
            email: patient.email || false,
            patientId: patient.id || false,
            nome: patient.name || false,
            cpf: patient.cpf || false,
            celular: patient.cel || false,
            tipoParentescoId: patient.relativeTypeId || false,
            nascimento: patient.birthdate || false,
            rua: patient.rua || false,
            bairro: patient.bairro || false,
            cep: patient.cep || false,
            cidade: patient.cidade || false,
            estado: patient.estado || false,
            complemento: patient.complemento || false,
            numero: patient.numero || false,
            origemId: patient.origemId,
            cartaoConvenio: cartaoConvenio,
            sexo: patient.sexo || false,
        }
    });

    if (!patientInfo.data.patientSite.success) throw new Error(patientInfo.data.patientSite.content)

    return patientInfo.data.patientSite;
};

export const getRelativeTypes = async () => {
    const relativeTypesQuery = await client.query({
        query: GET_RELATIVE_TYPES
    });

    if (relativeTypesQuery.data.relative.length === 0) throw new Error("Nenhum tipo de parentesco encontrado");

    return relativeTypesQuery.data.relative;
};

export const getOrigens = async (slug) => {
    const origensQuery = await client.query({
        query: GET_ORIGENS,
        variables: {slug: slug},
        fetchPolicy: "no-cache"
    });

    return origensQuery.data.OrigemQuery;
};

export const getRequiredFields = async (slug) => {
    const translateMap = {
        "cpf": {name: "cpf", label: "CPF"},
        "email": {name: "email", label: "Email"},
        "nascimento": {name: "birthdate", label: "Data de Nascimento"},
        "celular": {name: "cel", label: "Celular"},
        "origem": {name: "origemId", label: "Origem"},
        "nome": {name: "name", label: "Nome do Paciente"},
        "cep": {name: "cep", label: "CEP"},
        "cidade": {name: "cidade", label: "Cidade"},
        "rua": {name: "rua", label: "Logradouro"},
        "bairro": {name: "bairro", label: "Bairro"},
        "estado": {name: "estado", label: "Estado"},
        "complemento": {name: "complemento", label: "Complemento"},
        "numero": {name: "numero", label: "Número"},
        "sexo": {name: "sexo", label: "Sexo do paciente"},
        "matricula": {name: "matricula", label: "Matricula do plano"},
        "plano": {name: "planoId", label: "Plano do convenio"},
        "convenio": {name: "convenioId", label: "Convenio do paciente"},
    };

    const requiredFieldsQuery = await client.query({
        query: GET_REQUIRED_FIELDS,
        variables: {slug: slug},
        fetchPolicy: "no-cache"
    });

    let requiredFields = {};
    if (typeof requiredFieldsQuery.data.RequiredFieldsQuery[0] === "undefined")
        return requiredFields;


    requiredFieldsQuery.data.RequiredFieldsQuery[0].campos.forEach((campo) => {
        if (!campo.obrigatorio) return;
        if (typeof translateMap[campo.campo] === "undefined") return;

        requiredFields[translateMap[campo.campo].name] = translateMap[campo.campo].label;
    });

    return requiredFields;
};


export const getAvailablePatients = async (slug, isAdmin = false, searchTerm = false) => {
    const availablePatientsQuery = await client.query({
        query: GET_AVAILABLE_PATIENTS,
        variables: {
            slug: slug,
            isAdmin: isAdmin,
            searchTerm: searchTerm
        },
        fetchPolicy: "no-cache"
    });

    let availablePatients = {};
    if (availablePatientsQuery.data.patient.length === 0) return availablePatients;


    const patients = availablePatientsQuery.data.patient;

    //da um merge entre o paciente usuario e seus pacientes parentes
    let mergedPatients = [];
    if (!isAdmin) {
        let parent = patients[0];
        parent.isUser = true;
        parent.relativeTypeId = false;
        mergedPatients.push(parent);
        parent.parentes.forEach((children) => {
            children.paciente.relativeTypeId = children.tipoParente ? children.tipoParente.id : false;
            children.paciente.isUser = false;
            mergedPatients.push(children.paciente);
        })
    } else {
        mergedPatients = patients;
    }


    //formatando array para objeto com key-id
    mergedPatients.forEach((patient) => {
        availablePatients[patient.id] = {
            id: patient.id,
            name: patient.nome,
            birthdate: patient.nascimento,
            email: patient.email,
            photo: patient.user && patient.user.photoUrl,
            cpf: patient.cpf,
            cel: patient.celular,
            sexo: patient.sexo,
            origemId: patient.Origem,
            convenioId: patient.CartaoConvenio && patient.CartaoConvenio[0] ? patient.CartaoConvenio[0].convenio : false,
            planoId: patient.CartaoConvenio && patient.CartaoConvenio[0] ? patient.CartaoConvenio[0].plano : false,
            matricula: patient.CartaoConvenio && patient.CartaoConvenio[0] ? patient.CartaoConvenio[0].numero : null,
            rua: patient.endereco ? patient.endereco.rua : null,
            bairro: patient.endereco ? patient.endereco.bairro : null,
            cep: patient.endereco ? patient.endereco.cep : null,
            cidade: patient.endereco ? patient.endereco.cidade : null,
            estado: patient.endereco ? patient.endereco.estado : null,
            complemento: patient.endereco ? patient.endereco.complemento : null,
            numero: patient.endereco ? patient.endereco.numero : null,
            relativeTypeId: patient.relativeTypeId,
            isUser: patient.isUser
        }
    });


    return availablePatients;
};
