import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Badge} from "shards-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faCheckCircle,
    faClipboardCheck,
    faStethoscope,
    faUserMd,
    faCreditCard
} from "@fortawesome/free-solid-svg-icons";
import {faIdCard, faUser} from "@fortawesome/free-regular-svg-icons";
import {useStoreActions, useStoreState} from "easy-peasy";
import {formatDate, getStepUrl} from "../../utils/utils";
import {faCalendarAlt} from "@fortawesome/free-regular-svg-icons/faCalendarAlt";
import {withRouter} from "react-router-dom";
import Loader from 'react-loader-spinner';
import ComponentOverlay from "../../layout/LoadingContent/ComponentOverlay";
import {redirectToPayment} from "../../services/confirmationService";
import SelectTabelaParticular from "./SelectTabelaParticular";
import AceiteAgendamento from "./AceiteAgendamento";
import {faVideo} from "@fortawesome/free-solid-svg-icons/faVideo"


const Confirmar = (props) => {
    const [appointLoading, setAppointLoading] = useState(false);
    const [appointError, setAppointError] = useState(false);
    const [hideConfirmButton, setHideConfirmButton] = useState(false);
    const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);

    const confirmationStore = useStoreState(s => s.confirmation);
    const confirmationActions = useStoreActions(a => a.confirmation);
    const layoutStore = useStoreState(s => s.layout);
    const stepsActions = useStoreActions(a => a.steps);
    const stepsState = useStoreState(s => s.steps);
    const patientActions = useStoreActions(a => a.patient);
    const scheduleActions = useStoreActions(a => a.schedule);
    const unitActions = useStoreActions(a => a.units);
    const procedureActions = useStoreActions(a => a.procedure);

    const confirmDetails = confirmationStore.confirmationDetails;

    const patientName = confirmDetails ? confirmDetails.Paciente.nome : null;
    const professionalName = confirmDetails ? confirmDetails.Profissional.nome : null;

    const initialParams = {
        stepActions: confirmationActions,
        requiredQueryParams: [
            {param: "appointTypeId", name: "Tipo de agendamento"},
            {param: "selectedProcedures", name: "Procedimentos"},
            {param: "professionalId", name: "Profissional"},
            {param: "hour", name: "Hora do agendamento"},
            {param: "dateStart", name: "Data do agendamento"},
        ],
        initialDataFunctions: [
            procedureActions.getInitialValues,
            unitActions.getInitialValues,
            scheduleActions.getInitialValues,
            patientActions.getInitialValues,
            confirmationActions.getConfirmationDetails,
        ]
    };

    useEffect(() => {
        stepsActions.setCurrentStep("confirmar");
        props.getStepInitialData(initialParams);
    }, []);

    const totalAmount = () => {
        let total = 0;

        if (stepsState.getParams.returnId) return total.toFixed(2);

        if (confirmDetails) {
            confirmDetails.Procedimentos.forEach((procedure) => {
                if (procedure.valor) {
                    total += procedure.valor;
                }
            });
        }

        return total.toFixed(2);
    };

    const allowOnlinePayment =
        layoutStore.getConfig('pagamentoObrigatorio') &&
        !stepsState.getParams.planoId &&
        !stepsState.getParams.convenioId &&
        totalAmount() > 0 &&
        stepsState.getParams.isTelemedicine;

    const confirmAppoint = async () => {
        setAppointLoading(true);

        try {
            const appointConfirmed = await confirmationActions.confirmAppoint();

            if (appointConfirmed === "redirect") {
                props.history.push(getStepUrl(layoutStore.slug, "horario-profissional", stepsState.getParams));
                return false;
            }

            if (allowOnlinePayment) {
                await redirectToPayment(appointConfirmed.LicencaID, appointConfirmed.PacienteID, appointConfirmed.ID);
                return false;
            }

            stepsActions.setStepData({
                'confirmar': {
                    licenseId: appointConfirmed.LicencaID,
                    appointId: appointConfirmed.ID,
                    patientFeegowId: appointConfirmed.PacienteID
                }
            });

            props.history.push(getStepUrl(layoutStore.slug, "confirmacao", stepsState.getParams));
        } catch (e) {
            setAppointError(e.message);
        }

        setAppointLoading(false);
    };

    const changePatient = () => {
        stepsActions.setCurrentStep("paciente");
        props.history.push(getStepUrl(layoutStore.slug, "paciente", stepsState.getParams))
    };

    const renderPrepare = () => {
        if (confirmDetails) {
            let prepares = [];
            confirmDetails.Procedimentos.forEach((procedure) => {
                if (procedure.preparo) {
                    procedure.preparo.forEach((prep) => {
                        prepares.push(prep.tipoPreparo);
                    })
                }
            });

            if (prepares.length === 0) {
                return <p style={{opacity: 0.50, marginBottom: 0}}>Nenhum preparo definido</p>
            }

            return prepares.map((prepare, k) => (
                <p key={k} className="prepare-step"><FontAwesomeIcon style={{marginRight: 3}}
                                                                     color={layoutStore.primaryColor}
                                                                     icon={faCheckCircle}/>{prepare}</p>
            ))
        }
    };

    const renderConfirmAppointButton = () => {
        if (hideConfirmButton) {
            return null;
        }

        if (allowOnlinePayment) {
            return (
                <Button disabled={appointLoading || confirmButtonDisabled} onClick={() => confirmAppoint()}
                        style={{fontFamily: "Montserrat"}}
                        block>{appointLoading ? <Loader
                    type="ThreeDots"
                    color="white"
                    height={10}
                /> : <div><FontAwesomeIcon
                    icon={faCreditCard}/> Realizar pagamento</div>}</Button>
            );
        }

        return (
            <Button disabled={appointLoading || confirmButtonDisabled} onClick={() => confirmAppoint()}
                    style={{fontFamily: "Montserrat"}}
                    block>{appointLoading ? <Loader
                type="ThreeDots"
                color="white"
                height={10}
            /> : <div><FontAwesomeIcon icon={faCheck}/> Confirmar agendamento</div>}</Button>
        );
    };

    const renderSpecialties = () => {
        let specialties = [];

        if (confirmDetails) {
            confirmDetails.Procedimentos.forEach((procedure) => {
                if (procedure.especialidade) {
                    specialties.push(procedure.especialidade);
                }
            });
        }

        if (specialties.length === 0) return "Não definida";

        return specialties.join(", ");
    };

    const getDateAndHour = () => {
        const date = stepsState.getParams.dateStart;
        const hour = stepsState.getParams.hour;

        return formatDate(date, "weekDay") + " às " + hour + "h";
    };

    const renderProcedures = () => {
        if (confirmDetails) {
            return confirmDetails.Procedimentos.map((procedure, index) => (
                <Row key={index} style={{marginBottom: 15}}>
                    <Col>
                        <p style={{
                            fontFamily: "Montserrat",
                            fontWeight: "800",
                            marginBottom: 0,
                            fontSize: 14
                        }}>{procedure.procedimento}</p>
                        <p style={{
                            marginBottom: 0,
                            fontSize: 14,
                            fontWeight: "600",
                            opacity: 0.50
                        }}>{procedure.tipoProcedimento} {stepsState.getParams.returnId ? ' - Retorno' : null}</p>
                        {stepsState.getParams.isTelemedicine ?
                            <Badge style={{marginTop: 10}} theme="success"><FontAwesomeIcon icon={faVideo}/> Consulta
                                Online</Badge> : null}
                    </Col>
                    {typeof confirmDetails.Convenio === "undefined" && !confirmDetails.Convenio.id ?
                        <Col style={{textAlign: "right"}}>
                            <p style={{marginBottom: 0, fontSize: 14}}>R$ {procedure.valor.toFixed(2)}</p>
                        </Col> : null}
                </Row>
            ))
        }
    };

    const renderTotal = () => {
        if (typeof confirmDetails.Convenio !== "undefined" && confirmDetails.Convenio.id) {
            return (
                <span style={{fontFamily: "Montserrat"}}>
                    <FontAwesomeIcon style={{marginLeft: 10}} color={layoutStore.primaryColor}
                                     icon={faIdCard}/>
                                    <span style={{
                                        fontWeight: "500",
                                        fontSize: 14,
                                        marginLeft: 10
                                    }}>{confirmDetails.Convenio.nome}</span>
                                </span>
            )
        } else {
            return (
                <span style={{fontFamily: "Montserrat"}}>
                                    <span style={{paddingLeft: 10}}><b>Total:</b></span>
                                    <span style={{float: "right", fontWeight: "800"}}>R$ {totalAmount()}</span>
                                </span>
            )
        }
    };

    const renderAppointError = () => {
        if (appointError) {
            return (
                <Col>
                    <p style={{color: "red"}}>{appointError}</p>
                </Col>
            )
        } else {
            return null;
        }
    };

    return (
        <ComponentOverlay error={confirmationStore.error} loading={confirmationStore.loading}>
            <style>{confirmStyle}</style>
            <Row style={{marginTop: -10}}>
                <Col md="8">
                    <Col className="confirm-card" style={{marginTop: 20}}>
                        <Row style={{paddingLeft: 20}}>
                            <div style={{textAlign: "center", marginRight: 10}}>
                                <FontAwesomeIcon color={layoutStore.primaryColor} style={{fontSize: 25}}
                                                 icon={faCheckCircle}/>
                            </div>
                            <div>
                                <p style={{
                                    marginBottom: 0,
                                    fontFamily: "Montserrat",
                                    fontWeight: "800",
                                    color: layoutStore.primaryColor,
                                    fontSize: 23,
                                    letterSpacing: "-1.50px",
                                    marginTop: "-5px"
                                }}>Confirme seus dados !</p>
                                <p style={{
                                    color: layoutStore.primaryColor,
                                    marginBottom: 0,
                                    fontSize: 14,
                                    marginTop: "-5px"
                                }}>Verifique com atenção os dados do agendamento.</p>
                            </div>
                        </Row>
                        <hr/>

                        <Row style={{paddingLeft: 10, marginTop: 30}}>
                            <Col md="8">
                                <p className="details-title">Paciente agendado:</p>
                                <p className="details-patient-name"><FontAwesomeIcon color={layoutStore.primaryColor}
                                                                                     icon={faUser}/> {patientName}
                                </p>
                            </Col>
                            <Col md="4">
                                <Button onClick={() => changePatient()} style={{float: "right"}}
                                        size="sm" outline>Trocar
                                    paciente</Button>
                            </Col>
                        </Row>
                        <Row style={{paddingLeft: 10, marginTop: 30}}>
                            <Col md="6">
                                <p className="details-title">Profissional:</p>
                                <p className="details-patient-name"><FontAwesomeIcon
                                    color={layoutStore.primaryColor}
                                    icon={faUserMd}/> {professionalName}
                                </p>
                            </Col>
                        </Row>
                        <Row style={{paddingLeft: 10, marginTop: 30}}>
                            <Col md="6">
                                <p className="details-title">Especialidade:</p>
                                <p className="details-patient-name"><FontAwesomeIcon color={layoutStore.primaryColor}
                                                                                     icon={faStethoscope}/> {renderSpecialties()}
                                </p>
                            </Col>
                        </Row>
                        <Row style={{paddingLeft: 10, marginTop: 30}}>
                            <Col md="9">
                                <p className="details-title">Data & Hora:</p>
                                <p className="details-patient-name"><FontAwesomeIcon color={layoutStore.primaryColor}
                                                                                     icon={faCalendarAlt}/> {getDateAndHour()}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col className="confirm-card" style={{marginTop: 20}}>
                        <Row>
                            <Col md="1" style={{textAlign: "center", padding: 2}}>
                                <FontAwesomeIcon color={layoutStore.primaryColor} style={{fontSize: 30}}
                                                 icon={faClipboardCheck}/>
                            </Col>
                            <Col md="11" style={{marginLeft: "-10px"}}>
                                <p className="confirm-card-title">Preparos</p>
                                {renderPrepare()}
                            </Col>
                        </Row>
                    </Col>
                </Col>
                <Col md="4">
                    <Col className="confirm-card" style={{marginTop: 20}}>
                        <Row>
                            <Col style={{marginLeft: "-10px"}}>
                                <p className="confirm-card-title">Detalhes de pagamento</p>
                                <hr/>
                            </Col>
                        </Row>
                        {renderProcedures()}
                        <Row>
                            <Col style={{marginLeft: "-10px"}}>
                                <hr/>
                                {renderTotal()}
                            </Col>
                        </Row>
                        <Row style={{marginTop: 40}}>
                            {layoutStore.getConfig('tabelaParticular') ?
                                <SelectTabelaParticular appointError={setAppointError}
                                                        hideConfirmButton={setHideConfirmButton}/> : null}
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col>
                                {layoutStore.getConfig('aceiteAgendamento') ?
                                    <AceiteAgendamento disableConfirmButton={setConfirmButtonDisabled}/> : null}
                            </Col>
                        </Row>
                        <Row style={{marginTop: 20}}>
                            <Col>
                                {renderConfirmAppointButton()}
                            </Col>
                        </Row>
                        <Row style={{marginTop: 10}}>
                            {renderAppointError()}
                        </Row>
                    </Col>
                </Col>
            </Row>
        </ComponentOverlay>
    );
};

const confirmStyle = `
    .confirm-card {
        background-color: white;
        border-radius: 5px;
        padding: 30px;
        box-shadow: 00 0.125em 0.313em rgba(50,50,93,.09), 0 0.063em 0.125em rgba(0,0,0,.07);
    }
    .details-title {
        margin-bottom: 3px;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 14px;
        opacity: 0.6;
        letter-spacing: -0.50px
    }
    .details-unity-name {
        margin-bottom: 2px;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 14px;
        letter-spacing: -0.50px
    }
    .details-unity-address {
        margin-bottom: 0px;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 14px;
        letter-spacing: -0.50px
    }
    .details-patient-name {
        margin-bottom: 0px;
        font-family: "Montserrat";
        font-weight: 400;
        font-size: 14px;
        letter-spacing: -0.50px
    }

    .confirm-card-title {
        font-size: 16px;
        font-family: "Montserrat";
        font-weight: 600;
        letter-spacing: -0.50px;
        margin-bottom: 12px;
    }

    .prepare-step {
        font-size: 14px;
        margin-bottom: 10px;
    }
`;

export default withRouter(Confirmar);
