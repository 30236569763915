import React, {useState, useEffect} from 'react';
import {Row, Col, Form, FormGroup, FormInput, Button} from "shards-react";

import './LoginModal.css'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF, faGoogle} from "@fortawesome/free-brands-svg-icons";
import {useStoreActions, useStoreState} from "easy-peasy";
import {signInWithEmail, signInWithFacebook, signInWithGoogle} from "../../services/firebaseService";
import Loader from "react-loader-spinner";


const Login = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const userActions = useStoreActions(a => a.user);
    const layoutActions = useStoreActions(a => a.layout);
    const layoutState = useStoreState(s => s.layout);
    const stepActions = useStoreActions(a => a.steps);


    const onKeyDown = async (e) => {
        if (e.key === "Enter") {
            await signIn('email');
        }
    };

    useEffect(() => {
        setEmail(props.email);
        setError(layoutState.redirectError);
    }, []);

    const signIn = async (provider) => {
        let signInFunction = false;
        setLoading(true);

        switch (provider) {
            case "email":
                signInFunction = signInWithEmail;
                break;
            case "google":
                signInFunction = signInWithGoogle;
                break;
            case "facebook":
                signInFunction = signInWithFacebook;
        }

        try {
            const user = await signInFunction(email, password);

            if (!user.emailVerified) {
                props.setCurrentPage('verifyEmail', false, user);
                return false;
            }

            const userInfo = await userActions.signIn({
                userId: user.uid,
                email: user.email,
                displayName: user.displayName,
                photo: user.photoURL
            });


            userActions.setValue({userInfo: userInfo, redirectError: false});
            layoutActions.setModalContent(false);
            setLoading(false);

            if (props.goToNextStep) stepActions.toNextStep();
        } catch (e) {
            setError(e.message);
            setLoading(false);
        }
    };

    return (
        <div>
            <Row>
                <Col>
                    <Form>
                        <FormGroup>
                            <FormInput onKeyPress={onKeyDown} value={email} onChange={(ev) => setEmail(ev.target.value)}
                                       type="email"
                                       id="#email"
                                       placeholder="Email"/>
                        </FormGroup>
                        <FormGroup>
                            <FormInput onKeyPress={onKeyDown} onChange={(ev) => setPassword(ev.target.value)}
                                       type="password" id="#password"
                                       placeholder="Senha"/>
                        </FormGroup>
                        <p style={{color: "red"}}>{error}</p>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p onClick={() => props.setCurrentPage("forgotPassword")}
                       style={{fontSize: 12, opacity: 0.80, cursor: "pointer", float: "left"}}>Esqueci minha senha</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={() => props.setCurrentPage("signUp", email)} block outline>Criar uma conta</Button>
                </Col>
                <Col>
                    <Button onClick={() => signIn("email")} block>
                        {loading ? <Loader height={15} width={20} color="white" type="ThreeDots"/> : "Entrar"}
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col style={{textAlign: "center", marginTop: 25, marginBottom: 0}}>
                    <p style={{fontSize: 10, opacity: 0.50}}>------------ OU ------------</p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button onClick={() => signIn("facebook")} className="facebook-button" block outline
                            pill><FontAwesomeIcon icon={faFacebookF}/> Entrar
                        com facebook</Button>
                </Col>
            </Row>
            <Row style={{marginTop: 10}}>
                <Col>
                    <Button onClick={() => signIn("google")} className="google-button" block outline
                            pill><FontAwesomeIcon
                        icon={faGoogle}/> Entrar com
                        google </Button>
                </Col>
            </Row>
        </div>
    )
};

export default Login;