import React from 'react';
import {useStoreState} from 'easy-peasy';

const PlanoOptions = (props) => {
    const scheduleState = useStoreState(s => s.schedule);
    const stepsState = useStoreState(s => s.steps);


    if (props.convenioId) {
        if (typeof scheduleState.convenios[props.convenioId] === "undefined") return null;
        if (scheduleState.convenios[props.convenioId].planos.length === 0) return null;

        return scheduleState.convenios[props.convenioId].planos.map((plano) => (
            <option key={plano.id} value={plano.id}>{plano.plano}</option>
        ));
    }


    if (!stepsState.getParams.convenioId) return null;
    if (typeof scheduleState.convenios[stepsState.getParams.convenioId] === "undefined") return null;
    if (scheduleState.convenios[stepsState.getParams.convenioId].planos.length === 0) return null;

    return scheduleState.convenios[stepsState.getParams.convenioId].planos.map((plano) => (
        <option key={plano.id} value={plano.id}>{plano.plano}</option>
    ));
};

export default PlanoOptions;