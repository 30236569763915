import React from 'react';
import Steps, {Step} from 'rc-steps';
import {useStoreState} from "easy-peasy";

import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import DatePicker from './DatePicker'
import './StepsBar.css'
import StepIcon from "./StepIcon";
import {withRouter} from "react-router-dom";
import {getStepUrl} from "../../../utils/utils";

const StepsBar = (props) => {
    const stepsStore = useStoreState(state => state.steps);
    const layout = useStoreState(state => state.layout);

    const stepBarStyle = `.rc-steps-item-finish .rc-stepes-item-tail::after {background-color: ${layout.primaryColor} !important}`;

    const onSelectStep = (step) => {
        const currentStepIndex = stepsStore.currentStepIndex;
        const newStepIndex = Object.keys(stepsStore.availableSteps).indexOf(step);

        if (newStepIndex > currentStepIndex + 1) return false;

        props.history.push(getStepUrl(layout.slug, step, stepsStore.getParams))

    };

    return (
        <div>
            <style>{stepBarStyle}</style>
            <Steps current={stepsStore.currentStepIndex} className="step-bar-container" direction="vertical">
                {Object.keys(stepsStore.availableSteps).map((key, index) => (
                    <Step
                        onClick={() => stepsStore.canSelectStep({key: key, index: index}) ? onSelectStep(key) : null}
                        style={{cursor: stepsStore.canSelectStep({key: key, index: index}) ? "pointer" : "default"}}
                        key={key} icon={<StepIcon stepsStore={stepsStore} stepKey={key}/>}
                        title={stepsStore.availableSteps[key].name}
                        description={stepsStore.availableSteps[key].isDate ?
                            <DatePicker/> : stepsStore.availableSteps[key].selectedValue}/>
                ))}
            </Steps>
        </div>
    )
};

export default withRouter(StepsBar);