import React, {useState} from 'react';
import {Col, Dropdown, DropdownItem, DropdownMenu} from "shards-react";
import {useStoreActions, useStoreState} from "easy-peasy";
import {formatDate} from "../../utils/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle, faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import {faCalendar} from "@fortawesome/free-regular-svg-icons/faCalendar"
import {faIdCard} from "@fortawesome/free-regular-svg-icons/faIdCard"

const PatientCard = (props) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const layoutStore = useStoreState(s => s.layout);
    const patientStore = useStoreState(s => s.patient);
    const patientActions = useStoreActions(a => a.patient);
    const stepsStore = useStoreState(s => s.steps);


    const handleDropdownClick = (e) => {
        e.stopPropagation(); //ignora o onclick do pai

        setShowDropdown(!showDropdown);
    };

    const onRemovePatient = async (e) => {
        e.stopPropagation();
        let confirm = window.confirm("Você tem certeza que deseja excluir esse paciente?");

        if (confirm) {
            try {
                patientActions.removePatient(props.patient);
            } catch (e) {
                alert(e.message);
            }
        }
    };

    const onEditPatient = async (e) => {
        e.stopPropagation();
        await props.verifyConvenio(props.patient);
        props.setPatientModal({
            ...props.patientModal,
            showing: true,
            patient: props.patient,
            type: "update",
            error: false
        });
    };

    const renderSelectedIcon = () => {
        if (props.patient.id === stepsStore.availableSteps.paciente.data.patientId) {
            return (
                <div style={{position: "absolute", top: -10, right: 3, backgroundColor: "white"}}>
                    <FontAwesomeIcon style={{fontSize: 25}} color={layoutStore.primaryColor} icon={faCheckCircle}/>
                </div>
            )
        } else {
            return null
        }
    };

    const getPatientRelativeType = () => {
        const relativeTypeId = props.patient.relativeTypeId;

        if (props.patient.isUser) return "VOCÊ";

        let relativeName = "";
        patientStore.relativeTypes.forEach((type) => {
            if (type.id == relativeTypeId) {
                relativeName = type.tipoParente;
            }
        });

        return relativeName;
    };

    return (
        <Col style={{marginTop: 15}} md={4}>
            <style>{patientCardStyle}</style>
            {renderSelectedIcon()}
            <div
                onClick={() => props.onPatientSelect()}
                className="card-hover"
                style={{
                    backgroundColor: "white",
                    borderRadius: 5,
                    padding: 20,
                    display: "flex",
                    flexDirection: "row",
                    cursor: "pointer",
                    height: "100%",
                    border: stepsStore.availableSteps.paciente.data.patientId === props.patient.id ? `1px solid ${layoutStore.primaryColor}` : null
                }}>
                <div style={{cursor: "pointer"}}>
                    <img style={{height: 75}}
                         src="https://www.belr.com/wp-content/uploads/2017/06/avatar-placeholder-generic-1.jpg"
                         className="
                         rounded-circle"
                         alt="user-avatar"/>
                </div>
                <Dropdown className="patient-dropdown" open={showDropdown}
                          toggle={() => setShowDropdown(!showDropdown)}>
                    <DropdownMenu>
                        {props.patient.isUser ? null :
                            <DropdownItem onClick={(e) => onRemovePatient(e)} style={{color: "red"}}>Excluir
                                paciente</DropdownItem>}
                        <DropdownItem onClick={(e) => onEditPatient(e)}>Editar paciente</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
                <div style={{marginLeft: 20, cursor: "pointer"}}>
                    <p style={{
                        fontFamily: "Montserrat",
                        fontWeight: "800",
                        fontSize: 18,
                        color: layoutStore.primaryColor,
                        marginBottom: 0
                    }}>
                        {props.patient.name}
                    </p>
                    <p style={{
                        fontFamily: "Open Sans",
                        fontWeight: "600",
                        fontSize: 14,
                        color: "#9eadb5",
                        marginTop: 5,
                        marginBottom: 0
                    }}><FontAwesomeIcon icon={faCalendar}/> {formatDate(props.patient.birthdate, "d/m/y") || '-'}</p>
                    <p style={{
                        fontFamily: "Open Sans",
                        fontWeight: "600",
                        fontSize: 14,
                        color: "#9eadb5",
                        marginTop: 3,
                        marginBottom: 0
                    }}><FontAwesomeIcon icon={faIdCard}/> {props.patient.cpf || '-'}</p>
                    <p style={{
                        fontFamily: "Open Sans",
                        fontWeight: "600",
                        fontSize: 14,
                        color: "#9eadb5",
                        marginBottom: 0,
                        marginTop: 3
                    }}>{getPatientRelativeType()}</p>
                </div>
                <div onClick={(e) => handleDropdownClick(e)}
                     style={{marginLeft: "auto"}}>
                    <FontAwesomeIcon style={{fontSize: "20px", opacity: 0.50}} icon={faEllipsisH}/>
                </div>
            </div>
        </Col>
    )
};

const patientCardStyle = `
.patient-dropdown {
    top: -60px;
    left: 150px;
}
`;

export default PatientCard;