import React, {useEffect, useState} from 'react';
import {useStoreActions, useStoreState} from "easy-peasy";
import {Button, Col, Fade, FormInput, FormSelect, InputGroup, InputGroupAddon, InputGroupText, Row} from "shards-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {formatDate, getStepUrl} from "../../utils/utils";
import {withRouter} from "react-router-dom";
import {
    faCheck,
    faMapMarkerAlt,
    faSearch,
    faCaretDown,
    faVideo,
    faStethoscope
} from "@fortawesome/free-solid-svg-icons";
import AppointTypeOption from "./AppointTypeOption";
import {faListAlt} from "@fortawesome/free-regular-svg-icons";
import ProposalItem from "./ProposalItem";
import LoginModal from "../../layout/Modal/LoginModal";
import RenderReturns from "./RenderReturns"
import TelemedicineButton from "./TelemedicineButton";
import AppointButton from "./AppointButton";
import ProposalButton from "./ProposalButton";


const Especialidade = (props) => {
    const layoutStore = useStoreState(s => s.layout);
    const stepsState = useStoreState(s => s.steps);
    const procedureState = useStoreState(s => s.procedure);
    const procedureActions = useStoreActions(a => a.procedure);
    const stepsActions = useStoreActions(a => a.steps);
    const userState = useStoreState(s => s.user);
    const layoutActions = useStoreActions(a => a.layout);
    const unitsActions = useStoreActions(a => a.units);
    const [placeholder, setPlaceholder] = useState("");
    const [procedureInput, setProcedureInput] = useState("");
    const [proposalItens, setProposalItens] = useState([]);
    const [showingItems, setShowingItems] = useState(25);
    const appointType = procedureState.getCurrentAppointType;

    const style = `
        #input-options {
            border-color: ${layoutStore.primaryColor}
        }
        
        #input-options::placeholder {
            color: ${layoutStore.primaryColor};
            opacity: 1;
        }
    `;

    const setAppointType = (appointType) => {
        if (appointType.isReturn) {
            if (!userState.isLoggedIn) {
                layoutActions.setModalContent(<LoginModal/>);
                return false;
            }


            setPlaceholder(appointType.placeholder);
            stepsActions.setStepData({
                "especialidade": {
                    "appointTypeId": appointType.id,
                    "selectedProcedures": [],
                }
            });

            return false;
        }

        setPlaceholder(appointType.placeholder);
        stepsActions.setStepData({
            "especialidade": {
                "appointTypeId": appointType.id,
                "selectedProcedures": [],
            }
        });
    };

    const addToProposal = (procedure) => {
        if (!proposalItens.includes(procedure)) {
            setProposalItens(oldProposalItens => [...oldProposalItens, procedure])
        }
    };

    const confirmProposal = () => {
        stepsActions.setStepData({
            "especialidade": {
                selectedProcedures: proposalItens,
                isProposal: true,
            }
        });

        stepsActions.setSelectedValue({
            "especialidade": proposalItens.map((item) => item.name.substr(0, 4)).join(", ")
        });

        const nextStep = stepsActions.toNextStep();
        props.history.push(getStepUrl(layoutStore.slug, nextStep, stepsState.getParams))
    };

    const selectProcedure = (procedure, isTelemedicine = false) => {
        stepsActions.setStepData({
            "especialidade": {
                selectedProcedures: [{
                    specialtyId: procedure.specialtyId,
                    procedureId: procedure.procedureId,
                    telemedicineId: procedure.telemedicineId,
                    allowPayment: procedure.allowPayment
                }],
                isProposal: false,
                isTelemedicine: isTelemedicine
            }
        });

        stepsActions.setSelectedValue({
            "especialidade": procedure.name
        });


        if (isTelemedicine) {
            unitsActions.setUnitInfo({unityName: "Consultório Virtual", unityId: null});
            props.history.push(getStepUrl(layoutStore.slug, "horario-profissional", stepsState.getParams))
            return false;
        }

        unitsActions.setUnitInfo({unityName: false, unityId: false});

        const nextStep = stepsActions.toNextStep();
        props.history.push(getStepUrl(layoutStore.slug, nextStep, stepsState.getParams))
    };

    const removeProposalItem = (procedure) => {
        let teste = proposalItens.filter((proposalItem) => proposalItem !== procedure);
        setProposalItens(teste);
    };

    const getProceduresByFilter = () => {
        let results = [];

        if (appointType && procedureState.appointTypesOptions[appointType.id]) {
            results = procedureState.appointTypesOptions[appointType.id];
        }

        if (appointType && procedureInput !== "") {
            results = procedureState.appointTypesOptions[appointType.id].filter((option) => {
                return option.name.toLowerCase().includes(procedureInput.toLowerCase());
            });
        }

        return results;
    };

    const increaseShowingItems = () => {
        setShowingItems(showingItems + 10);
    };

    const RenderProcedures = () => {
        let procedures = getProceduresByFilter();

        let renderResults = (
            <p style={{textAlign: "center", marginBottom: 0, opacity: 0.5}}>Nenhum resultado encontrado</p>);

        if (procedures.length > 0) {
            renderResults = procedures.slice(0, showingItems).map((procedure, key) => {
                return (
                    <div key={key} style={{
                        borderRadius: 8,
                        backgroundColor: "white",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        padding: 25,
                        marginTop: 15
                    }}>
                        <div>
                            <p style={{
                                marginBottom: 0,
                                fontFamily: "Open Sans",
                                color: "#686868",
                                fontSize: 16
                            }}>{procedure.name}</p>
                        </div>
                        <div>
                            <TelemedicineButton procedure={procedure} onSelectProcedure={selectProcedure}/>
                            <AppointButton procedure={procedure} onSelectProcedure={selectProcedure}
                                           appointTypeId={appointType.id}/>
                            <ProposalButton procedure={procedure} onAddToProposal={addToProposal}/>
                        </div>
                    </div>
                )
            })
        }

        return appointType ? (
            <div style={{backgroundColor: "rgba(0,0,0,0.01)", borderRadius: 5, width: 900, padding: 25}}>
                {renderResults}
                {procedures.length > showingItems ? <p onClick={increaseShowingItems} style={{
                    textAlign: 'center',
                    marginBottom: 0,
                    marginTop: 20,
                    cursor: 'pointer',
                    color: layoutStore.primaryColor
                }}>Ver mais itens <FontAwesomeIcon icon={faCaretDown}/></p> : null}

            </div>
        ) : null;
    };

    const RenderInput = () => {
        if (appointType) {
            return (
                <div style={{display: "flex", justifyContent: "center", marginTop: 20, alignItems: "center"}}>
                    <div style={{display: "flex", width: 800}}>
                        <div style={{flex: 1.5}}>
                            <InputGroup seamless>
                                <InputGroupAddon type="prepend">
                                    <InputGroupText>
                                        <FontAwesomeIcon icon={faSearch}/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <FormInput autoComplete={"off"} onChange={(e) => setProcedureInput(e.target.value)}
                                           placeholder={appointType.placeholder}
                                           id="input-options"/>
                            </InputGroup>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    };

    const RenderProposal = () => {
        if (proposalItens.length > 0) {
            return (
                <div style={{display: "flex", justifyContent: "center", marginTop: 15}}>
                    <div style={{width: 900, padding: 20, backgroundColor: "rgba(0,0,0,0.01)", borderRadius: 5}}>
                        <p style={{fontFamily: "Open Sans", marginBottom: 10}}><FontAwesomeIcon
                            icon={faListAlt}/> Proposta
                            atual:</p>
                        {proposalItens.map((el, i) => (
                            <ProposalItem key={i} removeProcedure={() => removeProposalItem(el)} name={el.name}/>))}
                        <Button onClick={() => confirmProposal()} style={{marginTop: 30}} block>Confirmar
                            proposta <FontAwesomeIcon
                                icon={faCheck}/></Button>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    };

    const initialParams = {
        stepActions: procedureActions,
        requiredQueryParams: [],
        initialDataFunctions: [
            procedureActions.getAppointTypes,
            procedureActions.getAppointTypeOptions,
            procedureActions.getInitialValues
        ]
    };

    useEffect(() => {
        stepsActions.setCurrentStep("especialidade");
        props.getStepInitialData(initialParams);
    }, []);

    useEffect(() => {
        stepsActions.setStepData({
            "especialidade": {
                "appointTypeId": appointType?appointType.id:null,
                "selectedProcedures": [],
            }
        });
    }, [appointType])

    return (
        <Fade style={{display: "flex", flexDirection: "column"}}>
            <style>{style}</style>
            <Row>
                <p style={{
                    fontFamily: "Montserrat",
                    fontSize: "32px",
                    color: "#2c404a",
                    fontWeight: "500",
                    letterSpacing: "-2.75px"
                }}>Digite a área de atuação desejada</p>
            </Row>
            <div style={{
                marginTop: 25,
                display: "flex",
                flexWrap: "wrap",
                marginLeft: -20,
                marginRight: -20,
                justifyContent: "center"
            }}>
                {procedureState.appointTypes.sort((a, b) => a.id - b.id).map((appointType, index) => {
                    return (
                        <AppointTypeOption onSetAppointType={() => setAppointType(appointType)}
                                           isSelected={appointType.id == stepsState.availableSteps["especialidade"].data.appointTypeId}
                                           key={index} id={appointType.id} icon={appointType.icon}
                                           name={appointType.name} enabled={appointType.enabled}/>
                    )
                })}
            </div>
            {appointType && appointType.isReturn ?
                <RenderReturns/>
                :
                <div>
                    {RenderProposal()}
                    {RenderInput()}
                    <div style={{
                        display: "flex",
                        marginTop: 40,
                        justifyContent: "center"
                    }}>
                        {RenderProcedures()}
                    </div>
                </div>}
        </Fade>
    )
};

export default withRouter(Especialidade);
