import React from 'react';
import {Col} from "shards-react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

const AddPatient = (props) => {

    const onAddPatient = () => {
        props.setPatientModal({type: "create", patient: {}, showing: true})
    };

    return (
        <Col style={{marginTop: 15}} md={4}>
            <div
                onClick={() => onAddPatient()}
                className="card-hover"
                style={{
                    backgroundColor: "#ebeef1",
                    borderRadius: 5,
                    display: "flex",
                    flexDirection: "column",
                    cursor: "pointer",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "115px",
                }}>
                <div>
                    <FontAwesomeIcon style={{fontSize: 16, opacity: 0.50}} icon={faPlus}/>
                </div>
                <div>
                    <p style={{marginBottom: 0, marginTop: 5, fontWeight: "600", opacity: 0.50, fontSize: 16}}>Adicionar
                        novo paciente</p>
                </div>
            </div>
        </Col>
    )
};

export default AddPatient;