import React from 'react';
import {Col, Row} from "shards-react";
import {useStoreState} from "easy-peasy";

import './SideMenu.css'

import StepsBar from './components/StepsBar';

const SideMenu = () => {
    const layout = useStoreState(state => state.layout);

    return (
        <div style={{marginTop: -10}}>
            <Row>
                <Col style={{textAlign: "center"}}>
                    <img style={{height: layout.getConfig('logoAltura')}}
                         className="img-fluid side-bar-logo mx-auto d-block" alt="logo-side-menu"
                         src={layout.logoURL}/>
                    <p style={{marginBottom: 0, marginTop: 10, fontSize: 12, opacity: 0.50}}>powered by feegow</p>
                </Col>
            </Row>
            < Row style={{marginTop: -25}}>
                <StepsBar/>
            </Row>
        </div>
    )
};

export default SideMenu;