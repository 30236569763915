import React, {useState} from 'react';
import {ModalHeader, ModalBody} from "shards-react";
import "./LoginModal.css";
import SignUp from "./SignUp";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import VerifyEmail from "./VerifyEmail";

const LoginModal = (props) => {
    const [currentPage, setCurrentPage] = useState("login");
    const [user, setUser] = useState(false);
    const [email, setEmail] = useState("");

    const changePage = (page, email, user = false) => {
        setEmail(email);
        setUser(user);
        setCurrentPage(page);
    };

    const renderContent = () => {
        switch (currentPage) {
            case "login":
                return <Login email={email} goToNextStep={props.goToNextStep} setCurrentPage={changePage}/>;
            case "signUp":
                return <SignUp email={email} goToNextStep={props.goToNextStep} setCurrentPage={changePage}/>;
            case "forgotPassword":
                return <ForgotPassword setCurrentPage={changePage}/>;
            case "verifyEmail":
                return <VerifyEmail setCurrentPage={changePage} user={user}/>;
        }
    };

    return (
        <div>
            <ModalHeader style={{textAlign: "center"}} titleClass="modal-login-title">Realize seu login antes de
                continuar</ModalHeader>
            <ModalBody>
                {renderContent()}
            </ModalBody>
        </div>
    )
};

export default LoginModal;