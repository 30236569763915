import {action, computed, thunk} from "easy-peasy";

const unitsModel = {
    loading: false,
    error: false,
    availableUnits: computed(
        [
            (state, storeState) => storeState.schedule.availableSchedule
        ],
        (schedules) => {

        }
    ),

    setAvailableUnits: action((state, availableUnits) => {
        state.availableUnits = availableUnits;
    }),

    setLoading: action((state, isLoading) => {
        state.loading = isLoading;
    }),

    setError: action((state, error) => {
        state.error = error;
    }),

    setValue: action((state, payload) => {
        let payloadKeys = Object.keys(payload);

        payloadKeys.forEach((key) => {
            if (state.hasOwnProperty(key)) {
                state[key] = payload[key];
            }
        })
    }),

    setUnitInfo: thunk(async (actions, payload, {getStoreState, getStoreActions}) => {
        const stepsActions = getStoreActions().steps;

        stepsActions.setSelectedValue({
            "centro-medico": payload.unityName
        });

        stepsActions.setStepData({
            "centro-medico": {
                unitId: payload.unityId
            }
        });
    }),

    getInitialValues: thunk(async (actions, payload, {getStoreState, getStoreActions}) => {
        const unitSelectedValue = getStoreState().steps.availableSteps["centro-medico"].selectedValue;

        if (!unitSelectedValue) {
            const stepsActions = getStoreActions().steps;

            const unityId = payload.unityId;
            const unityName = payload.selectedValues["centro-medico"];

            stepsActions.setSelectedValue({
                "centro-medico": unityName
            });

            stepsActions.setStepData({
                "centro-medico": {
                    unitId: unityId
                }
            });
        }
    }),
};

export default unitsModel;
